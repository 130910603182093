import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

import './Page.css'

export default function Page404() {
  return (
    <div>
      <section className="error-container">
        <span>4</span>
        <span>
          <span className="screen-reader-text">0</span>
        </span>
        <span>4</span>
      </section>
      <h1>Упс! Этой страницы не существует</h1>
      <div className="link-container">
        <Link to="/orders">
          <Button>Перейти к заказам</Button>
        </Link>
      </div>
    </div>
  )
}
