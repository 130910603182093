import { useDragLayer } from 'react-dnd'
import { makeStyles } from '@material-ui/core/styles'

// ...
const useStyles = makeStyles((theme) => ({
  // ... остальные стили
  dragPreview: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 1000,
  },
}))

const DragPreview = ({ children }) => {
  const classes = useStyles()
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  return (
    <div
      className={classes.dragPreview}
      style={{
        left: currentOffset.x || 0, // Используйте значение по умолчанию, если x не определено
        top: currentOffset.y || 0, // Используйте значение по умолчанию, если y не определено
        display: 'inline-block',
      }}
    >
      {itemType === 'photo' && <div>{children}</div>}
    </div>
  )
}

export default DragPreview
