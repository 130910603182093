export function formatVerificationType(type) {
  if (!['none', 'by_card', 'full', 'manual'].includes(type)) {
    return 'Неверный тип верификации'
  }

  return {
    none: 'Нету',
    by_card: 'Картой',
    full: 'Видео',
    manual: 'Вручную',
  }[type]
}

export function formatAutocompleteClientName(client) {
  return `${client?.first_name || ''} ${client?.last_name || ''} ${client?.wechat_id || ''} ${
    client?.telegram_username || ''
  } (${client?.id ? `${client?.id}` : ''}, ${client.type})`
}
