import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import ButtonAppBar from '../bar/ButtonAppBar'

const useStyles = makeStyles((theme) => ({
  loader: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function Loading() {
  const classes = useStyles()

  return (
    <>
      <ButtonAppBar />
      <Container maxWidth="sm">
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
      </Container>
    </>
  )
}
