import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import ButtonAppBar from '../bar/ButtonAppBar'

const useStyles = makeStyles((theme) => ({
  center: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function ErrorComponent({ text = 'Ошибка загрузки страницы' }) {
  const classes = useStyles()

  return (
    <>
      <ButtonAppBar />
      <Container maxWidth="sm">
        <Box className={classes.center}>
          <Typography variant="h4">{text}</Typography>
        </Box>
      </Container>
    </>
  )
}
