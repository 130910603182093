import React, { useState } from 'react'
import MaterialTable from 'material-table'

import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { Button } from '@material-ui/core'
import { formattedCurrencies, formattedCurrenciesFixed } from '../../utils/customHooks'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import NewExchangeRates from './NewExchangeRates'

function Alert(props) {
  const { onClose, severity } = props
  return <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity} />
}

export default function Currencies() {
  const [recalculateExchanges, setRecalculateExchanges] = useState(undefined)
  const [recalculateExchangeId, setRecalculateExchangeId] = useState(undefined)
  const columns = [
    {
      title: 'Пара валют',
      field: 'name',
      editable: 'never',
    },
    {
      title: 'is_reversed',
      field: 'isReversed',
      editable: 'never',
      hidden: true,
    },
    {
      title: 'rateOriginal',
      field: 'rateOriginal',
      editable: 'never',
      hidden: true,
    },
    {
      title: 'Рейт',
      field: 'rate',
      hidden: true,
      editComponent: (props) => {
        const {
          rowData: { is_reversed: isReversed, rateOriginal },
        } = props
        const [curValue, setCurValue] = useState(isReversed ? 1 / Number(rateOriginal) : rateOriginal)
        return (
          <>
            {isReversed ? '1/' : ''}
            <input
              type="number"
              name="rate"
              style={{ color: 'black' }}
              required
              step={0.01}
              min={0}
              max={100}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(
                  isReversed ? formattedCurrenciesFixed(1 / e.target.value, 6) : formattedCurrenciesFixed(e.target.value)
                )
              }}
            />
            {isReversed ? `(${curValue ? formattedCurrencies(1 / curValue) : 0})` : ''}
          </>
        )
      },
    },
    {
      title: 'Себестоимость',
      field: 'clear_rate',
      editable: 'never',
      render: ({ clear_rate }) => (
        <>{clear_rate ? (clear_rate < 1 ? `1/${formattedCurrencies(1 / clear_rate)} (${clear_rate})` : clear_rate) : 0}</>
      ),
    },
    {
      title: 'Себестоимость + затраты',
      field: 'rate_with_expenses',
      editable: 'never',
      render: ({ rate_with_expenses }) => (
        <>
          {rate_with_expenses
            ? rate_with_expenses < 1
              ? `1/${formattedCurrencies(1 / rate_with_expenses)} (${rate_with_expenses})`
              : rate_with_expenses
            : 0}
        </>
      ),
    },
    {
      title: 'Обычный рейт',
      field: 'rate_big_amount',
      render: ({ rate_big_amount }) => (
        <>
          {rate_big_amount
            ? rate_big_amount < 1
              ? `1/${formattedCurrencies(1 / rate_big_amount)} (${rate_big_amount})`
              : rate_big_amount
            : 0}
        </>
      ),
      editComponent: (props) => {
        const {
          rowData: { is_reversed: isReversed, rate_big_amount },
        } = props
        const [curValue, setCurValue] = useState(isReversed ? 1 / Number(rate_big_amount) : rate_big_amount)
        return (
          <>
            {isReversed ? '1/' : ''}
            <input
              type="number"
              name="rate"
              style={{ color: 'black' }}
              required
              step={0.01}
              min={0}
              max={100}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(
                  isReversed ? formattedCurrenciesFixed(1 / e.target.value, 6) : formattedCurrenciesFixed(e.target.value)
                )
              }}
            />
            {isReversed ? `(${curValue ? formattedCurrencies(1 / curValue) : 0})` : ''}
          </>
        )
      },
    },
    {
      title: 'Малые суммы выкуп',
      field: 'rate_small_amount',
      render: ({ rate_small_amount }) => (
        <>
          {rate_small_amount
            ? rate_small_amount < 1
              ? `1/${formattedCurrencies(1 / rate_small_amount)} (${rate_small_amount})`
              : rate_small_amount
            : 0}
        </>
      ),
      editComponent: (props) => {
        const {
          rowData: { is_reversed: isReversed, rate_small_amount },
        } = props
        const [curValue, setCurValue] = useState(isReversed ? 1 / Number(rate_small_amount) : rate_small_amount)
        return (
          <>
            {isReversed ? '1/' : ''}
            <input
              type="number"
              name="rate"
              style={{ color: 'black' }}
              required
              step={0.01}
              min={0}
              max={100}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(
                  isReversed ? formattedCurrenciesFixed(1 / e.target.value, 6) : formattedCurrenciesFixed(e.target.value)
                )
              }}
            />
            {isReversed ? `(${curValue ? formattedCurrencies(1 / curValue) : 0})` : ''}
          </>
        )
      },
    },
    {
      title: 'Первый заказ',
      field: 'rate_first_order',
      render: ({ rate_first_order }) => (
        <>
          {rate_first_order
            ? rate_first_order < 1
              ? `1/${formattedCurrencies(1 / rate_first_order)} (${rate_first_order})`
              : rate_first_order
            : 0}
        </>
      ),
      editComponent: (props) => {
        const {
          rowData: { is_reversed: isReversed, rate_first_order },
        } = props
        const [curValue, setCurValue] = useState(isReversed ? 1 / Number(rate_first_order) : rate_first_order)
        return (
          <>
            {isReversed ? '1/' : ''}
            <input
              type="number"
              name="rate"
              style={{ color: 'black' }}
              required
              step={0.01}
              min={0}
              max={100}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(
                  isReversed ? formattedCurrenciesFixed(1 / e.target.value, 6) : formattedCurrenciesFixed(e.target.value)
                )
              }}
            />
            {isReversed ? `(${curValue ? formattedCurrencies(1 / curValue) : 0})` : ''}
          </>
        )
      },
    },
    {
      title: 'Мин сумма покупки',
      field: 'min_amount',
      editComponent: (props) => {
        const { value, isReversed } = props
        return (
          <input
            type="number"
            style={{ color: 'black' }}
            required
            min={0}
            max={100000000}
            value={value}
            onChange={(e) =>
              props.onChange(
                isReversed ? formattedCurrenciesFixed(1 / e.target.value, 6) : formattedCurrenciesFixed(e.target.value)
              )
            }
          />
        )
      },
    },
    {
      title: 'Макс сумма покупки',
      field: 'max_amount',
      editComponent: (props) => {
        const { value } = props
        return (
          <input
            type="number"
            style={{ color: 'black' }}
            required
            min={0}
            max={100000000}
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
    },
    {
      title: 'Мин сумма продажи',
      field: 'min_amount_reverse',
      editComponent: (props) => {
        const { value } = props
        return (
          <input
            type="number"
            style={{ color: 'black' }}
            required
            min={0}
            max={100000000}
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
    },
    {
      title: 'Макс сумма продажи',
      field: 'max_amount_reverse',
      editComponent: (props) => {
        const { value } = props
        return (
          <input
            type="number"
            style={{ color: 'black' }}
            required
            min={0}
            max={100000000}
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
    },
    {
      title: 'Статус',
      field: 'enabled',
      lookup: {
        1: <span className="badge badge-success">Активно</span>,
        0: <span className="badge badge-danger">Не активно</span>,
      },
    },
  ]

  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const tableRef = React.createRef()

  const updateData = () => {
    if (tableRef.current) {
      return tableRef.current.onQueryChange()
    }
    return null
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Все поля должны быть заполнены!
        </Alert>
      </Snackbar>
      <ButtonAppBar />
      <Container maxWidth="xl">
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <br />
            <MaterialTable
              title="Курсы валют"
              columns={columns}
              tableRef={tableRef}
              options={{
                search: false,
                sorting: false,
                paging: false,
                actionsColumnIndex: -1,
                padding: 'dense',
                headerStyle: {
                  fontSize: 14,
                  padding: 8,
                },
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.enabled === 0 ? '#EEE' : '#FFF',
                  fontSize: 14,
                }),
              }}
              data={() =>
                new Promise((resolve) => {
                  const url = '/exchanges'
                  AxiosService.get(url).then((result) => {
                    resolve({
                      // eslint-disable-next-line max-len,camelcase
                      data: result?.data?.map(
                        ({
                          id,
                          is_reversed: isReversed,
                          from_currency,
                          to_currency,
                          rate,
                          min_amount,
                          max_amount,
                          min_amount_reverse,
                          max_amount_reverse,
                          enabled,
                          clear_rate,
                          rate_with_expenses,
                          rate_small_amount,
                          rate_big_amount,
                          rate_first_order,
                        }) => ({
                          id,
                          name: `${from_currency.currency_code} - ${to_currency.currency_code}`,
                          is_reversed: isReversed,
                          // eslint-disable-next-line max-len
                          rate: `${isReversed ? `1/${formattedCurrencies(1 / rate)} (${formattedCurrencies(rate)})` : rate}`,
                          rateOriginal: rate,
                          from_currency: from_currency.currency_code,
                          to_currency: to_currency.currency_code,
                          clear_rate,
                          rate_with_expenses,
                          rate_small_amount,
                          rate_big_amount,
                          rate_first_order,
                          min_amount,
                          max_amount,
                          min_amount_reverse,
                          max_amount_reverse,
                          enabled,
                        })
                      ),
                      page: result?.data?.current_page - 1,
                      totalCount: result.data.total,
                    })
                  })
                })
              }
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (
                        !newData.rate ||
                        !newData.min_amount ||
                        !newData.max_amount ||
                        !newData.min_amount_reverse ||
                        !newData.max_amount_reverse
                      ) {
                        setOpen(true)
                        reject()
                        return
                      }
                      resolve()
                      if (oldData) {
                        if (Number.isNaN(Number(newData.rate))) {
                          // eslint-disable-next-line no-param-reassign
                          newData.rate = newData.rateOriginal
                        }
                        AxiosService.put(`/exchanges/${newData.id}`, new URLSearchParams(newData), {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                          },
                        })
                          .then(() => {
                            // eslint-disable-next-line no-unused-expressions
                            tableRef.current && tableRef.current.onQueryChange()
                          })
                          .catch((err) => {
                            console.log('err', err)
                            if (err.response) {
                              console.log(err.response.status)
                            }
                          })
                        // eslint-disable-next-line no-unused-expressions
                        tableRef.current && tableRef.current.onQueryChange()
                      }
                    }, 600)
                  }),
              }}
              actions={[
                {
                  icon: 'currency_exchange',
                  tooltip: 'Пересчитать',
                  isFreeAction: true,
                  onClick: () => setRecalculateExchangeId('all'),
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                (rowData) => ({
                  icon: 'currency_exchange',
                  tooltip: 'Пересчитать',
                  onClick: () => {
                    setRecalculateExchangeId(rowData.id)
                    setRecalculateExchanges(`${rowData.from_currency} -> ${rowData.to_currency}`)
                  },
                }),
              ]}
            />
          </Grid>
        </Grid>
        <Dialog
          open={typeof recalculateExchangeId === 'number' || typeof recalculateExchangeId === 'string'}
          onClose={() => setRecalculateExchangeId(undefined)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {recalculateExchangeId === 'all'
              ? 'Вы действительно хотите пересчитать все курсы?'
              : `Вы действительно хотите пересчитать курс ${recalculateExchanges}?`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setRecalculateExchangeId(undefined)
                setRecalculateExchanges(undefined)
              }}
              color="primary"
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                AxiosService.post(
                  recalculateExchangeId === 'all'
                    ? '/exchanges/recalculate'
                    : `/exchanges/recalculate/${recalculateExchangeId}`,
                  {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                  }
                )
                  .then(() => {
                    // eslint-disable-next-line no-unused-expressions
                    updateData()
                    setRecalculateExchangeId(undefined)
                    setRecalculateExchanges(undefined)
                  })
                  .catch((err) => {
                    console.log('err', err)
                    if (err.response) {
                      console.log(err.response.status)
                    }
                  })
              }}
              color="primary"
              autoFocus
            >
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
        <NewExchangeRates updateExchanges={() => updateData()} />
      </Container>
    </div>
  )
}
