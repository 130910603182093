import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Plus14x14Icon } from '../../utils/icons'

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    backgroundColor: '#f3f5f3',
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },
  btnContainer: {
    cursor: 'pointer',
    backgroundColor: '#f3f5f3',
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
  },
  btnText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#222',
  },
}))

const AddButton = ({ addGroup }) => {
  const classes = useStyles()
  return (
    <Grid onClick={() => addGroup()} item className={classes.btnContainer} xs={12}>
      <span className={classes.btnIcon}>
        <Plus14x14Icon />
      </span>
      <span className={classes.btnText}>Добавить группу</span>
    </Grid>
  )
}

export default AddButton
