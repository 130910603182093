import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

export default function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        GM Exchange
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
