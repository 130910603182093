import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'
import { Button, Grid, Input, makeStyles, MenuItem, Paper, Select } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import ButtonAppBar from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import { RenderBadge } from '../expenses/RenderBadge'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
}))

const colors = ['007bff', '6c757d', '28a745', 'dc3545', 'ffc107', '17a2b8', 'f8f9fa', '343a40']

export default function ExpenseCategory() {
  const { id } = useParams()
  const { reset, control, watch } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const [expenseCategories, setExpenseCategories] = useState(null)

  const navigate = useNavigate()
  const isDisabledButton = useMemo(
    () => (typeof watch().parent_id !== 'number' && !watch().color_hash) || !watch().name,
    [watch()]
  )

  const classes = useStyles()

  const getColorName = (color) => {
    switch (color) {
      case '007bff':
        return 'Голубой'
      case '6c757d':
        return 'Серый'
      case '28a745':
        return 'Зелёный'
      case 'dc3545':
        return 'Красный'
      case 'ffc107':
        return 'Жёлтый'
      case '17a2b8':
        return 'Бирюзовый'
      case 'f8f9fa':
        return 'Белый'
      case '343a40':
        return 'Чёрный'
      default:
        return 'Белый'
    }
  }

  const handleSave = () => {
    if (id)
      AxiosService.put(`/expense-categories/${id}`, {
        color_hash: watch().parent_id !== 'no-parent' ? null : watch().color_hash,
        name: watch().name,
        parent_id: typeof watch().parent_id === 'number' ? watch().parent_id : null,
      })
        .then((res) => {
          if (res?.statusText === 'OK') {
            enqueueSnackbar(id ? 'Категория была отредактирована!' : 'Категория была создана!', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            navigate('/expenses')
          } else if (res?.response?.status === 422 || res?.status === 422) {
            Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
              enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    else
      AxiosService.post('/expense-categories', {
        color_hash: watch().parent_id ? null : watch().color_hash,
        name: watch().name,
        parent_id: typeof watch().parent_id === 'number' ? watch().parent_id : null,
      })
        .then((res) => {
          if (res?.response?.status === 201 || res?.status === 201) {
            enqueueSnackbar('Категория была создана!', { variant: 'success', autoHideDuration: 2000 })
            navigate('/expenses')
          } else if (res?.response?.status === 422 || res?.status === 422) {
            Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
              enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
  }
  useEffect(() => {
    const getExpenseCategories = () => {
      AxiosService.get('/expense-categories').then((result) => {
        setExpenseCategories(result.data)
        let tableData = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < result?.data?.length; i++) {
          tableData.push(result.data[i])
          if (result.data[i].children) {
            tableData = [...tableData, ...result.data[i].children]
          }
        }
        const category = tableData.filter((categoryData) => categoryData.id === Number(id))[0]

        reset(
          id
            ? {
                color_hash: category.color_hash,
                name: category.name,
                parent_id: category.parent_id === null ? 'no-parent' : category.parent_id,
              }
            : { color_hash: '', name: '', parent_id: null }
        )
      })
    }
    if (!expenseCategories) getExpenseCategories()
  }, [])

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  Название категории:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="name"
                        type="text"
                        style={{ width: '100%' }}
                        inputProps={{
                          step: '0.01',
                          min: '0.01',
                        }}
                        value={value || ''}
                        onChange={(fieldValue) => onChange(fieldValue)}
                        placeholder="Введите название категории"
                      />
                    )}
                    name="name"
                  />
                </Grid>
                {typeof watch().parent_id === 'number' ? null : (
                  <>
                    <Grid item xs={3}>
                      Цвет категории:
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            id="color_hash"
                            style={{ width: '100%' }}
                            value={value || ''}
                            placeholder="Выберите Кошелек"
                            onChange={(colorValue) => onChange(colorValue)}
                          >
                            {colors.map((color) => (
                              <MenuItem key={color} value={color}>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
                                  <span
                                    className="badge badge-primary"
                                    style={{
                                      backgroundColor: `#${color}`,
                                      color: (color === 'f8f9fa' || color === 'ffc107') && '#333',
                                    }}
                                  >
                                    {getColorName(color)}
                                  </span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="color_hash"
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={3}>
                  Родительская категория:
                </Grid>
                <Grid item xs={9}>
                  {expenseCategories && Array.isArray(expenseCategories) ? (
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        const categoryValue = value
                          ? expenseCategories.find((category) => category.id === Number(value))
                          : null
                        const options = [{ color_hash: 'fff', id: null, name: '-' }, ...expenseCategories]
                        return (
                          <Autocomplete
                            id="demo-simple-select-outlined"
                            size="medium"
                            value={categoryValue}
                            onChange={(event, option) => {
                              onChange(option?.id)
                            }}
                            options={options}
                            getOptionLabel={(option) => option.name || ''}
                            renderOption={(category) => <span>{RenderBadge(category)}</span>}
                            renderInput={(params) => (
                              <>
                                <TextField placeholder="Категория" size="medium" fullWidth {...params} />
                              </>
                            )}
                          />
                        )
                      }}
                      name="parent_id"
                    />
                  ) : null}
                </Grid>

                <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave} disabled={isDisabledButton}>
                    {id ? 'Редактировать категорию расходов' : 'Создать категорию расходов'}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
