import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { Input } from '@material-ui/core'
import AxiosService from '../../utils/axiosService'
import { formattedCurrencies, formattedCurrenciesFixed } from '../../utils/customHooks'

export default function ExchangePairsTable() {
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      title: 'Пара валют',
      field: 'name',
      editable: 'never',
      render: ({ from_currency_code, to_currency_code }) => (
        <Grid container>
          {from_currency_code} - {to_currency_code}
        </Grid>
      ),
    },
    {
      title: 'Курс на P2P',
      field: 'rate',
      type: 'numeric',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      editComponent: (props) => {
        const {
          rowData: { rate },
        } = props
        const [curValue, setCurValue] = useState(rate)
        return (
          <>
            <Input
              type="number"
              name="rate"
              style={{ color: 'black' }}
              required
              inputProps={{
                step: 0.01,
                min: 0,
                max: 100,
              }}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(e.target.value)
              }}
            />
          </>
        )
      },
    },
  ]

  const tableRef = React.createRef()
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={7}>
        <br />
        <MaterialTable
          title="Курс p2p платформ"
          columns={columns}
          tableRef={tableRef}
          options={{
            search: false,
            sorting: true,
            paging: false,
            actionsColumnIndex: -1,

            rowStyle: (rowData) => ({
              backgroundColor: rowData.enabled === 0 ? '#EEE' : '#FFF',
            }),
          }}
          data={() =>
            new Promise((resolve) => {
              const url = '/binance-rate'
              AxiosService.get(url).then((result) => {
                resolve({
                  // eslint-disable-next-line max-len,camelcase
                  data: result?.data?.map(({ id, is_reversed: isReversed, from_currency, to_currency, rate }) => ({
                    id,
                    from_currency_name: from_currency?.currency_description,
                    from_currency_code: from_currency?.currency_code,
                    to_currency_name: to_currency?.currency_description,
                    to_currency_code: to_currency?.currency_code,
                    rate: `${isReversed ? `1/${formattedCurrencies(1 / rate)} (${formattedCurrencies(rate)})` : rate}`,
                  })),
                  page: result.data.current_page - 1,
                  totalCount: result.data.total,
                })
              })
            })
          }
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (!newData.rate) {
                    reject()
                    enqueueSnackbar('Укажите корректное значение', { variant: 'error', autoHideDuration: 7000 })
                    return
                  }
                  resolve()
                  if (oldData) {
                    if (Number.isNaN(Number(newData.rate))) {
                      // eslint-disable-next-line no-param-reassign
                      newData.rate = newData.rateOriginal
                    }
                    AxiosService.put(`/binance-rate/${newData.id}`, { rate: newData.rate, recalculate_rates: true }, {})
                      .then(() => {
                        // eslint-disable-next-line no-unused-expressions
                        tableRef.current && tableRef.current.onQueryChange()
                      })
                      .catch((err) => {
                        console.log('err', err)
                        if (err.response) {
                          console.log(err.response.status)
                        }
                      })
                    // eslint-disable-next-line no-unused-expressions
                    tableRef.current && tableRef.current.onQueryChange()
                  }
                }, 600)
              }),
          }}
          actions={[
            {
              icon: 'currency_exchange',
              tooltip: 'Подтянуть валютные пары',
              isFreeAction: true,
              onClick: () => {
                AxiosService.post(`/binance-rate/`, {})
                  .then((res) => {
                    if (res?.response?.status === 200 || res?.status === 200) {
                      enqueueSnackbar('Курсы p2p обновлены', { variant: 'success' })
                      tableRef?.current?.onQueryChange()
                    } else if (res?.response?.status === 422 || res?.status === 422) {
                      Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
                        enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
                      })
                    } else if (res?.response?.status === 500 || res?.status === 500) {
                      enqueueSnackbar(`${res?.response?.data?.message}`, { variant: 'error', autoHideDuration: 7000 })
                    }
                  })
                  .catch((err) => {
                    console.log('err', err)
                    if (err.response) {
                      console.log(err.response.status)
                    }
                  })
              },
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
        />
      </Grid>
    </>
  )
}
