import React, { useState } from 'react'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { TextField } from '@material-ui/core'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import { formattedCurrencies, formattedCurrenciesFixed } from '../../utils/customHooks'
import ExchangePairsTable from './ExchangePairsTable'
import ExchangeVariablesTable from './ExchangeVariablesTable'
import PermissionWrapper from '../permission-wrapper/PermissionWrapper'

export default function NewExchangeRates({ updateExchanges }) {
  return (
    <div>
      <Grid spacing={2} container justify="center">
        <PermissionWrapper permission={PERMISSIONS.Exchanges}>
          <ExchangePairsTable />
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Admin}>
          <ExchangeVariablesTable updateExchanges={updateExchanges} />
        </PermissionWrapper>
      </Grid>
    </div>
  )
}
