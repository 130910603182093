import React, { useEffect, useState, createRef, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import debounce from 'lodash/debounce'
import { format } from 'date-fns'
import useSWR from 'swr'

import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DateFnsUtils from '@date-io/date-fns'

import AxiosService from '../../utils/axiosService'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import { formattedCurrencies, formattedMoney, formatWalletToSendRequisitesSecret } from '../../utils/customHooks'
import { PERMISSIONS } from '../bar/ButtonAppBar'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'
import { getPhotoUrl } from '../../utils/photoUrl'
import fetcher from '../../utils/fetcher'
import { RenderBadge } from './RenderBadge'

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlAutoComplete: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: '300px',
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default function Expenses({ categories }) {
  const classes = useStyles()
  const tableRef = createRef()
  const navigate = useNavigate()
  const [currencies, setCurrencies] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [usdtAmount, setUsdtAmount] = useState(undefined)
  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    // eslint-disable-next-line no-restricted-globals,no-undef
    parse(location.search) || {
      page: 1,
      search_string: '',
      datetime_from: null,
      datetime_to: null,
      expense_category_id: '',
    }
  )

  useSWR(
    `/expenses?&${stringify({
      ...parsed,
      page: 1,
      currency_code: parsed.currency_code === '-' ? '' : parsed.currency_code,
    })}`,
    fetcher,
    {
      onSuccess: (result) => setUsdtAmount(result?.meta.totals.usdt_amount),
    }
  )

  const handleExportClick = async () => {
    const url = `/expenses/export-csv?${stringify(parsed)}` // URL с параметрами запроса.
    const { data } = await AxiosService.get(url)

    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', `Расходы (${format(new Date(), 'dd-MM-yyyy')})`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns = [
    {
      title: 'Дата',
      field: 'datetime',
      width: '165px',
      cellStyle: { width: '165px', whiteSpace: 'nowrap' },
    },
    {
      title: 'Наименование',
      field: 'name',
      sorting: false,
    },
    {
      title: 'Сумма',
      field: 'amount',
      render: (rowData) => `${formattedMoney(rowData.amount)} ${rowData.currency.currency_code}`,
      sorting: false,
    },
    {
      title: 'Сумма в 🇺🇸USDT',
      field: 'usdt_amount',
      render: (rowData) => formattedMoney(rowData.usdt_amount),
    },
    {
      title: 'Курс 🇺🇸USDT',
      field: 'usdt_rate',
      sorting: false,
    },
    {
      title: 'Категория',
      field: 'expense_category',
      render: (rowData) => (
        <div style={{ display: 'flex' }}>
          {rowData.expense_category.parent ? RenderBadge(rowData.expense_category.parent, { marginRight: 12 }) : null}
          {RenderBadge(rowData.expense_category)}
        </div>
      ),
      sorting: false,
    },
    {
      title: ' Кто создал',
      field: 'created_by',
      render: (rowData) => <i>{rowData.created_by ? rowData.created_by.email : ''}</i>,
      sorting: false,
    },
    {
      title: 'Комментарий',
      field: 'comment',
      render: (rowData) => <i>{rowData.comment ? rowData.comment : '-'}</i>,
      sorting: false,
    },
    { title: 'Кошелёк списания', field: 'wallet_to_send', hidden: true, sorting: false },
    {
      title: 'Фото чека',
      field: 'files',
      render: ({ files }) =>
        Array.isArray(files) && files.length > 0
          ? files.map((fileLink, index) => (
              <a href={getPhotoUrl(fileLink)} target="_blank" rel="noreferrer">
                <p style={{ margin: 0 }}>{files.length > 1 ? `Чек ${index + 1}` : 'Чек'}</p>
              </a>
            ))
          : '-',
      cellStyle: { width: '110px', whiteSpace: 'nowrap' },
      headerStyle: { width: '110px', whiteSpace: 'nowrap' },
    },
    {
      title: 'Кошелёк списания',
      field: 'wallet_to_send_id',
      render: (rowData) =>
        rowData.wallet_to_send ? (
          <Link
            style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
            to={`/wallets/${rowData.wallet_to_send.id}/history`}
          >
            {formatWalletToSendRequisitesSecret(rowData.wallet_to_send)}
          </Link>
        ) : (
          '-'
        ),
      sorting: false,
      width: '20%',
      cellStyle: { width: '20%' },
    },
  ]

  const onChange = (e) => {
    dispatch({ search_string: e.target.value || undefined, page: 1 })
  }

  const debouncedOnChange = debounce(onChange, 300)

  const codeFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <TextField
        id="outlined-basic"
        label="Поиск"
        variant="outlined"
        type="text"
        defaultValue={parsed.search_string || ''}
        onChange={debouncedOnChange}
      />
    </FormControl>
  )
  const currencyFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-outlined-label">Валюта</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.currency_code}
        defaultValue=""
        onChange={(event) =>
          dispatch({ currency_code: event.target.value === '-' ? undefined : event.target.value || undefined, page: 1 })
        }
        label="Валюта"
      >
        {Object.keys(currencies).map((key) => (
          <MenuItem key={key} value={currencies[key]}>
            {currencies[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const categoryFilter = () => {
    const categoryValue = parsed?.expense_category_id
      ? categories?.find((category) => category?.id === Number(parsed?.expense_category_id))
      : null
    const options = Array.isArray(categories) ? [{ id: null, color_hash: null, name: '-' }, ...categories] : []
    return (
      <FormControl variant="outlined" className={classes.formControlAutoComplete}>
        <Autocomplete
          id="demo-simple-select-outlined"
          size="medium"
          value={categoryValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue || '')
          }}
          onChange={(event, value) => {
            dispatch({ expense_category_id: value?.id ? value.id : undefined, page: 1 })
          }}
          options={options}
          getOptionLabel={(option) => option.name}
          renderOption={(category) => (
            <span style={category.parent_id ? { paddingLeft: 12 } : {}}>{RenderBadge(category)}</span>
          )}
          renderInput={(params) => (
            <>
              <TextField placeholder="Категория" size="medium" fullWidth {...params} variant="outlined" />
            </>
          )}
        />
      </FormControl>
    )
  }

  const dateFromFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="yyyy/MM/dd"
          label="Создан после"
          inputVariant="outlined"
          value={parsed.datetime_from || null}
          disableFuture
          autoOk
          clearable
          onChange={(newValue) =>
            dispatch({ datetime_from: newValue ? format(newValue, 'yyyy-MM-dd') : undefined, page: 1 })
          }
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )

  const dateToFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="yyyy/MM/dd"
          label="Создан до"
          inputVariant="outlined"
          value={parsed.datetime_to || null}
          disableFuture
          autoOk
          clearable
          onChange={(newValue) =>
            dispatch({ datetime_to: newValue ? format(newValue, 'yyyy-MM-dd') : undefined, page: 1 })
          }
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )

  useDidMountEffect(() => {
    navigate(`/expenses?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  useEffect(() => {
    const fetchCurrencies = async () => {
      const cur = {}
      await AxiosService.get(`/currencies`).then((res) => {
        if (res?.data) {
          const mergedCurrencies = [{ currencyCode: '-', id: 0 }, ...res?.data]
          mergedCurrencies.map(({ id, currency_code: currencyCode }) => (cur[id] = currencyCode || '-'))
          setCurrencies(cur)
        }
      })
    }
    fetchCurrencies()
  }, [])
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={11} lg={10}>
          {codeFilter}
          {currencyFilter}
          {Array.isArray(categories) && categoryFilter()}
          {dateFromFilter}
          {dateToFilter}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={10}>
          <br />
          <MaterialTable
            tableRef={tableRef}
            title={
              usdtAmount ? (
                <>
                  <h6 style={{ marginTop: 16 }} className="MuiTypography-root MuiTypography-h6">
                    Расходы
                  </h6>
                  <p>Всего: {usdtAmount} 🇺🇸USDT</p>
                </>
              ) : (
                'Расходы'
              )
            }
            searchable={false}
            columns={columns}
            onChangePage={(page) => dispatch({ page: page + 1 })}
            onOrderChange={(row, orderDirection) => {
              dispatch({
                order_by_field: row === 0 ? 'datetime' : 'usdt_amount',
                order_by_dest: orderDirection,
                page: 1,
              })
            }}
            data={() =>
              new Promise((resolve) => {
                let url = '/expenses?'
                url += `&page=${parsed.page ? parsed.page : 1}`
                url += `&${stringify({
                  ...parsed,
                  currency_code: parsed.currency_code === '-' ? '' : parsed.currency_code,
                })}`
                AxiosService.get(url).then((result) => {
                  resolve({
                    data: result?.data?.data?.map(
                      ({
                        datetime,
                        name,
                        amount,
                        currency,
                        usdt_amount,
                        usdt_rate,
                        expense_category,
                        comment,
                        wallet_to_send_id,
                        wallet_to_send,
                        created_by,
                        files,
                      }) => ({
                        datetime,
                        name,
                        amount,
                        currency,
                        usdt_amount,
                        usdt_rate: `${
                          usdt_rate < 1
                            ? `1/${formattedCurrencies(1 / usdt_rate)} (${formattedCurrencies(usdt_rate)})`
                            : usdt_rate
                        }`,
                        expense_category,
                        comment,
                        wallet_to_send_id,
                        wallet_to_send,
                        created_by,
                        files,
                      })
                    ),
                    page: result?.data?.current_page - 1,
                    totalCount: result?.data?.total,
                  })
                })
              })
            }
            options={{
              search: false,
              sorting: true,
              // columnsButton: true,
              paging: true,
              pageSizeOptions: [15],
              pageSize: 15,
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({ height: !rowData.parent_id ? 78 : 0 }),
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Добавить расход',
                isFreeAction: true,
                onClick: () => {
                  navigate('/expenses/create')
                },
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'download',
                tooltip: 'Выгрузить CSV',
                isFreeAction: true,
                onClick: () => {
                  handleExportClick()
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
