import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Menu from '@material-ui/core/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import { useStyles } from './PrimarySearchAppBarStyles'
import PermissionWrapper from '../permission-wrapper/PermissionWrapper'
import { useAuth } from '../../utils/customHooks'

export const PERMISSIONS = {
  Orders: 'orders_rw',
  Exchanges: 'exchange_rates_rw',
  Wallets: 'wallets_rw',
  Expenses: 'expenses_rw',
  Clients: 'clients_rw',
  Notifications: 'notifications_rw',
  Taobao: 'taobao_orders_rw',
  Admin: 'admin',
}

export default function ButtonAppBar() {
  const classes = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const state = useAuth()
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div>
        <PermissionWrapper permission={PERMISSIONS.Orders}>
          <Link to="/orders" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Заказы</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Exchanges}>
          <Link to="/exchange-rates" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Курсы</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Wallets}>
          <Link to="/wallets" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Кошельки</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Expenses}>
          <Link to="/expenses" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Расходы</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Clients}>
          <Link to="/clients" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Клиенты</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Notifications}>
          <Link to="/notifications" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Рассылки</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        {/*
        <PermissionWrapper permission={PERMISSIONS.Taobao}>
          <Link to="/taobao-registrations" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Регистрации Taobao</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        */}
        <PermissionWrapper permission={PERMISSIONS.Taobao}>
          <Link to="/taobao-orders" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Заказы выкуп</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSIONS.Admin}>
          <Link to="/users" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
              <p>Пользователи</p>
            </MenuItem>
          </Link>
        </PermissionWrapper>
      </div>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <Typography variant="h6" className={classes.title}>
              <PermissionWrapper permission={PERMISSIONS.Orders}>
                <Link to="/orders" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Заказы</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Exchanges}>
                <Link to="/exchange-rates" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Курсы</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Wallets}>
                <Link to="/wallets" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Кошельки</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Expenses}>
                <Link to="/expenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Расходы</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Clients}>
                <Link to="/clients" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Клиенты</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Notifications}>
                <Link to="/notifications" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Рассылки</Button>
                </Link>
              </PermissionWrapper>
              {/*
              <PermissionWrapper permission={PERMISSIONS.Taobao}>
                <Link to="/taobao-registrations" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Регистрации Taobao</Button>
                </Link>
              </PermissionWrapper>
              */}
              <PermissionWrapper permission={PERMISSIONS.Taobao}>
                <Link to="/taobao-orders" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Заказы выкуп</Button>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSIONS.Admin}>
                <Link to="/users" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">Пользователи</Button>
                </Link>
              </PermissionWrapper>
            </Typography>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.grow} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: '0 8px 0 0' }}>{state?.user?.email}</p>
            <Link to="/profile" style={{ textDecoration: 'none', color: 'white' }}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}
