import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Accordion, AccordionDetails, AccordionSummary, Button, Input } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DnDExpandedPhotos from './DnDExpandedPhotos'
import { makeStyles } from '@material-ui/core/styles'
import { useDrop } from 'react-dnd'
import ImageUploader from 'react-images-upload'
import { Xmark14x14Icon } from '../../utils/icons'
import ExpandedPhotos from '../order/ExpandedPhotos'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
  },
  expansion: {
    color: theme.palette.text.secondary,
  },
}))

const NestedPhotos = (props) => {
  const { text, group, setGroupForCompletion, baskets } = props
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Accordion className={classes.expansion}>
        <AccordionSummary style={{ alignItems: 'center' }} expandIcon={<ExpandMoreIcon />}>
          <Grid
            onClick={(event) => {
              event.stopPropagation()
            }}
            className={classes.grid}
            item
            alignItems="center"
            justify="space-between"
            xs={12}
          >
            <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
            {group.invoice ? (
              <span className="badge badge-success">Завершен</span>
            ) : (
              <Button type="submit" variant="contained" color="primary" onClick={() => setGroupForCompletion(group.id)}>
                Добавить накладную
              </Button>
            )}
          </Grid>
        </AccordionSummary>

        <AccordionDetails style={{ display: 'block' }}>
          <ExpandedPhotos text={`Скриншоты`} images={group.files} />
        </AccordionDetails>
        <AccordionDetails style={{ display: 'block' }}>
          {group.baskets.map((item, index) => {
            return (
              <ExpandedPhotos
                style={{ marginBottom: 8 }}
                key={index}
                isLink
                text={item.link}
                images={baskets.find((basket) => basket.id === item.id).files}
              />
            )
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default NestedPhotos
