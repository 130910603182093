import axios from 'axios'

const AxiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// eslint-disable-next-line no-undef
const localStorage1 = localStorage
// eslint-disable-next-line no-undef
const window1 = window

AxiosService.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-undef
    const token = localStorage1.getItem('token')

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.token = `${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

AxiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && error.response.config.url !== '/login') {
      localStorage1.removeItem('user')
      localStorage1.removeItem('token')
      window1.location.href = '/login'
    }
    if (error?.response?.status === 403) {
      window1.location.href = '/403'
    }

    if (error?.response?.status === 404) {
      window1.location.href = '/404'
    }
    return error
  }
)
export default AxiosService
