import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import Footer from './Footer'
import SignIn from '../sign-in/SignIn'
import Currencies from '../currencies/Currencies'
import Orders from '../orders/Orders'
import Order from '../order/Order'
import Page404 from './Page404'
import Page403 from './Page403'
import Wallets from '../wallet/Wallets'
import WalletsHistory from '../wallet-history/WalletsHistory'
import WalletsTransfer from '../wallet-transfer/WalletsTransfer'
import Clients from '../clients/Clients'
import Client from '../client/Client'
import ClientCreation from '../client-creation/ClientCreation'
import OrderCreation from '../order-creation/OrderCreation'
import NotificationList from '../notification/NotificationList'
import Notification from '../notification/Notification'
import NotificationCreate from '../notification/NotificationCreate'
import ExpenseCategories from '../expenses-categories/ExpenseCategories'
import ExpenseCategory from '../expense-category/ExpenseCategory'
import ExpenseCreation from '../expense-creation/ExpenseCreation'
import { useAuth } from '../../utils/customHooks'
import Users from '../users/Users'
import User from '../user/User'
import TaobaoOrders from '../taobao-orders/TaobaoOrders'
import TaobaoRegistrations from '../taobao/TaobaoRegistrations'
import TaobaoRegistration from '../taobao/TaobaoRegistration'
import TaobaoOrder from '../taobao-orders/TaobaoOrder'

export default function App() {
  const state = useAuth()
  const [, setIsUserUpdated] = useState(false)
  useEffect(() => {
    if (state?.token)
      state.refresh().then(() => {
        setIsUserUpdated(true)
      })
  }, [state?.token])

  return (
    <div className="App">
      <SnackbarProvider maxSnack={6}>
        <CssBaseline />
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route exact path="/login" element={<SignIn />} />
          <Route exact path="/profile" element={<SignIn />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/orders/create" element={<OrderCreation />} />
          <Route exact path="/orders/:id" element={<Order />} />
          <Route exact path="/exchange-rates" element={<Currencies />} />
          <Route exact path="/wallets" element={<Wallets />} />
          <Route exact path="/wallets/:id/history" element={<WalletsHistory />} />
          <Route exact path="/wallets/:id/transfer" element={<WalletsTransfer />} />
          <Route exact path="/clients" element={<Clients />} />
          <Route exact path="/clients/create" element={<ClientCreation />} />
          <Route exact path="/clients/:id" element={<Client />} />
          <Route exact path="/notifications" element={<NotificationList />} />
          <Route exact path="/notifications/:id" element={<Notification />} />
          <Route exact path="/notifications/create" element={<NotificationCreate />} />
          <Route exact path="/expenses" element={<ExpenseCategories />} />
          <Route exact path="/expenses-categories/create" element={<ExpenseCategory />} />
          <Route exact path="/expenses-categories/:id" element={<ExpenseCategory />} />
          <Route exact path="/expenses/create" element={<ExpenseCreation />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/users/:id" element={<User />} />
          <Route exact path="/users/create" element={<User />} />
          <Route exact path="/taobao-registrations" element={<TaobaoRegistrations />} />
          <Route exact path="/taobao-registration/:id" element={<TaobaoRegistration />} />
          <Route exact path="/taobao-orders" element={<TaobaoOrders />} />
          <Route exact path="/taobao-orders/:id" element={<TaobaoOrder />} />
          {/* <Route exact path="/taobao-orders/create" element={<TaobaoOrderCreation />} /> TODO */}
          <Route exact path="/403" element={<Page403 />} />
          <Route exact path="/404" element={<Page404 />} />
          <Route element={Page404} />
        </Routes>
        <br />
        <Footer />
      </SnackbarProvider>
    </div>
  )
}
