import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { useForm, Controller } from 'react-hook-form'
import { Input } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Checkbox from '@material-ui/core/Checkbox'
import { useAuth, logouts } from '../../utils/customHooks'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import PasswordChange from './PasswordChange'

function Alert(props) {
  const { onClose, severity } = props
  return <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity} />
}

const useStyles = makeStyles((theme) => ({
  passwordMobile: {
    display: 'none',
    '@media (min-width: 320px) and (max-width:767px)': {
      display: 'flex',
    },
  },
  passwordDesktop: {
    display: 'flex',
    '@media (min-width: 320px) and (max-width:767px)': {
      display: 'none',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordTextShown, setIsPasswordTextShown] = useState(false)
  const { control, watch, reset } = useForm({
    defaultValues: { current_password: '', new_password: '', new_password_confirmation: '' },
  })
  const formValues = watch()
  const [state, setState] = useState(useAuth())
  const [creds, setCreds] = useState({
    email: '',
    password: '',
  })
  const isDisabledButton = useMemo(
    () => !formValues.current_password || !formValues.new_password || !formValues.new_password_confirmation,
    [formValues]
  )

  async function logout() {
    setState({ token: '', user: null })
    await logouts()
  }

  const classes = useStyles()

  const [open, setOpen] = useState(false)

  async function login() {
    const params = new URLSearchParams()
    params.append('email', creds.email)
    params.append('password', creds.password)
    await AxiosService.post('/login', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => {
        if (res?.statusText === 'OK') {
          // eslint-disable-next-line no-undef
          localStorage.setItem('token', res.data.token)
          // eslint-disable-next-line no-undef
          localStorage.setItem('user', JSON.stringify(res.data.user))

          const menuLinks = [
            'orders_rw',
            'exchange_rates_rw',
            'wallets_rw',
            'expenses_rw',
            'clients_rw',
            'notifications_rw',
            'taobao_orders_rw',
          ]

          const foundLink = menuLinks.find((link) => res.data.user.permissions.includes(link))

          if (!foundLink) return window.location.reload()

          const route = res.data.user.permissions.includes('admin')
            ? 'orders'
            : foundLink.replace('_rw', '').replace('_', '-')

          navigate(`/${route}`)
        } else {
          setCreds({ ...creds, password: '' })
          const error = typeof res?.response?.data.error === 'string' ? res?.response?.data : res?.response?.data.error
          Object.entries(error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        console.error('err', err)
        if (err.response) {
          // eslint-disable-next-line no-undef
          console.error(err.response.status)
        }
        setOpen(true)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const savePassword = () => {
    const { current_password, new_password, new_password_confirmation } = watch()
    AxiosService.put(`/me/change-password`, {
      current_password,
      new_password,
      new_password_confirmation,
    })
      .then((res) => {
        if (res?.statusText === 'OK') {
          enqueueSnackbar('Пароль был изменён', {
            variant: 'success',
            autoHideDuration: 2000,
          })
          setIsPasswordVisible(false)
          setIsPasswordTextShown(false)
          reset({ current_password: '', new_password: '', new_password_confirmation: '' })
        } else {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  if (state.user === null) {
    return (
      <Container component="main" maxWidth="xs">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Invalid credentials
          </Alert>
        </Snackbar>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Вход
          </Typography>
          <div className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setCreds({ email: e.target.value, password: creds.password })
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  login()
                }
              }}
            />
            <TextField
              value={creds.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setCreds({ email: creds.email, password: e.target.value })
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  login()
                }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => login()}
            >
              Войти
            </Button>
          </div>
        </div>
      </Container>
    )
  }
  return (
    <div>
      <ButtonAppBar />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={8} sm={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              setIsPasswordTextShown(false)
              reset({ current_password: '', new_password: '', new_password_confirmation: '' })
              setIsPasswordVisible(!isPasswordVisible)
            }}
          >
            Изменить пароль
          </Button>
        </Grid>
        {isPasswordVisible ? (
          <PasswordChange
            classes={classes}
            className={classes.passwordMobile}
            savePassword={savePassword}
            isPasswordTextShown={isPasswordTextShown}
            control={control}
            setIsPasswordTextShown={setIsPasswordTextShown}
            isDisabledButton={isDisabledButton}
          />
        ) : null}
        <Grid item xs={8} sm={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => logout()}
          >
            Выйти
          </Button>
        </Grid>
      </Grid>
      {isPasswordVisible ? (
        <PasswordChange
          classes={classes}
          className={classes.passwordDesktop}
          savePassword={savePassword}
          isPasswordTextShown={isPasswordTextShown}
          control={control}
          setIsPasswordTextShown={setIsPasswordTextShown}
          isDisabledButton={isDisabledButton}
        />
      ) : null}
    </div>
  )
}
