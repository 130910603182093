import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'
import { Button, Grid, Input, makeStyles, MenuItem, Paper, Select } from '@material-ui/core'
import useSWR from 'swr'
import Checkbox from '@material-ui/core/Checkbox'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import fetcher from '../../utils/fetcher'
import { getPermissionLocale } from '../users/Users'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
}))

export default function User() {
  const { id } = useParams()
  const {
    reset,
    control,
    watch,
    formState: { isValid },
  } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const { data: permissions } = useSWR(`/users/permissions`, fetcher)

  const classes = useStyles()

  const editUser = () => {
    AxiosService.put(`/users/${id}`, {
      email: watch().email,
      permissions: watch().permissions,
      is_blocked: watch().is_blocked,
    }).then((ans) => {
      if (ans?.status === 200) {
        enqueueSnackbar('Пользователь был отредактирован', { variant: 'success', autoHideDuration: 2000 })
        navigate('/users')
      } else {
        Object.entries(ans?.response?.data?.error)?.forEach(([key, value]) => {
          enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
        })
      }
    })
  }

  const changePassword = () => {
    AxiosService.put(`/users/${id}/change-password`, {
      password: watch().password,
    })
      .then((res) => {
        if (res?.statusText === 'OK') {
          editUser()
        } else {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const createUser = () => {
    AxiosService.post(`/users`, {
      email: watch().email,
      permissions: watch().permissions,
      password: watch().password,
    })
      .then((res) => {
        if (res?.statusText === 'Created') {
          enqueueSnackbar('Пользователь был создан', { variant: 'success', autoHideDuration: 2000 })
          navigate('/users')
        } else {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleSave = () => {
    if (id) {
      if (watch().password) {
        changePassword()
      } else {
        editUser()
      }
    } else {
      createUser()
    }
  }

  const onPermissionChange = (value) => {}

  useEffect(() => {
    const getUser = () => {
      AxiosService.get(`/users/${id}`).then(({ data }) => {
        reset({ password: '', email: data.email, permissions: data.permissions, is_blocked: data.is_blocked })
      })
    }
    if (id) getUser()
    else reset({ email: '', permissions: [] })
  }, [])

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  Email:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="email"
                        type="text"
                        style={{ width: '100%' }}
                        inputProps={{
                          step: '0.01',
                          min: '0.01',
                        }}
                        value={value || ''}
                        onChange={(fieldValue) => onChange(fieldValue)}
                        placeholder="Введите Email пользователя"
                      />
                    )}
                    name="email"
                  />
                </Grid>
                <>
                  <Grid item xs={3}>
                    Доступы:
                  </Grid>
                  <Grid item xs={9}>
                    <Controller
                      control={control}
                      rules={{ required: true, minLength: 1 }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          id="permissions"
                          multiple
                          style={{ width: '100%' }}
                          value={value || []}
                          placeholder="Выберите Доступы"
                          onChange={(e) => {
                            if (value.includes(PERMISSIONS.Admin) && e.target.value.includes(PERMISSIONS.Admin))
                              return onChange(e.target.value.filter((permission) => permission !== PERMISSIONS.Admin))
                            if (!value.includes(PERMISSIONS.Admin) && e.target.value.includes(PERMISSIONS.Admin))
                              return onChange([PERMISSIONS.Admin])
                            onChange(e.target.value)
                          }}
                        >
                          {permissions?.map((permission) => (
                            <MenuItem key={permission} value={permission}>
                              {getPermissionLocale(permission)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="permissions"
                    />
                  </Grid>
                  <>
                    <Grid item xs={3}>
                      Пароль:
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        rules={id ? {} : { required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            id="password"
                            type="password"
                            autoComplete="new-password"
                            style={{ width: '100%' }}
                            inputProps={{
                              step: '0.01',
                              min: '0.01',
                            }}
                            value={value || ''}
                            onChange={(fieldValue) => onChange(fieldValue)}
                            placeholder="Введите пароль"
                          />
                        )}
                        name="password"
                      />
                    </Grid>
                    {id ? (
                      <>
                        <Grid item xs={4} md={3}>
                          Заблокировать:
                        </Grid>
                        <Grid item xs={8} md={9}>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Checkbox
                                style={{ padding: 0 }}
                                color="secondary"
                                name="all"
                                checked={value || false}
                                onChange={() => onChange(!value)}
                              />
                            )}
                            name="is_blocked"
                          />
                        </Grid>
                      </>
                    ) : null}
                  </>
                </>

                <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave} disabled={!isValid}>
                    {id ? 'Редактировать пользователя' : 'Создать пользователя'}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
