import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { getPhotoUrl } from '../../utils/photoUrl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDrag, usedr } from 'react-dnd'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { getEmptyImage } from 'react-dnd-html5-backend'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  expansion: {
    color: theme.palette.text.secondary,
  },
}))

export default function DnDExpandedPhotos(props) {
  const { images, text, isLink, canDrag, dragData, itemGroupId, isDraggable } = props
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  const [collected, drag, preview] = useDrag({
    type: 'photo',
    item: { id: dragData.id ?? '', name: dragData.name ?? '', itemGroupId },
    canDrag: isDraggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    previewOptions: {
      captureDraggingState: false,
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview, getEmptyImage, props])

  function getFileUrl(file) {
    if (!file) {
      console.error('Файл не передан')
      return null
    }

    try {
      const imageUrl = URL.createObjectURL(file)
      return imageUrl
    } catch (error) {
      console.error('Ошибка при создании URL из файла:', error)
      return null
    }
  }
  if (images) {
    return (
      <div className="dnd-expanded-photos">
        <Grid item xs={12}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={() => setIsOpen(false)}
            message="Данные скопированы"
            action={
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />

          <Accordion
            style={{ opacity: 1, width: '100%', height: '100%' }}
            ref={canDrag ? drag : null}
            className={classes.expansion}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {isLink ? (
                /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(text) ? (
                  <span>
                    <a href={text} target="_blank" rel="noreferrer">
                      {text}{' '}
                    </a>
                    <CopyToClipboard text={text}>
                      <FileCopyIcon
                        className="copy-clipboard"
                        fontSize="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpen(true)
                        }}
                      />
                    </CopyToClipboard>
                  </span>
                ) : (
                  <span>
                    {text}{' '}
                    <CopyToClipboard text={text}>
                      <FileCopyIcon
                        className="copy-clipboard"
                        fontSize="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpen(true)
                        }}
                      />
                    </CopyToClipboard>
                  </span>
                )
              ) : (
                <span>{text}</span>
              )}
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {images.map((photoUrl, index) => {
                return (
                  <Card key={index} className={classes.root}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt=""
                        image={typeof photoUrl === 'string' ? getPhotoUrl(photoUrl) : getFileUrl(photoUrl[0])}
                      />
                    </CardActionArea>
                  </Card>
                )
              })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </div>
    )
  }
  return false
}
