import React, { useState, useEffect, useMemo } from 'react'
import throttle from 'lodash/throttle'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AxiosService from '../../utils/axiosService'
import { formatAutocompleteClientName } from '../../utils/text'

export default function ClientAutocomplete({ value, onChange, error, helperText }) {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const fetch = useMemo(
    () =>
      throttle(async (request, callback) => {
        const res = await AxiosService.get(`/clients/search?search_string=${request.input}&type=telegram`)
        callback(res.data)
      }, 200),
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value && value.length ? [...value] : [])

      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []

        if (value && value.length) {
          newOptions = [...value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const filterSelectedClients = (newOptions) => {
    const selectedIds = value.map((client) => client.id)
    return newOptions.filter((option) => !selectedIds.includes(option.id))
  }

  return (
    <Autocomplete
      options={options}
      filterOptions={filterSelectedClients}
      autoComplete
      includeInputInList
      multiple
      value={value}
      noOptionsText="Клиентов не найдено"
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Клиенты" variant="outlined" fullWidth error={error} helperText={helperText} />
      )}
      getOptionLabel={formatAutocompleteClientName}
    />
  )
}
