import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Input, Select, Button } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

import ButtonAppBar from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import { typesOfClientAvailableForCreate } from '../../utils/customHooks'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
}))

export default function ClientCreation() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const firstColumnWidth = 4
  const secondColumnWidth = 8

  const { register, trigger, watch, getValues } = useForm()
  const formValues = watch()

  const isDisabledButton = useMemo(
    () => !getValues()?.first_name?.length || !getValues()?.type?.length || !getValues()?.language_code?.length,
    [formValues]
  )

  const handleSave = async () => {
    await AxiosService.post(`/clients`, getValues(), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res?.response?.status === 201 || res?.status === 201) {
          enqueueSnackbar(`Клиент добавлен (${res?.data?.id})`, { variant: 'success', autoHideDuration: 9000 })
          navigate(`/clients/${res?.data?.id}`)
        } else if (res?.response?.status === 422 || res?.status === 422) {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        } else {
          enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 7000 })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={firstColumnWidth}>
                  Имя пользователя:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    id="first_name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Введите имя"
                    {...register('first_name')}
                  />
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Фамилия:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    id="last_name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Введите фамилию"
                    {...register('last_name')}
                  />
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Язык пользователя:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    id="language_code"
                    style={{ width: '100%' }}
                    value={getValues().language_code || 'ru'}
                    placeholder="Выберите язык"
                    {...register('language_code', {
                      required: 'Пожалуйста выберите язык',
                      onChange: () => trigger(),
                    })}
                  >
                    <option value="ru">Русский</option>
                    <option value="ua">Українська</option>
                    <option value="en">English</option>
                  </Select>
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Wechat ID/другие заметки:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    id="wechat_id"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Введите wechat id"
                    {...register('wechat_id')}
                  />
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Тип пользователя:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    id="type"
                    style={{ width: '100%' }}
                    value={getValues().type || 'wechat'}
                    placeholder="Выберите тип"
                    {...register('type', {
                      required: 'Пожалуйста выберите тип',
                      onChange: () => trigger(),
                    })}
                  >
                    {Object.keys(typesOfClientAvailableForCreate).map((key) => (
                      <MenuItem key={key} value={key}>
                        {typesOfClientAvailableForCreate[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave} disabled={isDisabledButton}>
                    Добавить клиента
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
