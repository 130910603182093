import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import ImageUploader from 'react-images-upload'

import Grid from '@material-ui/core/Grid'
import { Accordion, AccordionDetails, AccordionSummary, Input } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'

import DnDExpandedPhotos from './DnDExpandedPhotos'
import { Xmark14x14Icon } from '../../utils/icons'
import DragPreview from './DragPreview'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
  },
  expansion: {
    color: theme.palette.text.secondary,
  },
}))

const DropGroup = (props) => {
  const { text, group, changeGroup, updatePurchasedAt, addImage, deleteGroup, isFirst } = props
  const classes = useStyles()
  const [isOpenOnDrag, setIsOpenOnDrag] = useState(true)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'photo',
    drop: (item) => {
      setIsOpenOnDrag(true)
      changeGroup(item, item.itemGroupId, group.id, item.index)
    },
  })
  const onDrop = (picture) => {
    addImage(picture[picture.length - 1], group.id)
  }
  return (
    <div ref={drop}>
      <Grid style={{ marginBottom: 8 }} item xs={12}>
        <Accordion
          defaultExpanded
          expanded={isOpenOnDrag}
          onChange={() => setIsOpenOnDrag(!isOpenOnDrag)}
          className={classes.expansion}
        >
          <AccordionSummary style={{ alignItems: 'center' }} expandIcon={<ExpandMoreIcon />}>
            <Grid
              onClick={(event) => {
                event.stopPropagation()
              }}
              className={classes.grid}
              item
              alignItems="center"
              justify="space-between"
              xs={12}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
              <ImageUploader
                onChange={onDrop}
                buttonText="Выбрать изображения"
                style={{ all: 'none', width: 'fit-content' }}
                fileContainerStyle={{
                  display: 'flex',
                  padding: 0,
                  margin: 0,
                  alignItems: 'flex-end',
                  width: 'fit-content',
                }}
                withIcon={false}
                withLabel={false}
                buttonStyles={{ margin: 0 }}
                imgExtension={['.jpg', '.jpeg', '.bmp', '.png']}
                maxFileSize={5242880}
                errorStyle={{ display: 'none' }}
              />
              {isFirst ? null : (
                <div
                  onClick={() => deleteGroup(group.id)}
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '-6px',
                    top: '-6px',
                  }}
                >
                  <Xmark14x14Icon />
                </div>
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {group.items.map((item, index) => {
              const child = (
                <DnDExpandedPhotos
                  images={item.files}
                  text={item.link}
                  isDraggable={item.isDraggable}
                  groupId={group.id}
                  itemGroupId={group.groupId}
                  isLink
                  canDrag
                  dragData={{ id: item.id ?? index, name: item.link, index }}
                />
              )
              return (
                <>
                  <DragPreview>{child}</DragPreview>
                  {child}
                </>
              )
            })}
            {group.files && group.files.length > 0 ? (
              <DnDExpandedPhotos
                images={group.files}
                text="Скриншоты"
                isDraggable
                groupId={null}
                itemGroupId={null}
                isLink
                canDrag
                dragData={{ id: null ?? 0, name: null, index: null }}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
        <Input
          id="first_name"
          style={{ width: '50%' }}
          type="date"
          value={group.purchased_at ? group.purchased_at.toISOString().split('T')[0] : ''}
          onChange={(e) => {
            const selectedDate = e.target.value
            const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(selectedDate)
            if (isValidDate) {
              const date = new Date(selectedDate)
              updatePurchasedAt(group.id, date)
            } else updatePurchasedAt(group.id, selectedDate)
          }}
          placeholder="Дата отправки"
        />
      </Grid>
    </div>
  )
}

export default DropGroup
