import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import TextField from '@material-ui/core/TextField'
import useSWR from 'swr'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import { formattedMoney, getLinkName, getStatusLabel, STATUS, typesOfClient, useAuth } from '../../utils/customHooks'
import ExpandedPhotos from '../order/ExpandedPhotos'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'
import fetcher from '../../utils/fetcher'
import { formatDateWithTime } from '../../utils/date'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
  preColor: {
    color: theme.palette.text.secondary,
    fontFamily: 'Arial',
    fontSize: '10pt',
  },
}))

export default function TaobaoRegistration() {
  const classes = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [status, setStatus] = useState('')
  const [comment, setComment] = useState('')
  const [walletToSendId, setWalletToSendId] = useState(0)
  const errorComment = comment.length > 200
  const [walletsToSent, setWalletsToSent] = useState([])
  const [isComissionConsidered, setIsComissionConsidered] = useState(false)
  const [commission, setCommission] = useState(0)

  const { data: state, mutate } = useSWR(`/taobao-register/${id}`, fetcher, {
    onSuccess: ({ status: statusData }) => {
      setStatus(statusData)
    },
  })

  async function fetchWalletsToSent() {
    if (state?.currency_code_to) {
      await AxiosService.get(`/wallets/send?currency_code=${state?.currency_code_to}&without_disabled=1`).then(
        (res) => {
          setWalletsToSent(res.data)
          setWalletToSendId(res.data?.[0]?.id)
        }
      )
    }
    return []
  }

  useEffect(() => {
    fetchWalletsToSent()
  }, [state?.currency_code_to])

  const handleChange = (event) => {
    setStatus(event.target.value)
  }

  const [pictures, setPictures] = useState([])

  function formatWalletToSendRequisites() {
    if (state?.wallet_to_send_address === state?.wallet_to_send_receiver_name) {
      return state?.wallet_to_send_address
    }

    return `${state?.wallet_to_send_address} ${state?.wallet_to_send_receiver_name || ''} `
  }

  const firstColumnWidth = 4
  const secondColumnWidth = 8
  const dividerWidth = 12

  function getStatusesChoise() {
    switch (state?.status) {
      case STATUS.REQUISITES_SENT:
        return (
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={handleChange}>
            <MenuItem value={STATUS.REQUISITES_SENT}>{getStatusLabel(STATUS.REQUISITES_SENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_SENT:
        return (
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={handleChange}>
            <MenuItem value={STATUS.PAYMENT_SENT}>{getStatusLabel(STATUS.PAYMENT_SENT)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.QR_SENT:
        return (
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={handleChange}>
            <MenuItem value={STATUS.QR_SENT}>{getStatusLabel(STATUS.QR_SENT)}</MenuItem>
            <MenuItem value={STATUS.QR_SCANNED}>{getStatusLabel(STATUS.QR_SCANNED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_RECEIVED:
        return getStatusLabel(STATUS.PAYMENT_RECEIVED)
      case STATUS.COMPLETED:
        return getStatusLabel(STATUS.COMPLETED)
      case STATUS.PAYMENT_COMPLETED:
        return getStatusLabel(STATUS.PAYMENT_COMPLETED)
      case STATUS.CANCELLED:
        return getStatusLabel(STATUS.CANCELLED)
      case STATUS.PAYMENT_ERROR:
        return getStatusLabel(STATUS.PAYMENT_ERROR)
      default:
        return 'Ошибка сервера'
    }
  }

  const [openDialog, setOpenDialog] = useState(false)

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setComment('')
    setPictures([])
  }

  async function saveStatus() {
    const curState = state
    curState.status = status
    if ([STATUS.COMPLETED, STATUS.PAYMENT_ERROR, STATUS.PAYMENT_SENT, STATUS.REQUISITES_UPDATED].includes(status)) {
      // eslint-disable-next-line no-undef
      const formData = new FormData()

      if (comment !== '') {
        const fieldName = status !== STATUS.REQUISITES_UPDATED ? 'comment' : 'new_requisites'

        formData.append(fieldName, comment)
      }

      if (commission && isComissionConsidered) {
        formData.append('commission_amount', `${commission}`)
      }

      if (walletToSendId !== 0) {
        formData.append('wallet_to_send_id', `${walletToSendId}`)
      }

      if (pictures.length) {
        for (let i = 0; i < pictures.length; i += 1) {
          formData.append('files[]', pictures[pictures.length - 1])
        }
      }
      await AxiosService.post(`/taobao-register/${state?.id}/${status}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          mutate(res.data)
          handleCloseDialog()
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      await AxiosService.post(`/taobao-register/${state?.id}/${status}`).then((res) => {
        mutate(res.data)
      })
    }
  }

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const isDialogConfirmDisabled =
    errorComment || (status === STATUS.REQUISITES_UPDATED ? comment.length === 0 : pictures.length === 0)

  const shouldOpenDialog = [
    STATUS.COMPLETED,
    STATUS.PAYMENT_ERROR,
    STATUS.PAYMENT_SENT,
    STATUS.REQUISITES_UPDATED,
  ].includes(status)

  const dialogTitle =
    status !== STATUS.REQUISITES_UPDATED ? 'Загрузите изображения для клиента' : 'Введите новые реквизиты'
  const getExpandedPhotos = () => {
    const expandedPhotos = []

    if (state?.files.payment_sent) {
      expandedPhotos.push(<ExpandedPhotos text="Чек клиента" images={state?.files.payment_sent} />)
    }

    if (state?.files.payment_error) {
      expandedPhotos.push(<ExpandedPhotos text="Ошибка платежа" images={state?.files.payment_error} />)
    }

    if (state?.files.completed) {
      expandedPhotos.push(<ExpandedPhotos text="Отчет об отправке клиенту" images={state?.files.completed} />)
    }

    if (state?.files.wallet_to_requisite) {
      expandedPhotos.push(<ExpandedPhotos text="Реквизиты получателя" images={state?.files.wallet_to_requisite} />)
    }

    if (state?.files.qr_sent) {
      expandedPhotos.push(<ExpandedPhotos text="QR клиента" images={state?.files.qr_sent} />)
    }

    return expandedPhotos
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Данные получателя скопированы"
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <ButtonAppBar />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {[STATUS.PAYMENT_ERROR, STATUS.REQUISITES_UPDATED].includes(status) && (
            <TextField
              error={errorComment}
              helperText="Максимальная длина поля 200 символов"
              id="comment"
              style={{ width: '100%' }}
              multiline
              rows={4}
              label="Комментарий"
              variant="outlined"
              type="text"
              value={comment}
              onChange={(event) => {
                setComment(() => event.target.value)
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={saveStatus} color="primary" disabled={isDialogConfirmDisabled}>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <br />
      <Grid container justify="center">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={firstColumnWidth}>
                Идентификатор заказа:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {state?.register_code}
              </Grid>
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Статус:
              </Grid>
              <Grid item xs={5}>
                {getStatusesChoise()}
              </Grid>
              <Grid item xs={3}>
                <Button
                  size="small"
                  color="primary"
                  disabled={status === state?.status}
                  onClick={() => (shouldOpenDialog ? handleClickOpenDialog() : saveStatus())}
                >
                  Применить
                </Button>
              </Grid>
              {state?.comments?.length > 0 && (
                <>
                  <Grid item xs={dividerWidth}>
                    <Divider />
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    Комментарии:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Grid container alignItems="center" spacing={1}>
                      <>
                        <Grid item xs={12} />
                        {state?.comments.map((c) => (
                          <>
                            <Grid item xs={firstColumnWidth}>
                              <pre className={classes.preColor}>{c.created_at}</pre>
                            </Grid>
                            <Grid item xs={secondColumnWidth}>
                              <pre className={classes.preColor}>{c.text}</pre>
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {state?.created_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Создан:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formatDateWithTime(state.created_at)}
                  </Grid>
                </>
              )}
              {state?.payment_sent_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Деньги отправлены:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state?.payment_sent_at}
                  </Grid>
                </>
              )}
              {state?.payment_received_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Деньги получены:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state?.payment_received_at}
                  </Grid>
                </>
              )}
              {state?.completed_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Заявка завершена:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state?.completed_at}
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <>
                <Grid item xs={firstColumnWidth}>
                  Проверить зачисление:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  {formattedMoney(state?.give_amount)} {state?.currency_code_from} на
                  <Link
                    style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
                    to={`/wallets/${state?.wallet_to_send_id}/history`}
                  >
                    <span>
                      {' '}
                      {state?.wallet_to_send?.name}
                      {state?.wallet_to_send?.description ? ` (${state?.wallet_to_send?.description})` : ''}
                    </span>
                  </Link>
                  <br />
                  {formatWalletToSendRequisites()}
                  <CopyToClipboard text={formatWalletToSendRequisites()}>
                    <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClick} />
                  </CopyToClipboard>
                </Grid>
              </>
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Пользователь:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getLinkName(state?.client)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                ID пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {state?.client?.id}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Тип пользователя:
              </Grid>
              <Grid item xs={5}>
                {typesOfClient[state?.client?.type]}
              </Grid>
              {state?.client?.type === 'telegram' &&
                (user.permissions.includes(PERMISSIONS.Notifications) || user.permissions.includes('admin')) && (
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/notifications/create?client=${state?.client?.id}`)}
                    >
                      Отправить сообщение
                    </Button>
                  </Grid>
                )}
              {state?.files && state?.files[STATUS.PAYMENT_SENT] && (
                <Grid item xs={dividerWidth}>
                  <Divider />
                </Grid>
              )}
              {state?.files && getExpandedPhotos()}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
