import React, { useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import useSWR from 'swr'

import { Grid, Input, TextField, Select, MenuItem, Checkbox, Button, Paper, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AxiosService from '../../utils/axiosService'
import { formatDate } from '../../utils/date'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import CTable from './CTable'
import {
  nickName,
  getLinkName,
  getOrderData,
  getStatusLabel,
  getCalcProfitSum,
  getCalcCurrencies,
  getCurrenciesList,
  typesOfClient,
  useAuth,
} from '../../utils/customHooks'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'
import { getTaobaoTypeBadge } from '../../utils/getTaobaoTypeBadge'
import { getTaobaoOrderData } from '../../utils/getTaobaoOrderData'
import fetcher from '../../utils/fetcher'
import Loading from '../common/Loading'
import ErrorComponent from '../common/ErrorComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    fontSize: 14,
  },
}))

export default function Client() {
  const classes = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { register, reset, control, formState, getValues, watch, handleSubmit } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      description: '',
      verification: '',
      verified_cards: '',
      blocked: false,
      language_code: 'ru',
      wechat_id: '',
    },
  })

  const { data: client = {}, error: clientError, isLoading: isClientLoading, mutate } = useSWR(
    `/clients/${id}`,
    fetcher,
    {
      onSuccess: (data) =>
        reset({
          first_name: data.first_name,
          last_name: data.last_name,
          description: data.description,
          verification: data.verification,
          verified_cards: data.verified_cards,
          blocked: data.blocked === 1,
          language_code: data.language_code,
          wechat_id: data.wechat_id,
        }),
    }
  )
  const { data: currencies = [], error: exchangesError, isLoading: areExchangesLoading } = useSWR(`/exchanges`, fetcher)

  const watchVerification = watch('verification')

  const [currentCurrency, setCurrentCurrency] = useState(global.localStorage.getItem('ccurrency'))

  const currenciesList = Array.from(
    new Set(currencies.map((c) => (c.enabled === 1 ? c.to_currency.currency_code : false)))
  )

  const onSubmit = handleSubmit(async () => {
    const formValues = getValues()
    const editedFields = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      description: formValues.description,
      verification: formValues.verification,
      verified_cards: formValues.verified_cards,
      blocked: formValues.blocked ? 1 : 0,
      language_code: formValues.language_code || 'ru',
      wechat_id: formValues.wechat_id,
    }

    try {
      await AxiosService.put(`/clients/${id}`, editedFields)
      mutate(`/clients/${id}`)
    } catch (e) {
      console.error(e)
    }
  })

  const handleCurrencyChange = (event) => {
    localStorage.setItem('ccurrency', event.target.value)
    setCurrentCurrency(event.target.value)
  }

  function levelBadge(referralType) {
    switch (referralType) {
      case 'register_1st_level':
        return <span className="badge badge-light">Партнер 1-го уровня</span>
      case 'register_2nd_level':
        return <span className="badge badge-secondary">Партнер 2-го уровня</span>
      default:
        return <span className="badge badge-danger">Партнер неизвестного уровня</span>
    }
  }

  const firstColumnWidth = 4
  const secondColumnWidth = 8
  const dividerWidth = 12

  const userTransactionsColumns = [
    { id: 'order_code', label: '№ Заказа', minWidth: 100, maxWidth: 100 },
    { id: 'created_at', label: 'Дата', minWidth: 120, maxWidth: 120 },
    { id: 'status', label: 'Статус', minWidth: 70 },
    { id: 'order_data', label: 'Данные заказа', minWidth: 100 },
  ]

  const userTransactionsRows =
    client?.orders?.map((o) => ({
      order_code: (
        <Link to={`/orders/${o.id}`} style={{ color: `inherit` }}>
          {o.order_code}
        </Link>
      ),
      created_at: o.created_at,
      status: getStatusLabel(o.status),
      order_data: getOrderData(o),
    })) || []

  const userTaobaoOrdersRows =
    client?.taobao_orders?.map((o) => ({
      order_code: (
        <Link
          to={`/taobao-orders/${o.id}`}
          style={{
            ...getLinkStylesByPermission(PERMISSIONS.Taobao, { color: 'inherit' }),
          }}
        >
          {o.order_code}
        </Link>
      ),
      type: getTaobaoTypeBadge(o.type),
      created_at: formatDate(o.created_at),
      status: getStatusLabel(o.status),
      orderDescription: getTaobaoOrderData(o, o.baskets?.length, true),
    })) || []

  const userTaobaoRegistrationsColumns = [
    { id: 'order_code', label: '№ Заказа', minWidth: 100, maxWidth: 100 },
    { id: 'created_at', label: 'Дата', minWidth: 120, maxWidth: 120 },
    { id: 'status', label: 'Статус', minWidth: 70 },
    { id: 'order_data', label: 'Данные заказа', minWidth: 100 },
  ]

  const userTaobaoRegistrationsRows =
    client?.taobao_registers?.map((o) => ({
      order_code: (
        <Link
          to={`/taobao-registration/${o.id}`}
          style={{
            ...getLinkStylesByPermission(PERMISSIONS.Taobao, { color: 'inherit' }),
          }}
        >
          {o.register_code}
        </Link>
      ),
      created_at: formatDate(o.created_at),
      status: getStatusLabel(o.status),
      order_data: getOrderData(o),
    })) || []

  const userTaobaoOrdersColumns = [
    { id: 'order_code', label: '№ Заказа', minWidth: 100, maxWidth: 100 },
    { id: 'created_at', label: 'Дата', minWidth: 120, maxWidth: 120 },
    { id: 'status', label: 'Статус', minWidth: 70 },
    { id: 'type', label: 'Тип заказа', minWidth: 70 },
    { id: 'orderDescription', label: 'Описание заказа', minWidth: 100 },
  ]

  const partnersColumns =
    client?.type === 'taobao'
      ? [
          { id: 'id', label: '№ Клиента', minWidth: 120 },
          { id: 'partner_name', label: 'Имя пользователя', minWidth: 180 },
          { id: 'username', label: 'Юзернейм', minWidth: 120 },
          { id: 'referral_type', label: 'Уровень партнера', minWidth: 200 },
          { id: 'orders_count', label: 'Количество совершенных заказов', minWidth: 300 },
          { id: 'orders_sum', label: 'Общая сумма сделок', minWidth: 200 },
        ]
      : [
          { id: 'id', label: '№ Клиента', minWidth: 120 },
          { id: 'partner_name', label: 'Имя пользователя', minWidth: 180 },
          { id: 'username', label: 'Юзернейм', minWidth: 120 },
          { id: 'referral_type', label: 'Уровень партнера', minWidth: 200 },
          { id: 'orders_count', label: 'Количество совершенных заказов', minWidth: 300 },
          { id: 'orders_sum', label: 'Общая сумма сделок', minWidth: 200 },
          { id: 'partner_profit', label: 'Профит с партнера', minWidth: 200 },
        ]

  const partnersRows =
    client?.partners_invited?.map((rt) => ({
      id: rt.id,
      partner_name: getLinkName(rt),
      username: nickName(rt),
      referral_type: levelBadge(rt.partner_level),
      orders_count: rt?.type === 'taobao' ? rt.taobao_orders_count : rt.orders_count,
      orders_sum: getCalcProfitSum(
        rt?.type === 'taobao' ? rt.taobao_order_sum : rt.order_sum,
        currentCurrency,
        currenciesList,
        currencies
      ),
      partner_profit: getCalcCurrencies(
        rt?.type === 'taobao' ? rt.profit_from_taobao_partner : rt.profit_from_partner,
        currentCurrency,
        currenciesList,
        currencies
      ),
    })) || []

  const partnerTransactionsColumns = [
    { id: 'order_code', label: '№ Заказа', minWidth: 110 },
    { id: 'completed_at', label: 'Заявка завершена', minWidth: 120 },
    { id: 'partner_name', label: 'Имя партнера', minWidth: 150 },
    { id: 'referral_type', label: 'Уровень партнера', minWidth: 170 },
    { id: 'status', label: 'Статус', minWidth: 100 },
    { id: 'order_data', label: 'Данные заказа', minWidth: 350 },
    { id: 'profit_sum', label: 'Сумма вознаграждения', minWidth: 200 },
  ]

  const partnerTransactionsRows = (referralTransactions) =>
    referralTransactions?.map((o) => ({
      order_code: (
        <Link
          to={`${
            ['taobao_order', 'taobao_register', 'link', 'rent'].includes(o.order.type) ? '/taobao-orders/' : '/orders/'
          }${o.order.id}`}
          style={{ color: `inherit` }}
        >
          {o.order.order_code}
        </Link>
      ),
      completed_at: o?.order?.completed_at ? formatDate(o?.order?.completed_at) : '-',
      partner_name: getLinkName(o.order.client),
      referral_type: levelBadge(o.referral_type),
      status: getStatusLabel(o.order.status),
      order_data: getOrderData(o.order),
      profit_sum: getCalcCurrencies([o], currentCurrency, currenciesList, currencies),
    })) || []

  if (clientError || exchangesError) {
    return <ErrorComponent />
  }

  if (isClientLoading || areExchangesLoading) {
    return <Loading />
  }

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <ButtonAppBar />
      <br />
      <Grid container justify="center">
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={firstColumnWidth}>
                № клиента:
              </Grid>
              <Grid item xs={firstColumnWidth}>
                {client.id}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                <Grid container justify="flex-end" spacing={1}>
                  {getCurrenciesList(currencies, currenciesList, currentCurrency, handleCurrencyChange)}
                </Grid>
              </Grid>
              {client.registered_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Дата регистрации
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formatDate(client.registered_at)}
                  </Grid>
                </>
              )}
              <Grid item xs={firstColumnWidth}>
                Имя пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <Input
                  id="first_name"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Введите имя"
                  classes={{ input: classes.input }}
                  {...register('first_name')}
                />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Фамилия пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <Input
                  id="last_name"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Введите фамилию"
                  classes={{ input: classes.input }}
                  {...register('last_name')}
                />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Юзернейм:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {nickName(client)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Тип клиента:
              </Grid>
              <Grid item xs={5}>
                {typesOfClient[client?.type]}
              </Grid>
              {client.type === 'telegram' &&
                (user.permissions.includes(PERMISSIONS.Notifications) || user.permissions.includes('admin')) && (
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/notifications/create?client=${client.id}`)}
                    >
                      Отправить сообщение
                    </Button>
                  </Grid>
                )}
              <Grid item xs={firstColumnWidth}>
                Контакт:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {client.contact}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Описание:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <TextField
                  multiline
                  style={{ width: '100%' }}
                  rows={4}
                  placeholder="Введите описание пользователя"
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  {...register('description')}
                />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Wechat ID/другие заметки:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <Input
                  id="wechat_id"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Введите WeChat ID"
                  classes={{ input: classes.input }}
                  {...register('wechat_id')}
                />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Бан
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <Controller
                  control={control}
                  name="blocked"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox placeholder="Бан" color="primary" onChange={onChange} checked={value} />
                  )}
                />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Тип верификации
              </Grid>
              <Grid item xs={secondColumnWidth}>
                <Controller
                  control={control}
                  name="verification"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="verification"
                      id="verification"
                      key="verification"
                      style={{ width: '100%' }}
                      placeholder="Выберите тип верификации"
                      value={value}
                      classes={{ select: classes.input }}
                      onChange={onChange}
                    >
                      <MenuItem disabled value="" classes={{ root: classes.input }}>
                        <em>Тип верификации</em>
                      </MenuItem>
                      <MenuItem value="none" classes={{ root: classes.input }}>
                        Нету
                      </MenuItem>
                      <MenuItem value="by_card" classes={{ root: classes.input }}>
                        Картой
                      </MenuItem>
                      <MenuItem value="full" classes={{ root: classes.input }}>
                        Видео
                      </MenuItem>
                      <MenuItem value="manual" classes={{ root: classes.input }}>
                        Вручную
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              {(client.verified_cards?.length > 0 || watchVerification === 'by_card') && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Верифицированные карты
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <TextField
                      multiline
                      style={{ width: '100%' }}
                      rows={4}
                      placeholder="Введите верифицированные карты"
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                      }}
                      {...register('verified_cards')}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Количество заказов:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {client.orders_count}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Баланс кошелька:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getCalcCurrencies(client.user_wallet, currentCurrency, currenciesList, currencies)}
              </Grid>
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Приглашен пользователем:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getLinkName(client.invited_by)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Количество приглашенных партнеров:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {client.partners_invited_count}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Профит с партнерки:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getCalcProfitSum(client.referral_transactions_sum, currentCurrency, currenciesList, currencies)}
              </Grid>
              <CTable
                text="Заказы пользователя"
                rows={userTransactionsRows}
                columns={userTransactionsColumns}
                keyPref="userTransactions"
              />
              <CTable
                text="Заказы выкуп"
                rows={userTaobaoOrdersRows}
                columns={userTaobaoOrdersColumns}
                keyPref="userTaobaoOrders"
              />
              <CTable
                text="Регистрации Taobao"
                rows={userTaobaoRegistrationsRows}
                columns={userTaobaoRegistrationsColumns}
                keyPref="userTaobaoRegistrations"
              />
              <CTable text="Приглашенные партнеры" rows={partnersRows} columns={partnersColumns} keyPref="partners" />
              <CTable
                text="Заказы партнеров"
                rows={partnerTransactionsRows(client?.exchange_referral_transactions)}
                columns={partnerTransactionsColumns}
                keyPref="partnerTransactions"
              />
              <CTable
                text="Заказы партнеров по выкупу"
                rows={partnerTransactionsRows(client?.taobao_referral_transactions)}
                columns={partnerTransactionsColumns}
                keyPref="partnerTransactions"
              />
              <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                <Button type="submit" variant="contained" color="primary" disabled={!formState.isDirty}>
                  Сохранить изменения
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  )
}
