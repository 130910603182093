import React from 'react'

export const RenderBadge = ({ color_hash, name }, style = {}) => {
  if (!color_hash)
    return (
      <span style={style} className="badge badge-light">
        {name}
      </span>
    )
  return (
    <span
      style={{ background: `#${color_hash}`, color: color_hash === 'fff' && '#333', ...style }}
      className="badge badge-primary"
    >
      {name}
    </span>
  )
}

