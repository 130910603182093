import React from 'react'
import { Link } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import AxiosService from './axiosService'

export const CURRENCIES = {
  USDT: '🇺🇸USDT',
  CNY: '🇨🇳CNY',
  UAH: '🇺🇦UAH',
  RUB: '🇷🇺RUB',
  KZT: '🇰🇿KZT',
}

export const STATUS = {
  WAITING_MANAGER: 'wait_for_manager_readiness',
  WAITING_CLIENT: 'wait_for_client_readiness',
  CLIENT_IS_WAITING: 'wait_for_requisites',
  REQUISITES_SENT: 'new',
  CANCELLED_BY_USER: 'cancelled_by_user',
  PAYMENT_SENT: 'payment_sent',
  PAYMENT_RECEIVED: 'payment_received',
  COMPLETED: 'completed',
  MANUAL_COMPLETED: 'manual_completed',
  CANCELLED: 'cancelled',
  PAYMENT_ERROR: 'payment_error',
  REQUISITES_UPDATED: 'requisites_updated',
  SEND_RECEIPT_TO_CLIENT: 'send_receipt_to_client',
  QR_SENT: 'qr_sent',
  QR_SCANNED: 'qr_scanned',
  PREPARE: 'prepare',
  PAYMENT_PURCHASED: 'payment_purchased',
  PURCHASED: 'purchased',
  PAYMENT_COMPLETED: 'payment_completed',
  PAYMENT_CHECK: 'waiting_price_count',
  PAYMENT_CHECKED: 'price_counted',
  CLIENT_CANCELLED: 'client_cancelled',
  PARTIALLY_COMPLETED: 'partially_completed',
}

export function getStatusLabel(status) {
  const statuses = {
    [STATUS.WAITING_MANAGER]: <span className="badge badge-warning">Ожидаем готовность менеджера</span>,
    [STATUS.WAITING_CLIENT]: <span className="badge badge-secondary">Ожидаем готовность клиента</span>,
    [STATUS.CLIENT_IS_WAITING]: <span className="badge badge-warning">Клиент ожидает реквизиты</span>,
    [STATUS.REQUISITES_SENT]: <span className="badge badge-light">Реквизиты отправлены</span>,
    [STATUS.CANCELLED_BY_USER]: <span className="badge badge-danger">Отменен пользователем</span>,
    [STATUS.PAYMENT_SENT]: <span className="badge badge-primary">Деньги отправлены</span>,
    [STATUS.PAYMENT_RECEIVED]: <span className="badge badge-info">Деньги получены</span>,
    [STATUS.PREPARE]: <span className="badge badge-info">Заказ готовится</span>,
    [STATUS.REQUISITES_UPDATED]: <span className="badge badge-dark">Реквизиты обновлены</span>,
    [STATUS.QR_SENT]: <span className="badge badge-dark">QR отправлен</span>,
    [STATUS.SEND_RECEIPT_TO_CLIENT]: <span className="badge badge-light">Отправить чек клиенту</span>,
    [STATUS.COMPLETED]: <span className="badge badge-success">Завершен</span>,
    [STATUS.QR_SCANNED]: <span className="badge badge-success">QR отсканирован</span>,
    [STATUS.MANUAL_COMPLETED]: <span className="badge badge-success">Завершен в ручном режиме</span>,
    [STATUS.CANCELLED]: <span className="badge badge-danger">Отменен</span>,
    [STATUS.PAYMENT_ERROR]: <span className="badge badge-warning">Ошибка платежа</span>,
    [STATUS.PAYMENT_CHECK]: <span className="badge badge-light">Ожидает формирования цены</span>,
    [STATUS.PAYMENT_PURCHASED]: <span className="badge badge-primary">Товар выкуплен</span>,
    [STATUS.PURCHASED]: <span className="badge badge-primary">Товар выкуплен</span>,
    [STATUS.PAYMENT_COMPLETED]: <span className="badge badge-success">Завершен</span>,
    [STATUS.PARTIALLY_COMPLETED]: <span className="badge badge-success">Завершен частично</span>,

    [STATUS.PAYMENT_CHECKED]: <span className="badge badge-primary">Цена сформирована</span>,
    [STATUS.CLIENT_CANCELLED]: <span className="badge badge-danger">Отменен пользователем</span>,
  }
  return status ? statuses[status] : statuses
}

// eslint-disable-next-line no-undef
const localStorage1 = localStorage

export function useAuth() {
  const refresh = () =>
    AxiosService.get('/me').then((res) => {
      // eslint-disable-next-line no-undef
      localStorage.setItem('user', JSON.stringify(res.data))
    })

  return {
    user: localStorage1.getItem('user') !== 'undefined' ? JSON.parse(localStorage1.getItem('user')) : null,
    token: localStorage1.getItem('token') !== 'undefined' ? localStorage1.getItem('token') : '',
    refresh,
  }
}

export function refreshPage() {
  // eslint-disable-next-line no-undef
  window.location.reload()
}

export async function logouts() {
  await AxiosService.post('/logout')
    .then(() => {
      localStorage1.removeItem('user')
      localStorage1.removeItem('token')
    })
    .catch((err) => {
      console.error('err', err)
      if (err.response) {
        console.error(err.response.status)
      }
      localStorage1.removeItem('user')
      localStorage1.removeItem('token')
    })
  refreshPage()
}

export function nickName(state) {
  return state.telegram_username !== '' && state.telegram_username !== null ? (
    <a
      href={`https://t.me/${state.telegram_username}`}
      style={{ color: `inherit` }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {state.telegram_username}
    </a>
  ) : (
    `-`
  )
}

export function getName(state) {
  const fullName = `${state.first_name !== '' && state.first_name !== null ? state.first_name : ''} ${
    state.last_name !== '' && state.last_name !== null ? state.last_name : ''
  }`
  return fullName !== ' ' ? fullName : `Имя отсутствует`
}

export function getLinkName(state) {
  if (state && Object.keys(state).length !== 0) {
    return (
      <Link to={`/clients/${state.id}`} style={{ color: `inherit` }}>
        {getName(state)}
      </Link>
    )
  }
  return '-'
}
export function getCurrenciesList(currencies, currenciesList, currentCurrency, handleChange, defaultText) {
  if (currencies && currencies.length > 0 && currenciesList && currenciesList.length > 0) {
    return (
      <div>
        <Select
          labelId="currencies"
          id="currencies"
          key="currencies"
          value={currentCurrency || ''}
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          <MenuItem disabled value="">
            <em>{defaultText || 'Валюта по умолчанию'}</em>
          </MenuItem>
          {currenciesList.map((c) => (
            <MenuItem value={c} key={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
  return ''
}

export function formattedMoney(money) {
  return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2 }).format(money)
}

export function formattedCurrencies(currency) {
  return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 6 }).format(currency)
}

export function formattedCurrenciesFixed(currency, roundDigits = 4) {
  return Number(currency)
    ? // eslint-disable-next-line no-restricted-properties
      Math.round(currency * Math.pow(10, roundDigits)) / Math.pow(10, roundDigits)
    : currency
}

export function getOrderData(order) {
  const p2p_order = order?.real_give_amount
    ? `${formattedMoney(order?.real_give_amount)} ${order?.real_give_amount_currency_code} ->`
    : ''

  if (['taobao_order', 'taobao_register', 'link', 'rent'].includes(order.type))
    return order.status !== STATUS.PAYMENT_CHECK
      ? `${formattedMoney(order.give_amount)} ${order.currency_code_from} -> ${p2p_order} ${formattedMoney(
          order.receive_amount
        )} 🇨🇳CNY (${order?.rate || order?.rate_description})`
      : getStatusLabel(STATUS.PAYMENT_CHECK)
  switch (order.type) {
    case `my_wallet_replenish`:
      return `${formattedMoney(order.give_amount)} ${order.currency_code_from} -> ${p2p_order} 💰Кошелек`
    case `my_wallet_withdraw`:
      return `${formattedMoney(order.give_amount)} ${order.currency_code_from} 💰Кошелек -> ${formattedMoney(
        order.receive_amount
      )} ${order.currency_code_to}`
    case `exchange_from_wallet`:
      return `${formattedMoney(order.give_amount)} ${order.currency_code_from} 💰Кошелек -> ${formattedMoney(
        order.receive_amount
      )} ${order.currency_code_to} (${order.rate || order.rate_description})`
    case `exchange_to_wallet`:
      return `${formattedMoney(order.give_amount)} ${order.currency_code_from} -> ${p2p_order} ${formattedMoney(
        order.receive_amount
      )} ${order.currency_code_to} (${order.rate || order.rate_description}) -> 💰Кошелек`
    default:
      return `${formattedMoney(order.give_amount)} ${order.currency_code_from} -> ${p2p_order} ${formattedMoney(
        order.receive_amount
      )} ${order.currency_code_to} (${order.rate || order.rate_description})`
  }
}

export function formatWalletToSendRequisitesSecret(walletToSend) {
  const requisite =
    walletToSend.type === 'crypto'
      ? `${walletToSend.account_num.substr(0, 5)}**${walletToSend.account_num.substr(-5)}`
      : `*${walletToSend.account_num.substr(-4)} ${walletToSend.receiver_name}`

  return `${walletToSend.description ? `[${walletToSend.description}] ` : ''} ${walletToSend.name} ${requisite}`
}

export const getManualToSourceLabels = {
  0: <span className="badge badge-primary">Telegram</span>,
  1: <span className="badge badge-light">Ручной</span>,
}

export function getCalcCurrencies(wallets, currentCurrency, currencyesList, currencyes) {
  if (wallets && Object.keys(wallets).length !== 0) {
    return wallets.map((uw) => {
      // eslint-disable-next-line no-param-reassign
      if (uw.currency) uw.currency_code = uw.currency
      const amount = formattedMoney(uw.amount)
      if (!currentCurrency || currentCurrency === uw.currency_code || !currencyesList.includes(uw.currency_code)) {
        return (
          <div key={Math.random()}>
            {uw.currency_code}: {amount}
          </div>
        )
      }
      const { clear_rate } = currencyes.find(
        (c) => c.from_currency.currency_code === uw.currency_code && c.to_currency.currency_code === currentCurrency
      )
      return (
        <div key={Math.random()}>
          {uw.currency_code}: {amount} ~ {currentCurrency}: {formattedMoney(uw.amount * clear_rate)}
        </div>
      )
    })
  }
  return '-'
}

export function getCalcProfitSum(referralTransactionsSum = [], currentCurrency, currencyesList, currencyes) {
  const refSum = []

  Object.keys(referralTransactionsSum).forEach((key) => {
    refSum.push({ currency_code: key, amount: referralTransactionsSum[key] })
  })
  return getCalcCurrencies(refSum, currentCurrency, currencyesList, currencyes)
}

export const ALPHABETS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '-',
]

export const typesOfClient = {
  telegram: <span className="badge badge-primary">Telegram</span>,
  wechat: <span className="badge badge-info">WeChat</span>,
  taobao: <span className="badge badge-secondary">Taobao</span>,
  contractor: <span className="badge badge-warning">Контрагент</span>,
  stock: <span className="badge badge-danger">Биржа</span>,
  other: <span className="badge badge-light">Other</span>,
}

export const typesOfClientAvailableForCreate = {
  wechat: <span className="badge badge-info">WeChat</span>,
  contractor: <span className="badge badge-warning">Контрагент</span>,
  stock: <span className="badge badge-danger">Биржа</span>,
  other: <span className="badge badge-light">Other</span>,
}

export const REQUEST_DIRECTIONS = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
}

export function getRequestDirectionLabel(requestDirection) {
  const requestDirections = {
    [REQUEST_DIRECTIONS.OUTBOUND]: <span className="badge badge-warning">Исходящая</span>,
    [REQUEST_DIRECTIONS.INBOUND]: <span className="badge badge-success">Входящая</span>,
  }
  return requestDirections[requestDirection] ? requestDirections[requestDirection] : requestDirection
}
