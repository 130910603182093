import React, { useEffect, useState, createRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import Expenses from '../expenses/Expenses'
import { RenderBadge } from '../expenses/RenderBadge'

export default function ExpenseCategories() {
  const tableRef = createRef()
  const navigate = useNavigate()
  const [expensesCategories, setExpensesCategories] = useState()

  const columns = [
    {
      title: 'Название',
      field: 'name',
      render: (rowData) => {
        const { parent_id, name } = rowData
        return parent_id == null ? RenderBadge(rowData) : <span style={{ paddingLeft: 15 }}>{name}</span>
      },
      width: '40%',
      cellStyle: { width: '40%' },
    },
    {
      title: 'Родительская категория',
      field: 'parent_id',
      render: (rowData) =>
        rowData.parent_id
          ? RenderBadge(expensesCategories?.filter((category) => category.id === rowData.parent_id)[0])
          : '-',
      width: '40%',
      cellStyle: { width: '40%' },
    },
  ]

  useEffect(() => {
    const getExpensesCategories = () => {
      AxiosService.get('/expense-categories').then((result) => {
        let tableData = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < result?.data?.length; i++) {
          tableData.push(result.data[i])
          if (result.data[i].children) {
            tableData = [...tableData, ...result.data[i].children]
          }
        }
        setExpensesCategories(tableData)
      })
    }
    if (!expensesCategories) getExpensesCategories()
  }, [expensesCategories])

  useDidMountEffect(() => {
    tableRef?.current?.onQueryChange()
  }, [tableRef])

  return (
    <>
      <ButtonAppBar />
      <Expenses categories={expensesCategories} />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={11} lg={10}>
          <br />
          {expensesCategories ? (
            <MaterialTable
              tableRef={tableRef}
              title="Категории расходов"
              searchable={false}
              columns={columns}
              onRowClick={(event, rowData) => navigate(`/expenses-categories/${rowData.id}`)}
              editable={{
                onRowDelete: (rowData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve()
                      AxiosService.delete(`/expense-categories/${rowData.id}`, {
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                      })
                        .then(() => {
                          setExpensesCategories((prevState) =>
                            prevState.filter((categoryData) => categoryData.id !== rowData.id)
                          )
                          // eslint-disable-next-line no-unused-expressions
                          tableRef.current && tableRef.current.onQueryChange()
                        })
                        .catch((err) => {
                          console.log('err', err)
                          if (err.response) {
                            console.log(err.response.status)
                          }
                        })
                      // eslint-disable-next-line no-unused-expressions
                      tableRef.current && tableRef.current.onQueryChange()
                    }, 600)
                  }),
              }}
              data={() =>
                new Promise((resolve) => {
                  resolve({
                    data: expensesCategories?.map(({ id, color_hash, created_at, name, parent_id }) => ({
                      id,
                      color_hash,
                      created_at,
                      name,
                      parent_id,
                    })),
                  })
                })
              }
              options={{
                search: false,
                sorting: false,
                // columnsButton: true,
                paging: false,
                pageSize: 15,
                pageSizeOptions: [15],
                actionsColumnIndex: -1,
                actionsCellStyle: { paddingLeft: '135px' },
                rowStyle: (rowData) => ({ height: !rowData.parent_id ? 78 : 0 }),
              }}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                {
                  icon: 'add',
                  tooltip: 'Создать категорию расходов',
                  isFreeAction: true,
                  onClick: () => navigate('/expenses-categories/create'),
                },
              ]}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  )
}
