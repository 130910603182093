import React, { useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import useSWR from 'swr'

import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { FormControl, InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'

import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import {
  getCalcCurrencies,
  getCalcProfitSum,
  getCurrenciesList,
  getLinkName,
  getOrderData,
  nickName,
  typesOfClient,
  useAuth,
} from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import fetcher from '../../utils/fetcher'
import ErrorComponent from '../common/ErrorComponent'
import Loading from '../common/Loading'

export default function Clients() {
  const columns = [
    {
      title: '№ Клиента',
      field: 'id',
    },
    {
      title: 'Тип клиента',
      field: 'type',
      render: ({ type }) => <>{typesOfClient[type]}</>,
    },
    {
      title: 'Имя пользователя',
      field: 'name',
    },
    {
      title: 'Юзернейм',
      field: 'nick_name',
    },
    {
      title: 'Заказы',
      field: 'orders_count',
      width: 200,
    },
    {
      title: 'Последний заказ',
      field: 'last_order_data',
    },
    {
      title: 'Кол-во приглашенных партнеров',
      field: 'partners_invited_count',
      width: 200,
    },
    {
      title: 'Баланс кошельков',
      field: 'wallets',
    },
    {
      title: 'Профит с партнерки',
      field: 'profits',
    },
  ]
  const tableRef = React.createRef()
  const navigate = useNavigate()

  const [currencies, setCurrencies] = useState({})
  const [currenciesList, setCurrenciesList] = useState([])

  const [totalBalance, setTotalBalance] = useState({})
  const [totalReferralRewards, setTotalReferralRewards] = useState([])

  const { user } = useAuth()
  const isAdmin = user?.permissions?.includes('admin')

  const [currentCurrency, setCurrentCurrency] = useState(localStorage.getItem('ccurrency'))

  const { error: currenciesError, isLoading: isCurrenciesLoading } = useSWR(`/exchanges`, fetcher, {
    onSuccess: (data) => {
      setCurrencies(data)
      setCurrenciesList(Array.from(new Set(data?.map((c) => (c.enabled === 1 ? c.to_currency.currency_code : false)))))
    },
  })

  if (isAdmin) {
    useSWR(`/clients/statistics`, fetcher, {
      onSuccess: (data) => {
        setTotalBalance(data?.total_balance)
        setTotalReferralRewards(data.total_referral_rewards)
      },
    })
  }

  const handleChange = (event) => {
    localStorage.setItem('ccurrency', event.target.value)
    tableRef?.current?.onQueryChange()
    setCurrentCurrency(event.target.value)
  }

  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    parse(window.location.search) || {
      search_string: '',
      type: '',
    }
  )

  useDidMountEffect(() => {
    navigate(`/clients?${stringify(parsed)}`)
    tableRef?.current?.onQueryChange()
  }, [parsed])

  const searchString = (
    <TextField
      id="outlined-basic"
      label="Поиск"
      variant="outlined"
      type="text"
      value={parsed.search_string || ''}
      onChange={(event) => dispatch({ search_string: event.target.value || undefined })}
    />
  )

  const typesOfClientFilter = (
    <FormControl variant="outlined" style={{ minWidth: 150 }}>
      <InputLabel id="demo-simple-select-outlined-label">Тип клиента</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.type ? parsed.type : ''}
        onChange={(event) => dispatch({ type: event.target.value || undefined })}
        label="Тип клиента"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(typesOfClient).map((key) => (
          <MenuItem key={key} value={key}>
            {typesOfClient[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const getData = async (query) => {
    const url = `/clients?page=${query.page + 1}&${stringify(parsed)}`
    const { data } = await AxiosService.get(url)

    return {
      data: data.data.map(
        ({
          id,
          type,
          first_name,
          last_name,
          telegram_username,
          orders_count: all_orders_count,
          completed_orders_count,
          partners_invited_count,
          user_wallet,
          referral_transactions_sum,
          last_order: lastOrder,
        }) => {
          const wallets = getCalcCurrencies(user_wallet, currentCurrency, currenciesList, currencies)
          const profits = getCalcProfitSum(referral_transactions_sum, currentCurrency, currenciesList, currencies)
          const last_order_data = lastOrder ? getOrderData(lastOrder) : '-'

          return {
            id,
            type,
            name: getLinkName({ id, first_name, last_name }),
            nick_name: nickName({ telegram_username }),
            orders_count: all_orders_count ? `${completed_orders_count}/${all_orders_count}` : '-',
            completed_orders_count,
            partners_invited_count,
            wallets,
            profits,
            last_order_data,
          }
        }
      ),
      page: data.current_page - 1,
      totalCount: data.total,
    }
  }

  if (currenciesError) {
    return <ErrorComponent />
  }

  if (isCurrenciesLoading) {
    return <Loading />
  }

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item xs={12}>
          <Box alignItems="center" justifyContent="center" display="flex" flexWrap="wrap">
            <Box style={{ minWidth: '30%' }} alignItems="center" justifyContent="center">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item>Валюта по умолчанию:</Grid>
                <Grid item>
                  {currencies && Object.keys(currencies).length !== 0 && currenciesList.length !== 0
                    ? getCurrenciesList(currencies, currenciesList, currentCurrency, handleChange)
                    : false}
                </Grid>
              </Grid>
            </Box>
            {user && isAdmin ? (
              <>
                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                <Box style={{ minWidth: '30%' }} alignItems="center" justifyContent="center">
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item>Сумма балансов:</Grid>
                    <Grid item>{getCalcCurrencies(totalBalance, currentCurrency, currenciesList, currencies)}</Grid>
                  </Grid>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                <Box style={{ minWidth: '30%' }} alignItems="center" justifyContent="center">
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item>Общий профит:</Grid>
                    <Grid item>
                      {getCalcCurrencies(totalReferralRewards, currentCurrency, currenciesList, currencies)}
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid container item xs={12} sm={12} md={11} lg={10} spacing={2}>
          <Grid item>{searchString}</Grid>
          <Grid item>{typesOfClientFilter}</Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={10}>
          {currencies && Object.keys(currencies).length !== 0 && currenciesList.length !== 0 && (
            <MaterialTable
              key="clients-table"
              title="Пользователи"
              tableRef={tableRef}
              searchable={false}
              columns={columns}
              data={getData}
              options={{
                search: false,
                sorting: false,
                paging: true,
                pageSize: 15,
                pageSizeOptions: [15],
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Обновить данные',
                  isFreeAction: true,
                  onClick: () => tableRef?.current?.onQueryChange(),
                },
                {
                  icon: 'add',
                  tooltip: 'Добавить клиента',
                  isFreeAction: true,
                  onClick: () => {
                    navigate('/clients/create')
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
