import React, { createRef, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { parse, stringify } from 'query-string'
import { useSnackbar } from 'notistack'
import useSWR from 'swr'
import DateFnsUtils from '@date-io/date-fns'
import { format, parse as parseDate } from 'date-fns'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, InputLabel } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import { getLinkName, getStatusLabel, STATUS } from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import fetcher from '../../utils/fetcher'
import { formatDateWithTime } from '../../utils/date'
import { getTaobaoOrderData } from '../../utils/getTaobaoOrderData'
import { getTaobaoTypeBadge } from '../../utils/getTaobaoTypeBadge'

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  textOverflowWithDots: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

export default function TaobaoOrders() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    parse(window.location.search) || {
      search_string: '',
      type: '',
      status: '',
      client_type: '',
      created_at_from: '',
      created_at_to: '',
    }
  )
  const tableRef = createRef()
  const { data: statuses } = useSWR('/taobao-order/statuses', fetcher)

  const onChange = (e) => {
    dispatch({ search_string: e.target.value || undefined, page: 1 })
  }

  const debouncedOnChange = debounce(onChange, 300)

  const handleExportClick = async () => {
    const url = `/taobao-order/export-csv?${stringify(parsed)}`
    const { data } = await AxiosService.get(url)

    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', `Выкуп (${format(new Date(), 'dd-MM-yyyy')})`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const statusFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-outlined-label">Статус</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.status ? parsed.status : ''}
        onChange={(event) => dispatch({ status: event.target.value || undefined })}
        label="Статус"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {statuses &&
          statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {getStatusLabel(status)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )

  const typesLabels = {
    link: 'По ссылке',
    rent: 'Аренда',
  }
  const typesFilter = (
    <FormControl variant="outlined" className={classes.formControlTypes}>
      <InputLabel id="demo-simple-select-outlined-label">Тип заказа</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.type ? parsed.type : ''}
        onChange={(event) => dispatch({ type: event.target.value || undefined })}
        label="Тип заказа"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(typesLabels).map((key) => (
          <MenuItem key={key} value={key}>
            {getTaobaoTypeBadge(key)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const createdAtFrom = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_from"
        label="Создан после"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_from ? parseDate(parsed?.created_at_from, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_from: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const createdAtTo = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_to"
        label="Создан до"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_to ? parseDate(parsed?.created_at_to, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_to: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const codeFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <TextField
        id="outlined-basic"
        label="Поиск"
        variant="outlined"
        type="text"
        defaultValue={parsed.search_string || ''}
        onChange={debouncedOnChange}
      />
    </FormControl>
  )

  const columns = [
    {
      title: 'Дата',
      field: 'created_at',
      maxWidth: 110,
      render: ({ created_at }) => formatDateWithTime(created_at),
    },
    {
      title: '№ Заказа',
      field: 'order_code',
      maxWidth: 80,
    },
    {
      title: 'Статус',
      field: 'status',
      maxWidth: 200,
      render: ({ is_p2p, status }) => (
        <Grid container item style={{ display: 'flex' }}>
          <Grid item style={{ marginRight: is_p2p ? '8px' : 0 }}>
            {getStatusLabel(status)}
          </Grid>
          <Grid item>{is_p2p ? <span className="badge badge-dark">P2P</span> : null}</Grid>
        </Grid>
      ),
    },
    {
      field: 'order_data',
      title: 'Данные заказа',
      render: ({ order_data, cargo }) => {
        if (cargo === 'client') {
          return (
            <>
              {order_data.country || '-'}, {order_data.delivery_method || '-'}, {order_data.give_amount}{' '}
              {order_data.currency_code_from}
            </>
          )
        }
        if (order_data.currency_code_from && order_data.give_amount) {
          return (
            <>
              {order_data.give_amount} {order_data.currency_code_from}
            </>
          )
        }
        return '-'
      },
    },
    { field: 'type', title: 'Тип заказа', render: ({ type }) => getTaobaoTypeBadge(type) },
    { field: 'orderDescription', title: 'Описание', render: ({ orderDescription }) => <>{orderDescription}</> },
    {
      title: 'В работе у',
      field: 'assign_to',
      maxWidth: 100,
      render: ({ assign_to = {}, clean_order_code = '', id = 0, status }) => {
        const throttledOnClickToAssignToMe = throttle(() => {
          enqueueSnackbar(`Заявка на выкуп ${clean_order_code} взята в работу`, {
            variant: 'success',
            autoHideDuration: 6000,
          })

          AxiosService.post(`/taobao-order/${id}/assign-to-me`)
            .then(() => {
              tableRef?.current?.onQueryChange()
            })
            .catch((err) => {
              console.log('err', err)
              if (err.response) {
                console.log(err.response.status)
              }
            })
        }, 30000)

        if (assign_to?.email) {
          return <div className={classes.textOverflowWithDots}>{assign_to?.email}</div>
        }
        if (status && ![STATUS.COMPLETED, STATUS.CANCELLED, STATUS.CANCELLED_BY_USER].includes(status))
          return (
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={throttledOnClickToAssignToMe}
              style={{ whiteSpace: 'nowrap' }}
            >
              В работу
            </Button>
          )
        return '-'
      },
    },
    {
      title: 'Имя клиента',
      field: 'name',
      maxWidth: 130,
    },
  ]

  useDidMountEffect(() => {
    navigate(`/taobao-orders?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  return (
    <div>
      <ButtonAppBar />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <br />
          {codeFilter}
          {statusFilter}
          {typesFilter}
          {createdAtFrom}
          {createdAtTo}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <br />
          <MaterialTable
            title="Заказы выкуп"
            tableRef={tableRef}
            searchable={false}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                let url = '/taobao-order?'
                url += `&${stringify({ ...parsed, page: query.page + 1 })}`
                AxiosService.get(url).then((result) => {
                  resolve({
                    data: result?.data?.data?.map(
                      ({
                        id,
                        order_code: orderCode,
                        status,
                        is_p2p,
                        created_at,
                        delivery_price,
                        commission,
                        client,
                        delivery_method,
                        country,
                        cargo,
                        type,
                        baskets,
                        receive_amount,
                        give_amount,
                        currency_code_from,
                        real_give_amount,
                        real_give_amount_currency_code,
                        assign_to,
                      }) => ({
                        id,
                        type,
                        orderDescription: getTaobaoOrderData(
                          {
                            type,
                            receive_amount,
                            commission,
                            delivery_price,
                            give_amount,
                            currency_code_from,
                            real_give_amount,
                            real_give_amount_currency_code,
                          },
                          baskets?.length,
                          true
                        ),
                        order_code: (
                          <Link to={`/taobao-orders/${id}`} style={{ color: `inherit` }}>
                            {orderCode}
                          </Link>
                        ),
                        status,
                        is_p2p,
                        cargo,
                        created_at,
                        order_data: {
                          delivery_method,
                          country,
                          give_amount,
                          currency_code_from,
                        },
                        name: getLinkName(client),
                        assign_to,
                        clean_order_code: orderCode,
                      })
                    ),
                    page: result?.data?.current_page - 1,
                    totalCount: result?.data?.total,
                  })
                })
              })
            }
            options={{
              search: false,
              sorting: false,
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15],
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'download',
                tooltip: 'Выгрузить CSV',
                isFreeAction: true,
                onClick: () => {
                  handleExportClick()
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
