import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { omit } from 'lodash'
import debounce from 'lodash/debounce'
import {
  Button,
  Grid,
  MenuItem,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Select,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import RefreshIcon from '@material-ui/icons/Refresh'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import ButtonAppBar from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import {
  ALPHABETS,
  CURRENCIES,
  formattedCurrenciesFixed,
  formatWalletToSendRequisitesSecret,
  getRequestDirectionLabel,
} from '../../utils/customHooks'
import { formatAutocompleteClientName } from '../../utils/text'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}))

const INTERNAL_WALLET = 'internal_wallet'

export default function OrderCreation() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const firstColumnWidth = 4
  const secondColumnWidth = 8

  const { register, watch, getValues, setValue, trigger } = useForm({
    defaultValues: {
      markup: '0',
      device: '-',
      request_direction: 'inbound',
    },
  })
  const formValues = watch()

  const [currenciesList, setCurrenciesList] = useState([])
  const [currencyCodeFrom, setCurrencyCodeFrom] = useState('')
  const [currencyCodeTo, setCurrencyCodeTo] = useState('')
  const [baseRate, setBaseRate] = useState(1)
  const [walletsToSent, setWalletsToSent] = useState([])

  async function fetchWalletsToSent() {
    if (currencyCodeFrom) {
      await AxiosService.get(`/wallets/send?currency_code=${currencyCodeFrom}&without_disabled=1`).then((res) => {
        setWalletsToSent(res.data)
      })
    }
    return []
  }

  const deviceOptions = [
    { value: '-', label: '-' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ]
  const requestDirectionOptions = [
    { value: 'inbound', label: 'Входящая' },
    { value: 'outbound', label: 'Исходящая' },
  ]
  useEffect(() => {
    fetchWalletsToSent()
  }, [currencyCodeFrom])

  async function fetchCurrentExchange() {
    if (currencyCodeFrom && currencyCodeTo) {
      await AxiosService.get(`/exchanges?from_currency=${currencyCodeFrom}&to_currency=${currencyCodeTo}`).then((res) => {
        const [exchange = {}] = res.data
        let rateValue = 1.0

        if (typeof exchange.rate_big_amount !== 'undefined') {
          rateValue = exchange?.is_reversed
            ? `${formattedCurrenciesFixed(1 / +exchange?.rate_big_amount || 1, 6)}`
            : `${exchange?.rate_big_amount}`
        }

        setBaseRate(rateValue)
        setValue('rate', rateValue)
        setValue('is_reversed', exchange.is_reversed || 0)
      })
    }
    return []
  }

  useEffect(() => {
    const walletRatePercent = walletsToSent.find(({ id }) => id === formValues?.wallet_to_send_id)?.wallet_rate_percent
    if (walletRatePercent && getValues().rate) {
      if (getValues()?.is_reversed) {
        setValue('rate', (1 + walletRatePercent / 100) * +baseRate)
      } else {
        setValue('rate', (1 - walletRatePercent / 100) * +baseRate)
      }
    } else {
      setValue('rate', +baseRate)
    }
  }, [baseRate, formValues?.is_reversed, formValues?.wallet_to_send_id])

  useEffect(() => {
    fetchCurrentExchange()
  }, [currencyCodeFrom, currencyCodeTo])

  useEffect(() => {
    setValue('wallet_to_send_id', walletsToSent?.[0]?.id || '')
  }, [walletsToSent])

  const [walletsToReceive, setWalletsToReceive] = useState([])

  async function fetchWalletsToReceive() {
    if (currencyCodeTo) {
      await AxiosService.get(`/wallets/receive?currency_code=${currencyCodeTo}`).then((res) => {
        setWalletsToReceive(res.data)
      })
    }
    return []
  }

  useEffect(() => {
    fetchWalletsToReceive()
  }, [currencyCodeTo])

  useEffect(() => {
    setValue('wallet_to_receive_id', walletsToReceive?.[0]?.id || '')
  }, [walletsToReceive])

  async function fetchData() {
    await AxiosService.get(`/exchanges`).then((res) => {
      setCurrenciesList(Array.from(new Set(res?.data?.map((c) => (c.enabled === 1 ? c.to_currency.currency_code : false)))))
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setCurrencyCodeFrom(currenciesList?.[0])
    setValue('currency_code_from', currenciesList?.[0] || '')
    setCurrencyCodeTo(currenciesList?.[1])
    setValue('currency_code_to', currenciesList?.[1] || '')
  }, [currenciesList])

  const isInternalSend = getValues()?.wallet_to_send_id === INTERNAL_WALLET
  const isInternalReceive = getValues()?.wallet_to_receive_id === INTERNAL_WALLET

  const isDisabledButton = useMemo(
    () =>
      !`${getValues()?.user_id}`?.length ||
      (!`${getValues()?.wallet_to_receive_address}`?.length && !isInternalReceive) ||
      (!`${getValues()?.wallet_to_receive_receiver_name}`?.length && !isInternalReceive) ||
      !Number(getValues()?.give_amount) > 0 ||
      !Number(getValues()?.rate) > 0 ||
      !Number(getValues()?.receive_amount) > 0,
    [formValues]
  )

  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])

  const searchClients = async (searchString) => {
    if (searchString?.length > 0) {
      AxiosService.get(`/clients/search?search_string=${searchString}`).then((res) => {
        setOptions(res.data)
      })
    } else {
      setOptions([])
    }
  }

  const debouncedSearchClients = debounce(searchClients, 500)

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const walletsToReceiveType = useMemo(
    () => walletsToReceive.filter(({ id }) => getValues()?.wallet_to_receive_id === id)?.[0]?.type,
    [walletsToReceive, getValues().wallet_to_receive_id]
  )

  const preparedRate = () =>
    getValues()?.is_reversed ? formattedCurrenciesFixed(1 / +getValues()?.rate || 1, 6) : +getValues()?.rate

  const [openDialog, setOpenDialog] = React.useState(false)

  const handleClickOpen = () => {
    setOpenDialog(true)
  }
  const [dialogAlreadyConfirmed, setDialogConfirmed] = React.useState(false)

  const handleSave = async () => {
    let values = getValues()
    if (!openDialog) {
      if ((+getValues()?.give_amount - +getValues()?.markup) * preparedRate() >= 0.01) {
        if (!dialogAlreadyConfirmed) {
          handleClickOpen()
          return
        }
      }
    }
    if (getValues()?.is_reversed) {
      values.rate = formattedCurrenciesFixed(1 / +getValues()?.rate, 6)
    }
    if (!getValues()?.markup) {
      values.markup = 0
    }
    if (getValues()?.wallet_to_send_id === INTERNAL_WALLET) {
      values.wallet_to_send_id = null
    }
    if (getValues()?.device === '-') {
      values = omit(values, 'device')
    }
    if (getValues()?.wallet_to_receive_id === INTERNAL_WALLET) {
      values.wallet_to_receive_id = null
      values.wallet_to_receive_address = null
      values.wallet_to_receive_receiver_name = null
    }
    await AxiosService.post(`/order`, values, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res?.response?.status === 201 || res?.status === 201) {
          enqueueSnackbar('Заказ Создан', { variant: 'success' })
          navigate(`/orders/${res?.data?.id}`)
        } else if (res?.response?.status === 422 || res?.status === 422) {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        } else {
          enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 7000 })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleClose = () => {
    if (dialogAlreadyConfirmed) {
      handleSave()
      setOpenDialog(false)
    } else {
      setDialogConfirmed(true)
      setOpenDialog(false)
    }
  }

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={firstColumnWidth}>
                  {/* 1) Клиент: user_id - випадаючий список, автокомпліт /clients/search?search_string=te */}
                  Клиент:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Autocomplete
                    id="user_id"
                    style={{ width: '100%' }}
                    {...register('user_id')}
                    open={open}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={formatAutocompleteClientName}
                    options={options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Введите id/имя/wechat_id клиента"
                        onChange={(event) => {
                          debouncedSearchClients(event.target.value)
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {open ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    onChange={(event, value) => setValue('user_id', value?.id || '')}
                  />
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Направление заявки:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    labelId="request_direction"
                    id="request_direction"
                    key="request_direction"
                    defaultValue="inbound"
                    style={{ width: '100%' }}
                    {...register('request_direction', { value: 'inbound' })}
                  >
                    {requestDirectionOptions.map(({ value }) => (
                      <MenuItem value={value} key={value}>
                        {getRequestDirectionLabel(value)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 2) Отдал валюту: currency_code_from - випадаючий список /currencies */}
                <Grid item xs={firstColumnWidth}>
                  Отдал валюту:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    labelId="currency_code_from"
                    id="currency_code_from"
                    key="currency_code_from"
                    value={currencyCodeFrom || ''}
                    style={{ width: '100%' }}
                    {...register('currency_code_from', {
                      onChange: (e) => {
                        setCurrencyCodeFrom(e.target.value)
                        trigger()
                      },
                    })}
                  >
                    <MenuItem disabled value="">
                      <em>Отдал валюту</em>
                    </MenuItem>
                    {currenciesList.map((c) => (
                      <MenuItem value={c} key={`currency_code_from${c}`}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 3) Получил валюту: currency_code_to - аналогічно, але <>  currency_code_from */}
                <Grid item xs={firstColumnWidth}>
                  Получил валюту:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    labelId="currency_code_to"
                    id="currency_code_to"
                    key="currency_code_to"
                    value={currencyCodeTo || ''}
                    style={{ width: '100%' }}
                    {...register('currency_code_to', {
                      onChange: (e) => {
                        setCurrencyCodeTo(e.target.value)
                        trigger()
                      },
                    })}
                  >
                    <MenuItem disabled value="">
                      <em>Получил валюту</em>
                    </MenuItem>
                    {currenciesList.map((c) => (
                      <MenuItem value={c} key={`currency_code_to_${c}`}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 4) Отдал сумму: give_amount - float від 0.01 з шагом 0.01 */}
                <Grid item xs={firstColumnWidth}>
                  Отдал сумму:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{ padding: 0 }}
                          aria-label="Пересчитать"
                          onClick={() => {
                            setValue('give_amount', +getValues()?.receive_amount / preparedRate() + +getValues().markup || 0)
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    id="give_amount"
                    type="number"
                    style={{ width: '100%' }}
                    inputProps={{
                      step: '0.01',
                      min: '0.01',
                      max: 9_999_999.99,
                    }}
                    value={getValues().give_amount}
                    onKeyDown={(e) => {
                      if (ALPHABETS.includes(e.key) || (getValues().give_amount > 9_999_999.99 && /\d/.test(e.key))) {
                        e.preventDefault()
                      }
                    }}
                    placeholder="Введите сумму"
                    {...register('give_amount', { setValueAs: (v) => formattedCurrenciesFixed(v, 2) })}
                  />
                </Grid>
                {/* 5) Курс: rate - float від 0.0001 з шагом 0.0001 */}
                <Grid item xs={firstColumnWidth}>
                  Курс:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    endAdornment={
                      <InputAdornment style={{ margin: 0 }} position="end">
                        {getValues().is_reversed ? `1/${formattedCurrenciesFixed(1 / +getValues().rate, 4)}` : ''}
                      </InputAdornment>
                    }
                    id="rate"
                    type="number"
                    style={{ width: '100%' }}
                    inputProps={{
                      step: '0.0001',
                      min: '0.0001',
                    }}
                    value={getValues()?.rate}
                    onKeyDown={(e) => {
                      if (ALPHABETS.includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    placeholder="Введите курс"
                    {...register('rate', { setValueAs: (v) => formattedCurrenciesFixed(v, 6) })}
                  />
                  <Input
                    style={{ display: 'none' }}
                    id="is_reversed"
                    type="number"
                    value={getValues().is_reversed}
                    placeholder="is_reversed"
                    {...register('is_reversed')}
                  />
                </Grid>
                <Grid item xs={firstColumnWidth}>
                  Устройство:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    labelId="device"
                    id="device"
                    key="device"
                    defaultValue="-"
                    style={{ width: '100%' }}
                    {...register('device')}
                  >
                    {deviceOptions.map(({ value, label }) => (
                      <MenuItem value={value} key={label}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 6) Комиссия: markup - float від 0 з шагом 0.01 */}
                <Grid item xs={firstColumnWidth}>
                  Комиссия:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    id="markup"
                    type="number"
                    style={{ width: '100%' }}
                    inputProps={{
                      step: '0.01',
                      min: '0.01',
                    }}
                    value={getValues().markup}
                    onKeyDown={(e) => {
                      if (ALPHABETS.includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    placeholder="Введите комиссию"
                    {...register('markup', { setValueAs: (v) => formattedCurrenciesFixed(v, 2) })}
                  />
                </Grid>
                {/* 7) Получил сумму: receive_amount - float від 0.01 з шагом 0.01 */}
                <Grid item xs={firstColumnWidth}>
                  Получил сумму:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Input
                    endAdornment={
                      <>
                        <InputAdornment position="end">
                          <IconButton
                            style={{ padding: 0 }}
                            aria-label="Пересчитать"
                            onClick={() => {
                              setValue(
                                'receive_amount',
                                (+getValues()?.give_amount - +getValues()?.markup) * preparedRate() || 0
                              )
                            }}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </InputAdornment>
                        {getValues()?.give_amount && getValues()?.receive_amount ? (
                          <InputAdornment position="end">
                            <IconButton style={{ padding: 4 }}>
                              <CopyToClipboard
                                text={`${getValues()?.give_amount} ${currencyCodeFrom}➡️${
                                  getValues().receive_amount
                                } ${currencyCodeTo}`}
                                className="copy-transaction"
                              >
                                <FileCopyIcon fontSize="small" />
                              </CopyToClipboard>
                            </IconButton>
                          </InputAdornment>
                        ) : null}
                      </>
                    }
                    id="receive_amount"
                    type="number"
                    style={{ width: '100%' }}
                    inputProps={{
                      step: '0.01',
                      min: '0.01',
                      max: 9_999_999.99,
                    }}
                    value={getValues().receive_amount}
                    onKeyDown={(e) => {
                      if (ALPHABETS.includes(e.key) || (getValues().receive_amount > 9_999_999.99 && /\d/.test(e.key))) {
                        e.preventDefault()
                      }
                    }}
                    placeholder="Введите сумму"
                    {...register('receive_amount', { setValueAs: (v) => formattedCurrenciesFixed(v, 2) })}
                  />
                </Grid>
                {/* 8) Кошелек, на который перевел: wallet_to_send_id -
                список /wallets/send?currency_code=UAH, по валюте currency_code_from
                Випадаючий список, де показується name, type, а відправляється id */}
                <Grid item xs={firstColumnWidth}>
                  Кошелек, на который перевел:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    id="wallet_to_send_id"
                    style={{ width: '100%' }}
                    value={getValues().wallet_to_send_id || walletsToSent?.[0]?.id || ''}
                    placeholder="Выберите Кошелек"
                    {...register('wallet_to_send_id', {
                      required: 'Пожалуйста выберите Кошелек',
                      onChange: () => trigger(),
                    })}
                  >
                    <MenuItem disabled value="">
                      <em>Выберите Кошелек...</em>
                    </MenuItem>
                    {!isInternalReceive && getValues()?.currency_code_from === CURRENCIES.USDT && (
                      <MenuItem value={INTERNAL_WALLET}>Списать с 💰Кошелька</MenuItem>
                    )}
                    {walletsToSent?.map((walletToSend) => (
                      <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                        {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 9) Реквизит, куда надо зачислить: wallet_to_receive_id -
                список /wallets/receive?currency_code=USDT
                по валюте currency_code_to Випадаючий список, де показується name, type, а відправляється id */}
                <Grid item xs={firstColumnWidth}>
                  Тип кошелька, куда надо зачислить:
                </Grid>
                <Grid item xs={secondColumnWidth}>
                  <Select
                    id="wallet_to_receive_id"
                    style={{ width: '100%' }}
                    value={getValues().wallet_to_receive_id || walletsToReceive?.[0]?.id || ''}
                    placeholder="Выберите Кошелек"
                    {...register('wallet_to_receive_id', {
                      required: 'Пожалуйста выберите Кошелек',
                      onChange: () => trigger(),
                    })}
                  >
                    <MenuItem disabled value="">
                      <em>Выберите Кошелек...</em>
                    </MenuItem>
                    {!isInternalSend && getValues()?.currency_code_to === CURRENCIES.USDT && (
                      <MenuItem value={INTERNAL_WALLET}>Зачислить на 💰Кошелек</MenuItem>
                    )}
                    {walletsToReceive?.map((walletToReceive) => (
                      <MenuItem key={`walletsTo_${walletToReceive.id}`} value={walletToReceive.id}>
                        {`${walletToReceive.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* 10) реквізити юзера, поля залежать від wallet_to_receive.type:

                - crypto:
                    Номер криптокошелька: wallet_to_receive_address, усі символи також відправити
                    wallet_to_receive_receiver_name з тим же значенням що і wallet_to_receive_address

                - alipay:
                    Аккаунт alipay: wallet_to_receive_address (текст)
                    Имя получателя: wallet_to_receive_receiver_name (текст)

                - bankcard або bankcard_chinese:
                    Номер картки: wallet_to_receive_address (від 16 до 19 цифр)
                    Имя получателя: wallet_to_receive_receiver_name (текст) */}
                {/* eslint-disable-next-line no-nested-ternary */}
                {walletsToReceiveType === 'crypto' ? (
                  <>
                    <Grid item xs={firstColumnWidth}>
                      Реквизиты юзера:
                    </Grid>
                    <Grid item xs={secondColumnWidth}>
                      <Input
                        id="crypto_wallet_to_receive_address"
                        type="text"
                        style={{ width: '100%' }}
                        value={getValues().wallet_to_receive_address || ''}
                        placeholder="Введите номер криптокошелька"
                        {...register('wallet_to_receive_address', {
                          onChange: (event) => {
                            setValue('wallet_to_receive_receiver_name', event.target.value || '')
                            return trigger()
                          },
                        })}
                      />
                      <Input
                        id="crypto_wallet_to_receive_receiver_name"
                        type="text"
                        style={{ display: 'none' }}
                        value={getValues().wallet_to_receive_address || ''}
                        placeholder="Введите номер криптокошелька"
                        {...register('wallet_to_receive_receiver_name')}
                      />
                    </Grid>
                  </>
                ) : walletsToReceiveType === 'alipay' ? (
                  <>
                    <Grid item xs={firstColumnWidth}>
                      Реквизиты юзера:
                    </Grid>
                    <Grid item xs={secondColumnWidth} />
                    <Grid item xs={firstColumnWidth}>
                      Аккаунт alipay:
                    </Grid>
                    <Grid item xs={secondColumnWidth}>
                      <Input
                        id="alipay_wallet_to_receive_address"
                        type="text"
                        style={{ width: '100%' }}
                        value={getValues().wallet_to_receive_address || ''}
                        placeholder="Введите аккаунт alipay"
                        {...register('wallet_to_receive_address', {
                          onChange: () => trigger(),
                        })}
                      />
                    </Grid>
                    <Grid item xs={firstColumnWidth}>
                      Имя получателя:
                    </Grid>
                    <Grid item xs={secondColumnWidth}>
                      <Input
                        id="alipay_wallet_to_receive_receiver_name"
                        type="text"
                        style={{ width: '100%' }}
                        value={getValues().wallet_to_receive_receiver_name || ''}
                        placeholder="Введите имя получателя"
                        {...register('wallet_to_receive_receiver_name', {
                          onChange: () => trigger(),
                        })}
                      />
                    </Grid>
                  </>
                ) : (
                  !isInternalReceive && (
                    <>
                      <Grid item xs={firstColumnWidth}>
                        Реквизиты юзера:
                      </Grid>

                      <Grid item xs={secondColumnWidth} />
                      <Grid item xs={firstColumnWidth}>
                        Номер карты:
                      </Grid>
                      <Grid item xs={secondColumnWidth}>
                        <Input
                          id="bank_wallet_to_receive_address"
                          type="number"
                          style={{ width: '100%' }}
                          inputProps={{
                            step: '1',
                            min: '0',
                            minLength: '16',
                            maxLength: '19',
                          }}
                          onKeyDown={(e) => {
                            if (ALPHABETS.includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                          value={getValues().wallet_to_receive_address || ''}
                          placeholder="Введите номер карты"
                          {...register('wallet_to_receive_address', {
                            onChange: () => trigger(),
                          })}
                        />
                      </Grid>
                      <Grid item xs={firstColumnWidth}>
                        Имя получателя:
                      </Grid>
                      <Grid item xs={secondColumnWidth}>
                        <Input
                          id="bank_wallet_to_receive_receiver_name"
                          type="text"
                          style={{ width: '100%' }}
                          value={getValues().wallet_to_receive_receiver_name || ''}
                          placeholder="Введите имя получателя"
                          {...register('wallet_to_receive_receiver_name', {
                            onChange: () => trigger(),
                          })}
                        />
                      </Grid>
                    </>
                  )
                )}
                <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave} disabled={isDisabledButton}>
                    Создать ручной заказ
                  </Button>
                </Grid>
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Похоже, “сумма, которую отдал”, и “сумма, которую получил” не соответствуют. Создать такой заказ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Отменить
                    </Button>
                    <Button onClick={handleSave} color="primary" autoFocus>
                      Подтвердить
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
