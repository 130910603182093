import Grid from '@material-ui/core/Grid'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import React from 'react'

export default function PasswordChange({
  className,
  control,
  isPasswordTextShown,
  setIsPasswordTextShown,
  classes,
  savePassword,
  isDisabledButton,
}) {
  return (
    <Grid className={className} role="form" container justify="center">
      <Grid container direction="row" justifyContent="center" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid style={{ display: 'flex' }} alignItems="center" justifyContent="flex-start" item md={2} xl={1} xs={3}>
          Текущий пароль:
        </Grid>
        <Grid item md={4} xs={7}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                style={{ width: '100%' }}
                id="current-password"
                type={isPasswordTextShown ? 'text' : 'password'}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder="Введите ваш текущий пароль"
                autoComplete="current-password"
              />
            )}
            name="current_password"
          />
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid style={{ display: 'flex' }} alignItems="center" justifyContent="flex-start" item md={2} xl={1} xs={3}>
          Новый пароль:
        </Grid>
        <Grid item md={4} xs={7}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                style={{ width: '100%' }}
                id="password"
                type={isPasswordTextShown ? 'text' : 'password'}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder="Введите новый пароль"
                autoComplete="new-password"
              />
            )}
            name="new_password"
          />
        </Grid>
      </Grid>

      <Grid container direction="row" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid style={{ display: 'flex' }} alignItems="center" justifyContent="flex-start" item md={2} xl={1} xs={3}>
          Подтверждение пароля:
        </Grid>
        <Grid item md={4} xs={7}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                style={{ width: '100%' }}
                id="password_confirmation"
                type={isPasswordTextShown ? 'text' : 'password'}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder="Введите подтверждение пароля"
                autoComplete="new-password"
              />
            )}
            name="new_password_confirmation"
          />
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid style={{ display: 'flex' }} alignItems="center" justifyContent="flex-start" item md={2} xl={1} xs={3}>
          Показать пароли
        </Grid>
        <Grid item md={4} xs={7}>
          <Checkbox
            style={{ paddingLeft: 0 }}
            color="primary"
            checked={isPasswordTextShown}
            onChange={(e) => setIsPasswordTextShown(e.target.checked)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={isDisabledButton}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => savePassword()}
        >
          Сохранить
        </Button>
      </Grid>
    </Grid>
  )
}
