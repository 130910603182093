import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { Button, Input } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { formattedCurrenciesFixed } from '../../utils/customHooks'
import AxiosService from '../../utils/axiosService'

export default function ExchangeVariablesTable({ updateExchanges }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      title: '',
      field: 'name',
      editable: 'never',
    },
    {
      title: 'Процент',
      field: 'value',
      type: 'numeric',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      render: ({ value }) => <>{value}%</>,
      editComponent: (props) => {
        const { value } = props
        const [curValue, setCurValue] = useState(value)
        return (
          <>
            <Input
              type="number"
              name="variable"
              style={{ color: 'black' }}
              required
              inputProps={{
                step: 0.01,
                min: 0,
                max: 100,
              }}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(e.target.value)
              }}
            />
          </>
        )
      },
    },
    { hidden: true, field: 'key' },
  ]

  const tableRef = React.createRef()
  return (
    <>
      <Grid item xs={12} sm={5} md={5} lg={3}>
        <br />
        <MaterialTable
          title="Наценки и скидки"
          columns={columns}
          tableRef={tableRef}
          options={{
            search: false,
            sorting: true,
            paging: false,
            actionsColumnIndex: -1,

            rowStyle: (rowData) => ({
              backgroundColor: rowData.enabled === 0 ? '#EEE' : '#FFF',
            }),
          }}
          data={() =>
            new Promise((resolve) => {
              const url = '/app-variable'
              AxiosService.get(url).then((result) => {
                resolve({
                  // eslint-disable-next-line max-len,camelcase
                  data: result?.data?.map(({ name, value, key }) => ({
                    name,
                    value,
                    key,
                  })),
                  page: result.data.current_page - 1,
                  totalCount: result.data.total,
                })
              })
            })
          }
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (!newData.value || newData.value >= 100) {
                    enqueueSnackbar('Укажите корректное значение', { variant: 'error', autoHideDuration: 7000 })
                    reject()
                    return
                  }
                  resolve()
                  if (oldData) {
                    AxiosService.put(`app-variable/${newData.key}/edit`, { value: newData.value }, {})
                      .then(() => {
                        setIsModalOpen(true)
                        // eslint-disable-next-line no-unused-expressions
                        tableRef.current && tableRef.current.onQueryChange()
                      })
                      .catch((err) => {
                        console.log('err', err)
                        if (err.response) {
                          console.log(err.response.status)
                        }
                      })
                    // eslint-disable-next-line no-unused-expressions
                    tableRef.current && tableRef.current.onQueryChange()
                  }
                }, 600)
              }),
          }}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
        />
      </Grid>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Вы действительно хотите пересчитать все курсы?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false)
            }}
            color="primary"
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              AxiosService.post('/exchanges/recalculate', {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
                .then(() => {
                  // eslint-disable-next-line no-unused-expressions
                  updateExchanges()
                  setIsModalOpen(false)
                })
                .catch((err) => {
                  console.log('err', err)
                  if (err.response) {
                    console.log(err.response.status)
                  }
                })
            }}
            color="primary"
            autoFocus
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
