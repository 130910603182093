import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'
import { Button, Grid, Input, InputAdornment, makeStyles, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { format } from 'date-fns'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ImageUploader from 'react-images-upload'
import { omit } from 'lodash'
import { ALPHABETS, formattedCurrenciesFixed, formatWalletToSendRequisitesSecret } from '../../utils/customHooks'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import { RenderBadge } from '../expenses/RenderBadge'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
}))

export default function ExpenseCreation() {
  const { reset, control, watch, trigger, getValues, register, setValue } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const [expenseCategories, setExpenseCategories] = useState(null)
  const [currencies, setCurrencies] = useState([])
  const [walletsToSent, setWalletsToSent] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isDisabledButton = useMemo(
    () =>
      !watch().name ||
      !watch().datetime ||
      !watch().amount ||
      !watch().usdt_rate ||
      !watch().expense_category_id ||
      !watch().wallet_to_send_id,
    [watch()]
  )

  const navigate = useNavigate()

  useEffect(() => {
    const date = new Date()
    reset({
      datetime: format(date, 'yyyy-MM-dd'),
      name: '',
      amount: null,
      usdt_rate: null,
      expense_category_id: null,
      wallet_to_send_id: null,
      comment: '',
    })
  }, [reset])

  const onDrop = (picture) => {
    setValue('files', picture)
  }

  async function fetchWalletsToSent() {
    if (watch().currency) {
      await AxiosService.get(`/wallets/send?currency_code=${watch().currency}&without_disabled=1`).then((res) => {
        setWalletsToSent(res.data)
        setValue('wallet_to_send_id', res.data[0]?.id)
      })
    }
    return []
  }

  useEffect(() => {
    fetchWalletsToSent()
  }, [watch().currency])

  useEffect(() => {
    const fetchCurrencies = async () => {
      const cur = {}
      await AxiosService.get(`/currencies`).then((res) => {
        res.data.map(({ id, currency_code: currencyCode }) => (cur[id] = currencyCode || '-'))
        setCurrencies(cur)
      })
    }
    fetchCurrencies()
  }, [])

  const classes = useStyles()

  const handleSave = () => {
    const data = watch()
    const pictures = data.files
    const formData = new FormData()
    for (const i in omit(data, 'files')) {
      // eslint-disable-next-line no-continue
      if (!data[i]) continue
      if (i === 'usdt_rate') {
        formData.append(i, formattedCurrenciesFixed(1 / +data[i], 6))
      } else formData.append(i, data[i])
    }

    if (pictures.length) {
      for (let i = 0; i < pictures.length; i += 1) {
        formData.append('files[]', pictures[pictures.length - 1])
      }
    }

    AxiosService.post(`/expenses`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        if (res?.statusText === 'Created') {
          enqueueSnackbar('Расход был создан', { variant: 'success', autoHideDuration: 2000 })
          navigate('/expenses')
        } else {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    const getExpensesCategories = () => {
      AxiosService.get('/expense-categories').then((result) => {
        let tableData = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < result?.data?.length; i++) {
          tableData.push(result.data[i])
          if (result.data[i].children) {
            tableData = [...tableData, ...result.data[i].children]
          }
        }
        setExpenseCategories(tableData)
      })
    }
    if (!expenseCategories) getExpensesCategories()
  }, [expenseCategories])

  async function fetchCurrentExchange() {
    if (watch().currency) {
      await AxiosService.get(`/exchanges?from_currency=${watch().currency}&to_currency=🇺🇸USDT`).then((res) => {
        const [exchange = {}] = res.data
        let rateValue = 1.0
        if (typeof exchange.clear_rate !== 'undefined') {
          rateValue = exchange?.is_reversed
            ? `${formattedCurrenciesFixed(1 / +exchange?.clear_rate || 1, 6)}`
            : `${exchange?.clear_rate}`
        }

        setValue('usdt_rate', rateValue)
        setValue('is_reversed', exchange.is_reversed || 0)
      })
    }
    return []
  }

  useEffect(() => {
    fetchCurrentExchange()
  }, [watch().currency])

  return (
    <div>
      <ButtonAppBar />
      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  Дата:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          format="yyyy/MM/dd"
                          label="Дата"
                          inputVariant="outlined"
                          disableFuture
                          autoOk
                          value={value}
                          onChange={(newValue) => onChange(format(newValue, 'yyyy-MM-dd'))}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="datetime"
                  />
                </Grid>
                <Grid item xs={3}>
                  Наименование расхода:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="name"
                        type="text"
                        style={{ width: '100%' }}
                        inputProps={{
                          step: '0.01',
                          min: '0.01',
                        }}
                        value={value || ''}
                        onChange={(fieldValue) => onChange(fieldValue)}
                        placeholder="Введите название расхода"
                      />
                    )}
                    name="name"
                  />
                </Grid>

                <Grid item xs={3}>
                  Валюта:
                </Grid>
                <Grid item xs={9}>
                  {currencies ? (
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          id="currencies"
                          style={{ width: '100%' }}
                          value={value || ''}
                          placeholder="Выберите Валюту"
                          onChange={(colorValue) => onChange(colorValue)}
                        >
                          {Object.keys(currencies)
                            .reverse()
                            .map((key) => (
                              <MenuItem key={currencies[key]} value={currencies[key]}>
                                <span>{currencies[key]}</span>
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      name="currency"
                    />
                  ) : null}
                </Grid>

                <Grid item xs={3}>
                  Сумма расхода:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="name"
                        type="number"
                        style={{ width: '100%' }}
                        inputProps={{
                          step: '0.01',
                          min: '0.01',
                        }}
                        value={formattedCurrenciesFixed(value, 2) || ''}
                        onChange={(fieldValue) => onChange(formattedCurrenciesFixed(fieldValue, 2))}
                        placeholder="Сумма расхода"
                      />
                    )}
                    name="amount"
                  />
                </Grid>

                <Grid item xs={3}>
                  Курс USDT:
                </Grid>
                <Grid item xs={9}>
                  <Input
                    endAdornment={
                      <InputAdornment style={{ margin: 0 }} position="end">
                        {getValues().is_reversed ? `1/${formattedCurrenciesFixed(1 / +getValues().usdt_rate, 6)}` : ''}
                      </InputAdornment>
                    }
                    id="clear_rate"
                    type="number"
                    style={{ width: '100%' }}
                    inputProps={{
                      step: '0.0001',
                      min: '0.0001',
                    }}
                    value={getValues()?.usdt_rate}
                    onKeyDown={(e) => {
                      if (ALPHABETS.includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    placeholder="Введите курс"
                    {...register('usdt_rate', { setValueAs: (v) => formattedCurrenciesFixed(v, 6) })}
                  />
                  <Input
                    style={{ display: 'none' }}
                    id="is_reversed"
                    type="number"
                    value={getValues().is_reversed}
                    placeholder="is_reversed"
                    {...register('is_reversed')}
                  />
                </Grid>

                <Grid item xs={3}>
                  Категория:
                </Grid>
                <Grid item xs={9}>
                  {currencies ? (
                    <Controller
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          options={expenseCategories}
                          getOptionLabel={(option) => option.name}
                          renderOption={(category) => (
                            <span style={category.parent_id ? { paddingLeft: 12 } : {}}>{RenderBadge(category)}</span>
                          )}
                          id="disable-close-on-select"
                          onChange={(event, category) => {
                            if (category?.id) {
                              onChange(category.id)
                            }
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField placeholder="Категория" {...params} />
                            </>
                          )}
                        />
                      )}
                      name="expense_category_id"
                    />
                  ) : null}
                </Grid>

                <Grid item xs={3}>
                  Комментарий:
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="comment"
                        type="text"
                        style={{ width: '100%' }}
                        inputProps={{
                          step: '0.01',
                          min: '0.01',
                        }}
                        value={value || ''}
                        onChange={(fieldValue) => onChange(fieldValue)}
                        placeholder="Введите комментарий"
                      />
                    )}
                    name="comment"
                  />
                </Grid>

                <Grid item xs={3}>
                  Кошелек списания:
                </Grid>
                <Grid item xs={9}>
                  <Select
                    id="wallet_to_send_id"
                    style={{ width: '100%' }}
                    value={getValues().wallet_to_send_id || walletsToSent?.[0]?.id || ''}
                    placeholder="Выберите Кошелек"
                    {...register('wallet_to_send_id', {
                      required: 'Пожалуйста выберите Кошелек',
                      onChange: () => trigger(),
                    })}
                  >
                    <MenuItem disabled value="">
                      <em>Выберите Кошелек...</em>
                    </MenuItem>
                    {walletsToSent?.map((walletToSend) => (
                      <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                        {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Dialog
                  open={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Прикрепите фотографию чека</DialogTitle>
                  <DialogContent>
                    <ImageUploader
                      withIcon
                      onChange={onDrop}
                      buttonText="Выбрать изображения"
                      style={{ all: 'none' }}
                      label="Максимальный размер файлов: 5 мегабайт"
                      withPreview
                      imgExtension={['.jpg', '.jpeg', '.bmp', '.png']}
                      maxFileSize={5242880}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClose={() => setIsDialogOpen(false)} color="primary">
                      Отмена
                    </Button>
                    <Button disabled={!watch().files} onClick={handleSave} color="primary" autoFocus>
                      Подтвердить
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid item xs={12} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsDialogOpen(true)}
                    disabled={isDisabledButton}
                  >
                    Создать расход
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
