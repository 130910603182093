import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { getPhotoUrl } from '../../utils/photoUrl'
import DropGroup from '../taobao-orders/DropGroup'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  expansion: {
    color: theme.palette.text.secondary,
  },
}))

export default function ExpandedPhotos(props) {
  const { images, text, isLink, isGroup, group, style } = props
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  if (images) {
    return (
      <Grid style={style} item xs={12}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isOpen}
          autoHideDuration={3000}
          onClose={() => setIsOpen(false)}
          message="Данные скопированы"
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <Accordion className={classes.expansion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {isLink ? (
              /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(text) ? (
                <span>
                  <Link href={text} target="_blank" rel="noreferrer" style={{ wordBreak: 'break-all' }}>
                    {text}{' '}
                  </Link>
                  <CopyToClipboard text={text}>
                    <FileCopyIcon
                      className="copy-clipboard"
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsOpen(true)
                      }}
                    />
                  </CopyToClipboard>
                </span>
              ) : (
                <span>
                  <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                    {text}{' '}
                  </Typography>
                  <CopyToClipboard text={text}>
                    <FileCopyIcon
                      className="copy-clipboard"
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsOpen(true)
                      }}
                    />
                  </CopyToClipboard>
                </span>
              )
            ) : (
              <span>{text}</span>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            {isGroup
              ? Object.values(group).map((item) => (
                  <DropGroup key={`group-element-${item?.id}`} group={item} text={text} />
                ))
              : images.map((photoUrl) => (
                  <Card key={photoUrl} className={classes.root}>
                    <CardActionArea>
                      <CardMedia component="img" alt="" image={getPhotoUrl(photoUrl)} />
                    </CardActionArea>
                  </Card>
                ))}
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
  return false
}
