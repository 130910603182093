import React, { useEffect, useMemo, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ImageUploader from 'react-images-upload'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import throttle from 'lodash/throttle'
import { useSnackbar } from 'notistack'
import useSWR from 'swr'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import {
  formattedMoney,
  getLinkName,
  getStatusLabel,
  STATUS,
  typesOfClient,
  formattedCurrenciesFixed,
  useAuth,
  formatWalletToSendRequisitesSecret,
  CURRENCIES,
} from '../../utils/customHooks'
import ExpandedPhotos from '../order/ExpandedPhotos'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'
import { formatDateWithTime } from '../../utils/date'
import { formatVerificationType } from '../../utils/text'
import { getTaobaoOrderData } from '../../utils/getTaobaoOrderData'
import { getTaobaoTypeBadge } from '../../utils/getTaobaoTypeBadge'
import DNDScreens from './DNDScreens'
import NestedPhotos from './NestedPhotos'
import fetcher from '../../utils/fetcher'
import ErrorComponent from '../common/ErrorComponent'
import Loading from '../common/Loading'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  preColor: {
    color: theme.palette.text.secondary,
    fontFamily: 'Arial',
    fontSize: '10pt',
  },
}))

export default function TaobaoOrder() {
  const classes = useStyles()
  const { id } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { USDT, CNY } = CURRENCIES
  const firstColumnWidth = 4
  const secondColumnWidth = 8
  const dividerWidth = 12

  const [status, setStatus] = useState('')
  const [comment, setComment] = useState('')
  const [invoice, setInvoice] = useState(undefined)
  const [amount, setAmount] = useState(undefined)
  const [deliveryPrice, setDeliveryPrice] = useState(0)
  const [walletToSendId, setWalletToSendId] = useState(0)
  const [amountPaid, setAmountPaid] = useState(undefined)
  const [walletsToSend, setWalletsToSend] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [groups, setGroups] = useState(undefined)
  const [groupForCompletion, setGroupForCompletion] = useState(undefined)

  const [card, setCard] = useState(undefined)
  const [receiverName, setReceiverName] = useState(undefined)

  const [realGiveAmountCurrencyCode, setRealGiveAmountCurrencyCode] = useState(USDT)
  const [realGiveAmount, setRealGiveAmount] = useState(undefined)
  const [realWalletToSendId, setRealWalletToSendId] = useState(0)

  const [allRealWalletToSend, setAllRealWalletToSend] = useState([])
  const [currencyCodesList, setCurrencyCodesList] = useState([USDT])

  const [pictures, setPictures] = useState([])

  const [openDialog, setOpenDialog] = useState(false)

  const errorComment = useMemo(() => comment.length > 200, [comment])

  const { data: orderData, mutate, isLoading: isLoadingOrderData, error: orderError } = useSWR(
    `/taobao-order/${id}`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateIfStale: false,
      onSuccess: (data) => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 14)
        setAmountPaid((Number(data?.receive_amount) + Number(data?.delivery_price ?? 0)).toFixed(2))
        setStatus(data.status)

        const group = {
          items: [],
          id: 1,
          groupId: 1,
          purchased_at: currentDate,
          basketIds: [],
          files: [],
        }
        data?.baskets?.forEach((basket) => {
          group.items = [...group.items, { files: basket.files, link: basket.link, id: basket.id, isDraggable: true }]
          group.basketIds = [...group.basketIds, basket.id]
        })
        setGroups([group])
      },
    }
  )

  const realWalletsToSendByCurrency = useMemo(
    () =>
      allRealWalletToSend.filter(
        ({ currency: { currency_code } = {} } = {}) => currency_code === realGiveAmountCurrencyCode
      ),
    [allRealWalletToSend, realGiveAmountCurrencyCode]
  )

  useEffect(() => {
    setRealWalletToSendId(realWalletsToSendByCurrency?.[0]?.id)
  }, [realWalletsToSendByCurrency])

  async function fetchWalletsToSent() {
    const { data: allWallets } = await AxiosService.get(`/wallets/send?without_disabled=1`)
    setAllRealWalletToSend(allWallets)
    const currenciesList = [...new Set(allWallets?.map(({ currency: { currency_code } = {} } = {}) => currency_code))]
    setCurrencyCodesList(currenciesList)
    setRealGiveAmountCurrencyCode(currenciesList.includes(USDT) ? USDT : currenciesList[0])

    const { data: walletsToSendData } = await AxiosService.get(`/wallets/send?currency_code=${CNY}&without_disabled=1`)
    setWalletsToSend(walletsToSendData)
    setWalletToSendId(walletsToSendData?.[0]?.id)
  }

  useEffect(() => {
    fetchWalletsToSent()
  }, [orderData?.currency_code_to])

  const updateGroups = (newGroups) => {
    setGroups(newGroups)
  }

  const moveImage = (fileId, sourceGroupId, targetGroupId) => {
    const sourceGroupIndex = groups.findIndex((group) => group.id === sourceGroupId)
    const targetGroupIndex = groups.findIndex((group) => group.id === targetGroupId)

    if (sourceGroupIndex !== -1 && targetGroupIndex !== -1) {
      const sourceGroup = groups[sourceGroupIndex]
      const targetGroup = groups[targetGroupIndex]

      const fileIndex = sourceGroup.items.findIndex((file) => file.id === fileId)
      if (fileIndex !== -1) {
        const movedFile = sourceGroup.items.splice(fileIndex, 1)[0]

        targetGroup.items.push(movedFile)

        updateGroups([...groups])
      }
      const movedFile = sourceGroup.basketIds.splice(fileIndex, 1)[0]

      targetGroup.basketIds.push(movedFile)
      updateGroups([...groups])
    }
  }

  const addImage = (image, groupId) => {
    setGroups(
      groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            files: [...group.files, [image]],
          }
        }
        return group
      })
    )
  }

  const updatePurchasedAt = (groupId, newPurchasedAt) => {
    const groupIndex = groups.findIndex((group) => group.id === groupId)

    if (groupIndex !== -1) {
      const updatedGroup = { ...groups[groupIndex] }

      updatedGroup.purchased_at = newPurchasedAt

      const updatedGroups = [...groups]
      updatedGroups[groupIndex] = updatedGroup
      updateGroups(updatedGroups)
    }
  }

  const onDrop = (picture) => {
    setPictures(picture)
  }

  function formatWalletToSendRequisites() {
    if (orderData.wallet_to_send_address === orderData.wallet_to_send_receiver_name) {
      return orderData.wallet_to_send_address
    }

    return `${orderData.wallet_to_send_address} ${orderData.wallet_to_send_receiver_name || ''} `
  }

  const handleChangeStatus = (event) => {
    if ([STATUS.PAYMENT_PURCHASED].includes(event.target.value)) fetchWalletsToSent()
    setStatus(event.target.value)
  }

  function getStatusesChoice() {
    switch (orderData?.status) {
      case STATUS.REQUISITES_SENT:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.REQUISITES_SENT}>{getStatusLabel(STATUS.REQUISITES_SENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_SENT:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_SENT}>{getStatusLabel(STATUS.PAYMENT_SENT)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_ERROR}>{getStatusLabel(STATUS.PAYMENT_ERROR)}</MenuItem>
          </Select>
        )
      case STATUS.WAITING_MANAGER:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.WAITING_MANAGER}>{getStatusLabel(STATUS.WAITING_MANAGER)}</MenuItem>
            <MenuItem value={STATUS.WAITING_CLIENT}>{getStatusLabel(STATUS.WAITING_CLIENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.CLIENT_IS_WAITING:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.CLIENT_IS_WAITING}>{getStatusLabel(STATUS.CLIENT_IS_WAITING)}</MenuItem>
            <MenuItem value={STATUS.REQUISITES_SENT}>{getStatusLabel(STATUS.REQUISITES_SENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_RECEIVED:
        return orderData?.type === 'rent' ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_COMPLETED}>{getStatusLabel(STATUS.COMPLETED)}</MenuItem>
          </Select>
        ) : (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_PURCHASED}>{getStatusLabel(STATUS.PAYMENT_PURCHASED)}</MenuItem>
          </Select>
        )
      case STATUS.COMPLETED:
        return getStatusLabel(STATUS.COMPLETED)
      case STATUS.WAITING_CLIENT:
        return getStatusLabel(STATUS.WAITING_CLIENT)
      case STATUS.CANCELLED:
        return getStatusLabel(STATUS.CANCELLED)
      case STATUS.CLIENT_CANCELLED:
        return getStatusLabel(STATUS.CLIENT_CANCELLED)
      case STATUS.PAYMENT_ERROR:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_ERROR}>{getStatusLabel(STATUS.PAYMENT_ERROR)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
            <MenuItem value={STATUS.REQUISITES_UPDATED}>{getStatusLabel(STATUS.REQUISITES_UPDATED)}</MenuItem>
          </Select>
        )
      case STATUS.PARTIALLY_COMPLETED:
        return getStatusLabel(STATUS.PARTIALLY_COMPLETED)
      case STATUS.PAYMENT_CHECK:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_CHECK}>{getStatusLabel(STATUS.PAYMENT_CHECK)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_CHECKED}>{getStatusLabel(STATUS.PAYMENT_CHECKED)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PURCHASED:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value="purchased">{getStatusLabel(STATUS.PAYMENT_PURCHASED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_CHECKED:
        return getStatusLabel(STATUS.PAYMENT_CHECKED)
      default:
        return 'Ошибка сервера'
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setComment('')
    setPictures([])
  }

  async function saveStatus() {
    setIsLoading(true)
    if (invoice && groupForCompletion) {
      await AxiosService.put(`/taobao-order/${orderData.id}/order_group/${groupForCompletion}`, { invoice })
        .then(() => {
          setIsLoading(false)
          handleCloseDialog()
          mutate()
        })
        .catch((err) => {
          setIsLoading(false)
          setInvoice(undefined)
          console.error(err)
        })
      return
    }
    if (
      ([
        STATUS.PAYMENT_COMPLETED,
        STATUS.PAYMENT_ERROR,
        STATUS.PAYMENT_SENT,
        STATUS.REQUISITES_UPDATED,
        STATUS.PAYMENT_CHECKED,
        STATUS.PAYMENT_PURCHASED,
        STATUS.CANCELLED,
      ].includes(status) &&
        !groupForCompletion) ||
      ((status === STATUS.PAYMENT_RECEIVED || status === STATUS.REQUISITES_SENT) && orderData?.is_p2p)
    ) {
      const formData = new FormData()
      const groupsArr = []
      if (comment !== '') {
        let fieldName

        switch (status) {
          case STATUS.CANCELLED:
            fieldName = 'cancel_comment'
            break
          case STATUS.REQUISITES_UPDATED:
            fieldName = 'new_requisites'
            break
          case STATUS.REQUISITES_SENT:
            fieldName = 'wallet_to_send_comment'
            break
          default:
            fieldName = 'comment'
        }

        formData.append(fieldName, comment)
      }

      if (walletToSendId !== 0) {
        formData.append('wallet_to_send_id', `${walletToSendId}`)
      }

      if (amountPaid) {
        formData.append('amount_we_paid', amountPaid)
      }

      if (pictures.length) {
        for (let i = 0; i < pictures.length; i += 1) {
          formData.append('files[]', pictures[i])
        }
      }

      if (realGiveAmount) {
        formData.append('real_give_amount', realGiveAmount)
      }
      if (realWalletToSendId) {
        formData.append('real_wallet_to_send_id', realWalletToSendId)
      }

      if (groups && status === STATUS.PAYMENT_PURCHASED) {
        // eslint-disable-next-line no-restricted-syntax
        for (const group of groups) {
          if (group.items && group.items.length > 0) {
            groupsArr.push({
              purchased_at: group.purchased_at ? group.purchased_at.toISOString().split('T')[0] : null,
              basket_ids: group.basketIds,
              files: group.files,
              group_number: group.id,
            })
          }
          groupsArr.forEach((groupData, index) => {
            formData.append(`groups[${index}][purchased_at]`, groupData.purchased_at ? groupData.purchased_at : '')

            if (groupData.basket_ids) {
              groupData.basket_ids.forEach((basketId, i) => {
                formData.append(`groups[${index}][basket_ids][${i}]`, basketId)
              })
            }

            if (groupData.files) {
              groupData.files.forEach((file, i) => {
                formData.append(`groups[${index}][files][${i}]`, file[0])
              })
            }

            formData.append(`groups[${index}][group_number]`, groupData.group_number)
          })
        }
      }
      if (status === STATUS.PAYMENT_COMPLETED) {
        formData.append('invoice', invoice)
      }
      if (status === STATUS.PAYMENT_CHECKED) {
        formData.append('amount', amount)
      }
      if (status === STATUS.PAYMENT_CHECKED) {
        formData.append('delivery_price', deliveryPrice)
      }
      await AxiosService.post(
        `/taobao-order/${orderData.id}/${status === STATUS.REQUISITES_SENT ? 'requisites_sent' : status}`,
        status === STATUS.REQUISITES_SENT
          ? {
              wallet_to_send_address: card,
              wallet_to_send_receiver_name: receiverName,
              wallet_to_send_comment: comment,
            }
          : formData,
        {
          headers: {
            'Content-Type': status === STATUS.REQUISITES_SENT ? 'application/json' : 'multipart/form-data',
          },
        }
      )
        .then((res) => {
          if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
            setIsLoading(false)
            handleCloseDialog()
            mutate()
          } else {
            enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
            setIsLoading(false)
            handleCloseDialog()
            mutate()
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
        })
    } else if (status === STATUS.REQUISITES_SENT && !groupForCompletion) {
      const formData = new FormData()
      if (pictures.length) {
        for (let i = 0; i < pictures.length; i += 1) {
          formData.append('files[]', pictures[i])
        }
      }
    } else {
      await AxiosService.post(
        `/taobao-order/${orderData.id}/${status === STATUS.REQUISITES_SENT ? 'requisites_sent' : status}`
      ).then((res) => {
        if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
          setIsLoading(false)
          mutate()
        } else {
          enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
          setIsLoading(false)
          mutate()
        }
      })
    }
  }

  const handleClickCopy = () => {
    enqueueSnackbar('Данные скопированы', { variant: 'info' })
  }

  const isDialogConfirmDisabled = () => {
    if (errorComment) {
      return !!errorComment
    }
    if (status === STATUS.PAYMENT_PURCHASED) {
      return (
        !amountPaid ||
        !walletToSendId ||
        !Array.isArray(groups) ||
        groups.length === 0 ||
        ('baskets' in orderData ? groups.length > orderData?.baskets.length : true) ||
        groups.some((group) => group.basketIds.length === 0) ||
        groups.some((group) => group.files.length === 0)
      )
    }
    if (status === STATUS.REQUISITES_UPDATED) {
      return comment.length === 0
    }
    if (status === STATUS.PAYMENT_COMPLETED) {
      return !invoice
    }
    if (status === STATUS.PAYMENT_CHECKED) {
      return !amount || deliveryPrice.length === 0
    }
    if (status === STATUS.REQUISITES_SENT) {
      return !receiverName || !card
    }
    if (status === STATUS.PAYMENT_RECEIVED && orderData?.is_p2p) {
      return !realGiveAmount || realGiveAmount === 0 || +realGiveAmount === 0 || !realWalletToSendId || pictures.length === 0
    }
    return status !== STATUS.CANCELLED && pictures.length === 0
  }

  const shouldOpenDialog = [
    STATUS.COMPLETED,
    STATUS.PAYMENT_ERROR,
    STATUS.PAYMENT_SENT,
    STATUS.REQUISITES_UPDATED,
    STATUS.PAYMENT_CHECKED,
    STATUS.CANCELLED,
    STATUS.PAYMENT_PURCHASED,
  ].includes(status)
  let dialogTitle

  switch (status) {
    case STATUS.CANCELLED:
      dialogTitle = 'Введите комментарий'
      break
    case STATUS.PAYMENT_PURCHASED:
      dialogTitle = 'Загрузите скриншоты товаров'
      break
    case STATUS.PAYMENT_CHECKED:
      dialogTitle = `Введите сумму заказа в ${CNY}`
      break
    case STATUS.PAYMENT_COMPLETED:
      dialogTitle = 'Введите номер накладной для клиента'
      break
    case STATUS.REQUISITES_SENT:
      dialogTitle = 'Введите реквизиты из P2P для пользователя'
      break
    case STATUS.PAYMENT_RECEIVED:
      if (orderData?.is_p2p) {
        dialogTitle = 'Загрузите чек p2p перевода'
        break
      }
      dialogTitle = 'Введите новые реквизиты'
      break
    default:
      dialogTitle = 'Введите новые реквизиты'
  }

  const getExpandedPhotos = () => {
    const expandedPhotos = []

    if (orderData.files.real_order_payment_received) {
      expandedPhotos.push(<ExpandedPhotos text="Чек P2P заказа" images={orderData.files.real_order_payment_received} />)
    }

    if (orderData.files.payment_sent) {
      expandedPhotos.push(<ExpandedPhotos text="Чек клиента" images={orderData.files.payment_sent} />)
    }

    if (orderData.files.payment_error) {
      expandedPhotos.push(<ExpandedPhotos text="Ошибка платежа" images={orderData.files.payment_error} />)
    }

    if (orderData.files.completed) {
      expandedPhotos.push(<ExpandedPhotos text="Отчет об отправке клиенту" images={orderData.files.completed} />)
    }

    if (orderData.files.wallet_to_requisite) {
      expandedPhotos.push(<ExpandedPhotos text="Реквизиты получателя" images={orderData.files.wallet_to_requisite} />)
    }

    if (orderData.files.product_screen_sent) {
      expandedPhotos.push(<ExpandedPhotos text="Скриншоты товаров" images={orderData.files.product_screen_sent} />)
    }

    if (orderData.files.new) {
      expandedPhotos.push(<ExpandedPhotos text="Чек клиента" images={orderData.files.new} />)
    }

    if (orderData.files.purchased) {
      expandedPhotos.push(<ExpandedPhotos text="Скриншоты товаров" images={orderData.files.purchased} />)
    }

    if (orderData.files.prepare) {
      expandedPhotos.push(<ExpandedPhotos text="Скриншоты товаров" images={orderData.files.prepare} />)
    }

    if (Array.isArray(orderData.baskets) && orderData.baskets.length > 0 && orderData.groups.length === 0) {
      orderData.baskets.forEach((basket) => {
        expandedPhotos.push(<ExpandedPhotos isLink text={basket.link} images={basket.files} />)
      })
    }

    if (orderData.groups && orderData.groups.length > 0) {
      orderData.groups.forEach((group, index) => {
        expandedPhotos.push(
          <NestedPhotos
            text={`Группа ${index + 1}`}
            images={[]}
            group={group}
            baskets={orderData.baskets}
            isGroup
            isFirst={index === 0}
            isForSend
            setGroupForCompletion={(groupId) => {
              setGroupForCompletion(groupId)
              handleClickOpenDialog()
            }}
          />
        )
      })
    }
    return expandedPhotos
  }

  function getDialogContent() {
    const arrayElements = []

    if ([STATUS.PAYMENT_ERROR, STATUS.REQUISITES_UPDATED, STATUS.CANCELLED].includes(status)) {
      arrayElements.push(
        <TextField
          error={errorComment}
          helperText="Максимальная длина поля 200 символов"
          id="comment"
          style={{ width: '100%' }}
          multiline
          rows={4}
          label="Комментарий"
          variant="outlined"
          type="text"
          value={comment}
          onChange={(event) => {
            setComment(() => event.target.value)
          }}
        />
      )
    }

    if (
      ![
        STATUS.REQUISITES_UPDATED,
        STATUS.REQUISITES_SENT,
        STATUS.PAYMENT_COMPLETED,
        STATUS.PAYMENT_CHECKED,
        STATUS.PAYMENT_PURCHASED,
        STATUS.CANCELLED,
      ].includes(status) &&
      !groupForCompletion
    ) {
      arrayElements.push(
        <Grid spacing={2} container item xs={12}>
          <Grid item xs={12}>
            <ImageUploader
              withIcon
              onChange={onDrop}
              buttonText="Выбрать изображения"
              style={{ all: 'none' }}
              label="Максимальный размер файлов: 5 мегабайт"
              withPreview
              imgExtension={['.jpg', '.jpeg', '.bmp', '.png']}
              maxFileSize={5242880}
            />
          </Grid>
        </Grid>
      )
    }

    if (status === STATUS.PAYMENT_COMPLETED || groupForCompletion) {
      arrayElements.push(
        <TextField
          id="invoice"
          style={{ width: '100%' }}
          rows={4}
          label="Номер накладной"
          variant="outlined"
          type="text"
          value={invoice}
          onChange={(event) => {
            setInvoice(() => event.target.value)
          }}
        />
      )
    }

    if (status === STATUS.PAYMENT_CHECKED) {
      arrayElements.push(
        <Grid spacing={2} container item xs={12}>
          <Grid item xs={12}>
            <TextField
              id="amount"
              style={{ width: '100%' }}
              rows={4}
              label="Сумма"
              variant="outlined"
              type="number"
              value={amount || ''}
              onChange={(event) => {
                setAmount(() => event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="delivery_price"
              style={{ width: '100%' }}
              rows={4}
              label="Сумма доставки"
              variant="outlined"
              type="number"
              value={deliveryPrice || ''}
              onChange={(event) => {
                setDeliveryPrice(() => event.target.value)
              }}
            />
          </Grid>
        </Grid>
      )
    }

    if (status === STATUS.PAYMENT_PURCHASED) {
      arrayElements.push(
        <Grid spacing={2} container xs={12}>
          <Grid item xs={12}>
            <DndProvider backend={HTML5Backend}>
              <DNDScreens
                moveImage={moveImage}
                updatePurchasedAt={updatePurchasedAt}
                groups={groups}
                addImage={addImage}
                addGroup={() => {
                  const currentDate = new Date()
                  currentDate.setDate(currentDate.getDate() + 14)
                  setGroups([
                    ...groups,
                    {
                      items: [],
                      id: groups.length + 1,
                      groupId: groups.length + 1,
                      purchased_at: currentDate,
                      basketIds: [],
                      files: [],
                    },
                  ])
                }}
                deleteGroup={(groupId) => {
                  setGroups(groups.filter((group) => group.id !== groupId))
                }}
              />
            </DndProvider>
          </Grid>
          <Grid item xs={12}>
            <Select
              id="wallet_to_send_id"
              style={{ width: '100%' }}
              value={walletToSendId}
              placeholder="Выберите Кошелек"
              onChange={(event) => {
                setWalletToSendId(() => event.target.value)
              }}
            >
              <MenuItem disabled value="">
                <em>Выберите Кошелек</em>
              </MenuItem>
              {walletsToSend?.map((walletToSend) => (
                <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                  {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="amount"
              style={{ width: '100%' }}
              rows={4}
              label="Отправлена сумма"
              variant="outlined"
              type="number"
              value={amountPaid}
              onChange={(event) => {
                setAmountPaid(() => event.target.value)
              }}
            />
          </Grid>
        </Grid>
      )
    }

    if (status === STATUS.REQUISITES_SENT && orderData?.is_p2p) {
      arrayElements.push(
        <Grid xs={12} container item spacing={2}>
          <Grid xs={12} item>
            <TextField
              id="card"
              style={{ width: '100%' }}
              multiline
              rows={1}
              label="Номер карты"
              variant="outlined"
              type="number"
              value={card}
              onChange={(event) => {
                setCard(event.target.value)
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="name"
              style={{ width: '100%' }}
              multiline
              rows={1}
              label="Имя получателя"
              variant="outlined"
              type="text"
              value={receiverName}
              onChange={(event) => {
                setReceiverName(event.target.value)
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              error={errorComment}
              helperText="Максимальная длина поля 200 символов"
              id="comment"
              style={{ width: '100%' }}
              multiline
              rows={4}
              label="Комментарий"
              variant="outlined"
              type="text"
              value={comment}
              onChange={(event) => {
                setComment(() => event.target.value)
              }}
            />
          </Grid>
        </Grid>
      )
    }

    if (status === STATUS.PAYMENT_RECEIVED && orderData?.is_p2p) {
      arrayElements.push(
        <Grid spacing={2} container item xs={12}>
          <Grid item xs={12}>
            <Select
              id="currency_to_send_id"
              style={{ width: '100%' }}
              value={realGiveAmountCurrencyCode || ''}
              placeholder="Выберите валюту"
              onChange={(event) => {
                setRealGiveAmountCurrencyCode(event.target.value)
              }}
            >
              <MenuItem disabled value="">
                <em>Выберите Валюту</em>
              </MenuItem>
              {currencyCodesList?.map((currencyCode) => (
                <MenuItem key={`key_${currencyCode}`} value={currencyCode}>
                  {currencyCode}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select
              id="wallet_to_send_id"
              style={{ width: '100%' }}
              value={realWalletToSendId || ''}
              placeholder="Выберите Кошелек"
              onChange={(event) => {
                setRealWalletToSendId(() => event.target.value)
              }}
            >
              <MenuItem disabled value="">
                <em>Выберите Кошелек</em>
              </MenuItem>
              {realWalletsToSendByCurrency?.map((walletToSend) => (
                <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                  {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="amount"
              style={{ width: '100%' }}
              rows={4}
              label={`Получена сумма в ${realGiveAmountCurrencyCode || USDT}`}
              variant="outlined"
              type="number"
              value={realGiveAmount || ''}
              onChange={(event) => {
                setRealGiveAmount(() => event.target.value)
              }}
            />
          </Grid>
        </Grid>
      )
    }

    return arrayElements
  }

  const throttledOnClickToAssignToMe = throttle(() => {
    enqueueSnackbar(`Заявка на выкуп ${orderData.order_code} взята в работу`, {
      variant: 'success',
      autoHideDuration: 6000,
    })

    AxiosService.post(`/taobao-order/${id}/assign-to-me`)
      .then((res) => {
        if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
          mutate()
        } else {
          enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
        }
      })
      .catch((err) => {
        console.log('err', err)
        if (err.response) {
          console.log(err.response.status)
        }
      })
  }, 30000)

  if (orderError) {
    return <ErrorComponent />
  }

  if (isLoadingOrderData) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <ButtonAppBar />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Отменить
          </Button>
          <Button
            onClick={() => saveStatus()}
            color="primary"
            disabled={groupForCompletion ? !invoice || isLoading : isDialogConfirmDisabled() || isLoading}
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <br />
      <Grid container justify="center">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={firstColumnWidth}>
                Идентификатор заказа:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {orderData?.order_code}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Информация о заказе:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getTaobaoOrderData(orderData, orderData?.baskets?.length, false)}
              </Grid>
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {orderData?.receive_amount ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Сумма товаров:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {orderData?.receive_amount} {CNY}
                  </Grid>
                </>
              ) : null}

              {orderData?.delivery_price ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Сумма доставки:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {orderData?.delivery_price} {CNY}
                  </Grid>
                </>
              ) : null}
              {orderData?.commission ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Комиссия:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(orderData.commission)} {CNY}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={firstColumnWidth}>
                Тип заказа:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getTaobaoTypeBadge(orderData?.type)}
              </Grid>
              {orderData?.rate ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Рейт заказа:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {orderData.rate < 1
                      ? `${formattedCurrenciesFixed(1 / orderData.rate || 1, 6)} (1/${orderData.rate})`
                      : orderData.rate}
                  </Grid>
                </>
              ) : null}

              <Grid item xs={firstColumnWidth}>
                Статус:
                {orderData?.is_p2p && (
                  <span style={{ marginLeft: 8 }} className="badge badge-dark">
                    P2P
                  </span>
                )}
              </Grid>
              <Grid item xs={5}>
                {getStatusesChoice()}
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  color="primary"
                  disabled={status === orderData?.status}
                  onClick={() => {
                    if (status === STATUS.PAYMENT_COMPLETED && orderData?.type === 'link') {
                      return handleClickOpenDialog()
                    }
                    return shouldOpenDialog ||
                      (orderData?.is_p2p && (status === STATUS.REQUISITES_SENT || status === STATUS.PAYMENT_RECEIVED))
                      ? handleClickOpenDialog()
                      : saveStatus()
                  }}
                >
                  Применить
                </Button>
              </Grid>

              <Grid item xs={firstColumnWidth}>
                В работе у:
              </Grid>
              <Grid item xs={firstColumnWidth}>
                {orderData?.assign_to?.email || (orderData?.assign_to === user?.id ? 'текущего менеджера' : '-')}
              </Grid>
              <Grid item xs={firstColumnWidth} style={{ textAlign: 'right' }}>
                {orderData?.assign_to !== user?.id &&
                  orderData?.status &&
                  ![STATUS.COMPLETED, STATUS.CANCELLED, STATUS.CANCELLED_BY_USER].includes(orderData?.status) &&
                  user?.email !== orderData?.assign_to?.email && (
                    <Button onClick={throttledOnClickToAssignToMe}>
                      <span className="badge badge-warning">Взять в работу</span>
                    </Button>
                  )}
              </Grid>

              {orderData.cancel_comment ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Комментарий:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {orderData.cancel_comment}
                  </Grid>
                </>
              ) : null}
              {orderData.comments?.length > 0 && (
                <>
                  <Grid item xs={dividerWidth}>
                    <Divider />
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    Комментарии:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Grid container alignItems="center" spacing={1}>
                      <>
                        <Grid item xs={12} />
                        {orderData.comments.map((c) => (
                          <>
                            <Grid item xs={firstColumnWidth}>
                              <pre className={classes.preColor}>{c.created_at}</pre>
                            </Grid>
                            <Grid item xs={secondColumnWidth}>
                              <pre className={classes.preColor}>{c.text}</pre>
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {orderData.created_at && (
                <>
                  <Grid item xs={4}>
                    Создан:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.created_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.created_by ? orderData.created_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.cancelled_at && (
                <>
                  <Grid item xs={4}>
                    Отменен:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.cancelled_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.cancelled_by ? orderData.cancelled_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.checked_at && (
                <>
                  <Grid item xs={4}>
                    Расчет суммы проведен:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.checked_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.price_counted_by ? orderData.price_counted_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.waiting_for_client_readiness_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Менеджер подтвердил готовность:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData.waiting_for_client_readiness_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData?.waiting_for_client_readiness_by?.email}
                  </Grid>
                </>
              )}
              {orderData.client_asked_requisites_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Клиент готов получить реквизиты:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData.client_asked_requisites_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData?.requisites_updated_by?.email}
                  </Grid>
                </>
              )}
              {(orderData?.requisites_sent_at || orderData?.requisites_sent_by) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Реквизиты отправлены:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData?.requisites_sent_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {orderData?.requisites_sent_by?.email}
                  </Grid>
                </>
              )}
              {orderData.payment_error_at && (
                <>
                  <Grid item xs={4}>
                    Ошибка платежа:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.payment_error_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.payment_error_by ? orderData.payment_error_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.requisites_updated_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Реквизиты обновлены:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formatDateWithTime(orderData.requisites_updated_at)}
                  </Grid>
                </>
              )}
              {orderData.payment_sent_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Деньги отправлены:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formatDateWithTime(orderData.payment_sent_at)}
                  </Grid>
                </>
              )}
              {orderData.payment_received_at && (
                <>
                  <Grid item xs={4}>
                    Деньги получены:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.payment_received_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.payment_received_by ? orderData.payment_received_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.sent_receipt_to_client_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Отправить чек клиенту:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formatDateWithTime(orderData.sent_receipt_to_client_at)}
                  </Grid>
                </>
              )}
              {orderData.purchased_at && (
                <>
                  <Grid item xs={4}>
                    Товар выкуплен:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.purchased_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.purchased_by ? orderData.purchased_by.email : ''}
                  </Grid>
                </>
              )}
              {orderData.completed_at && (
                <>
                  <Grid item xs={4}>
                    Заявка завершена:
                  </Grid>
                  <Grid item xs={4}>
                    {formatDateWithTime(orderData.completed_at)}
                  </Grid>
                  <Grid item xs={4}>
                    {orderData.completed_by ? orderData.completed_by.email : ''}
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {orderData?.is_p2p && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Пользователь платит через P2P:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(orderData.give_amount)} {orderData.currency_code_from} на {orderData.wallet_to_send_name}
                    {orderData.wallet_to_send_receiver_name !== orderData.wallet_to_send?.receiver_name && (
                      <>
                        <br />
                        <span>
                          {orderData.wallet_to_send_address}
                          <CopyToClipboard text={orderData.wallet_to_send_address}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                          <br />
                          {orderData.wallet_to_send_receiver_name}
                          <CopyToClipboard text={orderData.wallet_to_send_receiver_name}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                          <br />
                          <i>{orderData.wallet_to_send_comment}</i>
                          <CopyToClipboard text={orderData.wallet_to_send_comment}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                        </span>
                      </>
                    )}
                  </Grid>
                </>
              )}
              {(orderData.payment_received_at || orderData.payment_sent_at || orderData?.status === STATUS.PAYMENT_SENT) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Проверить зачисление {orderData?.is_p2p ? '(от P2P)' : ''}:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(orderData?.real_give_amount ? orderData?.real_give_amount : orderData.give_amount)}{' '}
                    {orderData?.real_give_amount_currency_code
                      ? orderData?.real_give_amount_currency_code
                      : orderData.currency_code_from}{' '}
                    на
                    <Link
                      style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
                      to={`/wallets/${orderData.wallet_to_send_id}/history`}
                    >
                      <span>
                        {' '}
                        {orderData.wallet_to_send?.name}
                        {orderData.wallet_to_send?.description ? ` (${orderData.wallet_to_send?.description})` : ''}
                      </span>
                    </Link>
                    <br />
                    {formatWalletToSendRequisites()}
                    <CopyToClipboard text={formatWalletToSendRequisites()}>
                      <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                    </CopyToClipboard>
                  </Grid>
                </>
              )}
              {orderData?.amount_we_paid && Number(orderData?.amount_we_paid) > 0 ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Отправлена сумма:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {orderData?.amount_we_paid} {CNY}
                  </Grid>
                </>
              ) : null}
              {orderData.wallet_receive_amount_sent_from ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Отправлено с кошелька:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Link
                      style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
                      to={`/wallets/${orderData.wallet_receive_amount_sent_from.id}/history`}
                    >
                      <span>
                        {orderData.wallet_receive_amount_sent_from?.name}
                        {orderData.wallet_receive_amount_sent_from?.description
                          ? ` (${orderData.wallet_receive_amount_sent_from?.description})`
                          : ''}
                      </span>
                    </Link>

                    <br />
                    {orderData.wallet_receive_amount_sent_from?.account_num}
                    {orderData.wallet_receive_amount_sent_from?.type !== 'crypto' ? (
                      <>
                        <br />
                        {orderData.wallet_receive_amount_sent_from?.receiver_name}
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </>
              ) : (
                ''
              )}
              <>
                {orderData?.type === 'link' ? (
                  <>
                    <Grid item xs={firstColumnWidth}>
                      Адрес склада:
                    </Grid>
                    <Grid item xs={secondColumnWidth}>
                      {(
                        <>
                          {orderData?.storage_address}{' '}
                          <CopyToClipboard text={orderData?.storage_address}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                        </>
                      ) || '-'}
                    </Grid>
                  </>
                ) : (
                  <>
                    {orderData?.invoice ? (
                      <>
                        <Grid item xs={firstColumnWidth}>
                          Номер накладной:
                        </Grid>
                        <Grid item xs={secondColumnWidth}>
                          {(
                            <>
                              {orderData?.invoice}{' '}
                              <CopyToClipboard text={orderData?.invoice}>
                                <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                              </CopyToClipboard>
                            </>
                          ) || '-'}
                        </Grid>
                        <Grid item xs={dividerWidth}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </>
              <Grid item xs={firstColumnWidth}>
                Пользователь:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getLinkName(orderData.client)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                ID пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {orderData?.client?.id}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Тип пользователя:
              </Grid>
              <Grid item xs={5}>
                {typesOfClient[orderData?.client?.type]}
              </Grid>
              {orderData?.client?.type === 'telegram' &&
                (user.permissions.includes(PERMISSIONS.Notifications) || user.permissions.includes('admin')) && (
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/notifications/create?client=${orderData?.client?.id}`)}
                    >
                      Отправить сообщение
                    </Button>
                  </Grid>
                )}
              <Grid item xs={firstColumnWidth}>
                Тип верификации пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {formatVerificationType(orderData?.client?.verification)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Описание:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {orderData?.client?.description || '-'}
              </Grid>
              {orderData.files && orderData.files[STATUS.PAYMENT_SENT] && (
                <Grid item xs={dividerWidth}>
                  <Divider />
                </Grid>
              )}
              {'files' in orderData ? getExpandedPhotos() : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
