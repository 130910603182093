import React, { useState, useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'

import ButtonAppBar from '../bar/ButtonAppBar'
import ClientAutocomplete from './ClientAutocomplete'
import AxiosService from '../../utils/axiosService'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  buttons: {
    padding: theme.spacing(3),
  },
}))

export default function NotificationCreate() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      text: '',
      description: '',
      clients: [],
      all: false,
    },
  })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(async () => {
    const searchParams = new URLSearchParams(location.search)
    const clientId = searchParams.get('client')

    if (clientId) {
      const res = await AxiosService.get(`/clients/${clientId}`)

      setValue('clients', [res.data])
    }
  }, [location.search])

  const sendNotification = async (data) => {
    const payload = {
      text: data.text,
      description: data.description,
      type: data.all ? 'all' : 'client_ids',
    }

    if (!data.all) {
      payload.client_ids = data.clients.map((c) => c.id)
    }

    const res = await AxiosService.post('/client-notifications', payload)

    setIsLoading(false)

    if (res?.response?.status === 200 || res?.status === 200) {
      enqueueSnackbar('Рассылка создана', { variant: 'success' })
      navigate(`/notifications`)
    } else if (res?.response?.status === 422 || res?.status === 422) {
      Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
        enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
      })
    }
  }

  return (
    <>
      <ButtonAppBar />
      <Container maxWidth="md">
        <Paper square className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Создать рассылку
          </Typography>
          <form onSubmit={handleSubmit(sendNotification)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="text"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="text"
                      name="text"
                      label="Текст"
                      {...field}
                      error={Boolean(error)}
                      helperText={error ? 'Текст не может быть пустым' : null}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="description"
                      name="description"
                      label="Название"
                      {...field}
                      error={Boolean(error)}
                      helperText={error ? 'Название не может быть пустым' : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="all"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          name="all"
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Всем клиентам"
                    />
                  )}
                />
              </Grid>
              {!watch('all') && (
                <Grid item xs={12}>
                  <Controller
                    name="clients"
                    control={control}
                    defaultValue={[]}
                    rules={{ required: !watch('all') }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <ClientAutocomplete
                        value={value}
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error ? 'Нужно выбрать минимум одного клиента' : null}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid container justify="center" className={classes.buttons}>
                {!isLoading ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={!isValid}
                  >
                    Создать
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  )
}
