import React from 'react'
import Grid from '@material-ui/core/Grid'
import DropGroup from './DropGroup'
import AddButton from './AddButton'

const DNDScreens = ({ moveImage, groups = [], addGroup, updatePurchasedAt, addImage, deleteGroup }) => (
  <Grid style={{ marginBottom: 8 }} spacing={2} container xs={12}>
    {groups.map((group, index) => (
      <Grid item xs={12}>
        <DropGroup
          text={`Группа ${index + 1}`}
          images={[]}
          group={group}
          isGroup
          addImage={addImage}
          changeGroup={(item, groupIdFrom, groupIdTo, id) => moveImage(item.id, groupIdFrom, groupIdTo, id)}
          updatePurchasedAt={(groupId, purchasedAt) => updatePurchasedAt(groupId, purchasedAt)}
          deleteGroup={(id) => deleteGroup(id)}
          isFirst={index === 0}
        />
      </Grid>
    ))}
    <AddButton addGroup={() => addGroup()} />
  </Grid>
)

export default DNDScreens
