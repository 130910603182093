import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ImageUploader from 'react-images-upload'
import throttle from 'lodash/throttle'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { Checkbox } from '@material-ui/core'

import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import {
  formattedMoney,
  formatWalletToSendRequisitesSecret,
  getManualToSourceLabels,
  getLinkName,
  getOrderData,
  getStatusLabel,
  STATUS,
  typesOfClient,
  formattedCurrenciesFixed,
  useAuth,
  CURRENCIES,
  getRequestDirectionLabel,
} from '../../utils/customHooks'
import ExpandedPhotos from './ExpandedPhotos'
import { formatVerificationType } from '../../utils/text'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
  preColor: {
    color: theme.palette.text.secondary,
    fontFamily: 'Arial',
    fontSize: '10pt',
  },
}))

export default function Order() {
  const classes = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const firstColumnWidth = 4
  const secondColumnWidth = 8
  const dividerWidth = 12

  const { USDT, CNY, KZT, RUB, UAH } = CURRENCIES
  const [state, setState] = useState({
    data: [],
  })
  const [status, setStatus] = useState('')
  const [comment, setComment] = useState('')
  const [card, setCard] = useState(undefined)
  const [receiverName, setReceiverName] = useState(undefined)
  const [walletToSendId, setWalletToSendId] = useState(0)

  const errorComment = comment.length > 200
  const [walletsToSent, setWalletsToSent] = useState([])
  const [isCommissionConsidered, setIsCommissionConsidered] = useState(false)
  const [commission, setCommission] = useState(0)

  const [openDialog, setOpenDialog] = useState(false)
  const [openCopySnackbar, setOpenCopySnackbar] = useState(false)

  const [realGiveAmountCurrencyCode, setRealGiveAmountCurrencyCode] = useState(USDT)
  const [realGiveAmount, setRealGiveAmount] = useState(undefined)
  const [realWalletToSendId, setRealWalletToSendId] = useState(0)

  const [allRealWalletToSend, setAllRealWalletToSend] = useState([])
  const [currencyCodesList, setCurrencyCodesList] = useState([USDT])

  const realWalletsToSendByCurrency = useMemo(
    () =>
      allRealWalletToSend.filter(
        ({ currency: { currency_code } = {} } = {}) => currency_code === realGiveAmountCurrencyCode
      ),
    [allRealWalletToSend, realGiveAmountCurrencyCode]
  )

  useEffect(() => {
    setRealWalletToSendId(realWalletsToSendByCurrency?.[0]?.id)
  }, [realWalletsToSendByCurrency])

  async function fetchWalletsToSent() {
    const { data: allWallets } = await AxiosService.get(`/wallets/send?without_disabled=1`)
    setAllRealWalletToSend(allWallets)
    const currenciesList = [...new Set(allWallets?.map(({ currency: { currency_code } = {} } = {}) => currency_code))]
    setCurrencyCodesList(currenciesList)
    setRealGiveAmountCurrencyCode(currenciesList.includes(USDT) ? USDT : currenciesList[0])

    if (state.currency_code_to) {
      const { data: walletsToSendData } = await AxiosService.get(
        `/wallets/send?currency_code=${state.currency_code_to}&without_disabled=1`
      )
      setWalletsToSent(walletsToSendData)
      setWalletToSendId(walletsToSendData?.[0]?.id)
    }
    return []
  }

  useEffect(() => {
    fetchWalletsToSent()
  }, [state?.currency_code_to])

  const countCommission = (currencyCode, total) => {
    switch (currencyCode) {
      case UAH:
        return total * (0.5 / 100)
      case USDT:
        return 1
      case RUB:
        return total * (1.5 / 100) >= 30 ? total * (1.5 / 100) : 30
      case CNY:
        return total * (0.1 / 100)
      case KZT:
        return total * (1 / 100)
      default:
        return 0
    }
  }

  async function fetchData() {
    await AxiosService.get(`/order/${id}`).then((res) => {
      setState(res.data)
      if (res.data.status) {
        setStatus(res.data.status)
      }
      setCommission(
        formattedCurrenciesFixed(
          countCommission(res.data.currency_code_to, res.data.receive_amount),
          state.currency_code_to === USDT ? 4 : 2
        )
      )
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }

  const [pictures, setPictures] = useState([])

  const onDrop = (picture) => {
    setPictures(picture)
  }

  function isExchange() {
    return state.type === 'exchange'
  }

  function isManualExchange() {
    return Boolean(state.is_manual)
  }

  function isMyWalletWithdraw() {
    return state.type === 'my_wallet_withdraw'
  }

  function isMyWalletReplenish() {
    return state.type === 'my_wallet_replenish'
  }

  function isExchangeFromMyWallet() {
    return state.type === 'exchange_from_wallet'
  }

  function isExchangeToMyWallet() {
    return state.type === 'exchange_to_wallet'
  }

  function isCryptoReceive() {
    return state.wallet_to_receive_type === 'crypto'
  }

  function formatWalletToSendRequisites() {
    if (state.wallet_to_send.type === 'crypto') {
      return state.wallet_to_send.account_num
    }

    return `${state.wallet_to_send.account_num} ${state.wallet_to_send.receiver_name || ''} `
  }

  function getStatusesChoice() {
    switch (state.status) {
      case STATUS.REQUISITES_SENT:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.REQUISITES_SENT}>{getStatusLabel(STATUS.REQUISITES_SENT)}</MenuItem>
            {isManualExchange() ? (
              <MenuItem value={STATUS.PAYMENT_SENT}>{getStatusLabel(STATUS.PAYMENT_SENT)}</MenuItem>
            ) : (
              <></>
            )}
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_SENT:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_SENT}>{getStatusLabel(STATUS.PAYMENT_SENT)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.WAITING_MANAGER:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.WAITING_MANAGER}>{getStatusLabel(STATUS.WAITING_MANAGER)}</MenuItem>
            <MenuItem value={STATUS.WAITING_CLIENT}>{getStatusLabel(STATUS.WAITING_CLIENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.CLIENT_IS_WAITING:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.CLIENT_IS_WAITING}>{getStatusLabel(STATUS.CLIENT_IS_WAITING)}</MenuItem>
            <MenuItem value={STATUS.REQUISITES_SENT}>{getStatusLabel(STATUS.REQUISITES_SENT)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.PAYMENT_RECEIVED:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_RECEIVED}>{getStatusLabel(STATUS.PAYMENT_RECEIVED)}</MenuItem>
            <MenuItem value={STATUS.COMPLETED}>{getStatusLabel(STATUS.COMPLETED)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_ERROR}>{getStatusLabel(STATUS.PAYMENT_ERROR)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      case STATUS.COMPLETED:
        return getStatusLabel(STATUS.COMPLETED)
      case STATUS.WAITING_CLIENT:
        return getStatusLabel(STATUS.WAITING_CLIENT)
      case STATUS.CANCELLED:
        return getStatusLabel(STATUS.CANCELLED)
      case STATUS.CANCELLED_BY_USER:
        return getStatusLabel(STATUS.CANCELLED_BY_USER)
      case STATUS.PAYMENT_ERROR:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.PAYMENT_ERROR}>{getStatusLabel(STATUS.PAYMENT_ERROR)}</MenuItem>
            <MenuItem value={STATUS.COMPLETED}>{getStatusLabel(STATUS.COMPLETED)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
            <MenuItem value={STATUS.REQUISITES_UPDATED}>{getStatusLabel(STATUS.REQUISITES_UPDATED)}</MenuItem>
          </Select>
        )
      case STATUS.SEND_RECEIPT_TO_CLIENT:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.SEND_RECEIPT_TO_CLIENT}>{getStatusLabel(STATUS.SEND_RECEIPT_TO_CLIENT)}</MenuItem>
            <MenuItem value={STATUS.MANUAL_COMPLETED}>{getStatusLabel(STATUS.MANUAL_COMPLETED)}</MenuItem>
          </Select>
        )
      case STATUS.REQUISITES_UPDATED:
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem value={STATUS.REQUISITES_UPDATED}>{getStatusLabel(STATUS.REQUISITES_UPDATED)}</MenuItem>
            <MenuItem value={STATUS.COMPLETED}>{getStatusLabel(STATUS.COMPLETED)}</MenuItem>
            <MenuItem value={STATUS.PAYMENT_ERROR}>{getStatusLabel(STATUS.PAYMENT_ERROR)}</MenuItem>
            <MenuItem value={STATUS.CANCELLED}>{getStatusLabel(STATUS.CANCELLED)}</MenuItem>
          </Select>
        )
      default:
        return 'Ошибка сервера'
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setComment('')
    setPictures([])
  }

  async function saveStatus() {
    const curState = state
    curState.status = status
    setState(curState)
    if (
      [STATUS.COMPLETED, STATUS.PAYMENT_ERROR, STATUS.PAYMENT_SENT, STATUS.REQUISITES_UPDATED].includes(status) ||
      ((status === STATUS.PAYMENT_RECEIVED || status === STATUS.REQUISITES_SENT) && state?.is_p2p)
    ) {
      const formData = new FormData()

      if (comment !== '') {
        let fieldName
        switch (status) {
          case STATUS.REQUISITES_SENT:
            fieldName = 'wallet_to_send_comment'
            break
          case STATUS.REQUISITES_UPDATED:
            fieldName = 'new_requisites'
            break
          default:
            fieldName = 'comment'
        }

        formData.append(fieldName, comment)
      }

      if (commission && isCommissionConsidered) {
        formData.append('commission_amount', `${commission}`)
      }

      if (walletToSendId !== 0) {
        formData.append('wallet_to_send_id', `${walletToSendId}`)
      }

      if (pictures.length) {
        for (let i = 0; i < pictures.length; i += 1) {
          formData.append('files[]', pictures[pictures.length - 1])
        }
      }
      if (realGiveAmount) {
        formData.append('real_give_amount', realGiveAmount)
      }
      if (realWalletToSendId) {
        formData.append('real_wallet_to_send_id', realWalletToSendId)
      }
      await AxiosService.post(
        `/order/${state.id}/${status === STATUS.REQUISITES_SENT ? 'requisites_sent' : status}`,
        status === STATUS.REQUISITES_SENT
          ? {
              wallet_to_send_address: card,
              wallet_to_send_receiver_name: receiverName,
              wallet_to_send_comment: comment,
            }
          : formData,
        {
          headers: {
            'Content-Type': status === STATUS.REQUISITES_SENT ? 'application/json' : 'multipart/form-data',
          },
        }
      )
        .then((res) => {
          if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
            setState(res.data)
            fetchData()
            handleCloseDialog()
          } else {
            enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
          }
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 7000 })
        })
    } else {
      await AxiosService.post(`/order/${state.id}/${status === STATUS.REQUISITES_SENT ? 'requisites_sent' : status}`)
        .then((res) => {
          if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
            setState(res.data)
            fetchData()
          } else {
            enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
          }
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 7000 })
        })
    }
  }

  const handleClickCopy = () => {
    setOpenCopySnackbar(true)
  }

  const handleCloseCopySnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenCopySnackbar(false)
  }

  const hasWalletToRequisite = Boolean(state?.files?.wallet_to_requisite)

  let isDialogConfirmDisabled
  if (status === STATUS.PAYMENT_RECEIVED && state?.is_p2p) {
    isDialogConfirmDisabled = !realGiveAmount || !realWalletToSendId || pictures.length === 0
  } else if (status === STATUS.REQUISITES_SENT) {
    isDialogConfirmDisabled = !receiverName || !card
  } else {
    isDialogConfirmDisabled =
      errorComment || (status === STATUS.REQUISITES_UPDATED ? comment.length === 0 : pictures.length === 0)
  }

  const shouldOpenDialog = [
    STATUS.COMPLETED,
    STATUS.PAYMENT_ERROR,
    STATUS.PAYMENT_SENT,
    STATUS.REQUISITES_UPDATED,
  ].includes(status)

  let dialogTitle = 'Загрузите изображения для клиента'

  if (status === STATUS.REQUISITES_SENT) {
    dialogTitle = 'Введите реквизиты из P2P для пользователя'
  } else if (status === STATUS.PAYMENT_RECEIVED && state?.is_p2p) {
    dialogTitle = 'Загрузите чек p2p перевода'
  } else if (status === STATUS.REQUISITES_UPDATED) {
    dialogTitle = 'Введите новые реквизиты'
  }

  const getExpandedPhotos = () => {
    const expandedPhotos = []

    if (state.files.real_order_payment_received) {
      expandedPhotos.push(<ExpandedPhotos text="Чек P2P заказа" images={state.files.real_order_payment_received} />)
    }

    if (state.files.payment_sent) {
      expandedPhotos.push(<ExpandedPhotos text="Чек клиента" images={state.files.payment_sent} />)
    }

    if (state.files.payment_error) {
      expandedPhotos.push(<ExpandedPhotos text="Ошибка платежа" images={state.files.payment_error} />)
    }

    if (state.files.completed) {
      expandedPhotos.push(<ExpandedPhotos text="Отчет об отправке клиенту" images={state.files.completed} />)
    }

    if (state.files.wallet_to_requisite) {
      expandedPhotos.push(<ExpandedPhotos text="Реквизиты получателя" images={state.files.wallet_to_requisite} />)
    }

    return expandedPhotos
  }

  const throttledOnClickToAssignToMe = throttle(() => {
    enqueueSnackbar(`Заявка ${state.order_code} взята в работу`, { variant: 'success', autoHideDuration: 6000 })

    AxiosService.post(`/order/${id}/assign-to-me`)
      .then((res) => {
        if ([200, 201, 204].includes(res?.response?.status) || [200, 201, 204].includes(res?.status)) {
          setState(res.data)
          fetchData()
        } else {
          enqueueSnackbar(res?.response?.data?.message, { variant: 'error', autoHideDuration: 3000 })
        }
      })
      .catch((err) => {
        console.log('err', err)
        if (err.response) {
          console.log(err.response.status)
        }
      })
  }, 30000)

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openCopySnackbar}
        autoHideDuration={3000}
        onClose={handleCloseCopySnackbar}
        message="Данные получателя скопированы"
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseCopySnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <ButtonAppBar />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {[STATUS.PAYMENT_ERROR, STATUS.REQUISITES_UPDATED].includes(status) && (
            <TextField
              error={errorComment}
              helperText="Максимальная длина поля 200 символов"
              id="comment"
              style={{ width: '100%' }}
              multiline
              rows={4}
              label="Комментарий"
              variant="outlined"
              type="text"
              value={comment}
              onChange={(event) => {
                setComment(() => event.target.value)
              }}
            />
          )}
          {status === STATUS.COMPLETED && (
            <>
              <Select
                id="wallet_to_send_id"
                style={{ width: '100%' }}
                value={walletToSendId}
                placeholder="Выберите Кошелек"
                onChange={(event) => {
                  setWalletToSendId(() => event.target.value)
                }}
              >
                <MenuItem disabled value="">
                  <em>Выберите Кошелек</em>
                </MenuItem>
                {walletsToSent?.map((walletToSend) => (
                  <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                    {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                  </MenuItem>
                ))}
              </Select>
              <Grid style={{ marginTop: 8 }} alignItems="center" container>
                <Grid item>Учесть комиссию:</Grid>
                <Checkbox
                  placeholder="Учесть комиссию:"
                  style={{ padding: 0 }}
                  checked={isCommissionConsidered}
                  onChange={() => setIsCommissionConsidered(!isCommissionConsidered)}
                />
              </Grid>
              {isCommissionConsidered ? (
                <TextField
                  type="number"
                  style={{ width: '100%' }}
                  value={commission}
                  onChange={(event) => {
                    if (event.target.value >= 0)
                      setCommission(
                        formattedCurrenciesFixed(event.target.value, state.currency_code_to === USDT ? 4 : 2)
                      )
                  }}
                />
              ) : null}
            </>
          )}
          {status !== STATUS.REQUISITES_UPDATED && status !== STATUS.REQUISITES_SENT && (
            <Grid spacing={2} container xs={12}>
              <Grid item xs={12}>
                <ImageUploader
                  withIcon
                  onChange={onDrop}
                  buttonText="Выбрать изображения"
                  style={{ all: 'none' }}
                  label="Максимальный размер файлов: 5 мегабайт"
                  withPreview
                  imgExtension={['.jpg', '.jpeg', '.bmp', '.png']}
                  maxFileSize={5242880}
                />
              </Grid>
            </Grid>
          )}
          {status === STATUS.PAYMENT_RECEIVED && state?.is_p2p ? (
            <Grid spacing={2} container xs={12}>
              <Grid item xs={12}>
                <Select
                  id="currency_to_send_id"
                  style={{ width: '100%' }}
                  value={realGiveAmountCurrencyCode || ''}
                  placeholder="Выберите валюту"
                  onChange={(event) => {
                    setRealGiveAmountCurrencyCode(event.target.value)
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Выберите Валюту</em>
                  </MenuItem>
                  {currencyCodesList?.map((currencyCode) => (
                    <MenuItem key={`key_${currencyCode}`} value={currencyCode}>
                      {currencyCode}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="wallet_to_send_id"
                  style={{ width: '100%' }}
                  value={realWalletToSendId}
                  placeholder="Выберите Кошелек"
                  onChange={(event) => {
                    setRealWalletToSendId(() => event.target.value)
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Выберите Кошелек</em>
                  </MenuItem>
                  {realWalletsToSendByCurrency?.map((walletToSend) => (
                    <MenuItem key={`walletsTo_${walletToSend.id}`} value={walletToSend.id}>
                      {`${formatWalletToSendRequisitesSecret(walletToSend)}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="amount"
                  style={{ width: '100%' }}
                  rows={4}
                  label={`Получена сумма в ${realGiveAmountCurrencyCode || USDT}`}
                  variant="outlined"
                  type="number"
                  value={realGiveAmount}
                  onChange={(event) => {
                    setRealGiveAmount(() => event.target.value)
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          {status === STATUS.REQUISITES_SENT && state?.is_p2p ? (
            <Grid xs={12} container spacing={2}>
              <Grid xs={12} item>
                <TextField
                  id="card"
                  style={{ width: '100%' }}
                  multiline
                  rows={1}
                  label="Номер карты"
                  variant="outlined"
                  type="number"
                  value={card}
                  onChange={(event) => {
                    setCard(event.target.value)
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  id="name"
                  style={{ width: '100%' }}
                  multiline
                  rows={1}
                  label="Имя получателя"
                  variant="outlined"
                  type="text"
                  value={receiverName}
                  onChange={(event) => {
                    setReceiverName(event.target.value)
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  error={errorComment}
                  helperText="Максимальная длина поля 200 символов"
                  id="comment"
                  style={{ width: '100%' }}
                  multiline
                  rows={4}
                  label="Комментарий"
                  variant="outlined"
                  type="text"
                  value={comment}
                  onChange={(event) => {
                    setComment(() => event.target.value)
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={saveStatus} color="primary" disabled={isDialogConfirmDisabled}>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <br />
      <Grid container justify="center">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={1}>
              {state?.order_code ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Идентификатор заказа:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state.order_code}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={firstColumnWidth}>
                Информация о заказе:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getOrderData(state)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Источник заказа:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getManualToSourceLabels[+state.is_manual]}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Направление заявки:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getRequestDirectionLabel(state.request_direction)}
              </Grid>
              {(isExchange() || isExchangeFromMyWallet() || isExchangeToMyWallet()) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Рейт заказа:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state.rate_description}
                  </Grid>
                </>
              )}
              {(isExchange() || isExchangeFromMyWallet() || isExchangeToMyWallet() || isMyWalletWithdraw()) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Комиссия:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(state.markup)} {state.currency_code_from}
                  </Grid>
                </>
              )}
              {state.device ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Устройство:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {state.device}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Статус:
                {state?.is_p2p && (
                  <span style={{ marginLeft: 8 }} className="badge badge-dark">
                    P2P
                  </span>
                )}
              </Grid>
              <Grid item xs={5}>
                {getStatusesChoice()}
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  color="primary"
                  disabled={status === state.status}
                  onClick={() =>
                    shouldOpenDialog ||
                    (state?.is_p2p && (status === STATUS.REQUISITES_SENT || status === STATUS.PAYMENT_RECEIVED))
                      ? handleClickOpenDialog()
                      : saveStatus()
                  }
                >
                  Применить
                </Button>
              </Grid>
              <Grid item xs={firstColumnWidth}>
                В работе у:
              </Grid>
              <Grid item xs={firstColumnWidth}>
                {state?.assign_to?.email || (state?.assign_to === user?.id ? 'текущего менеджера' : '-')}
              </Grid>
              <Grid item xs={firstColumnWidth} style={{ textAlign: 'right' }}>
                {state?.assign_to !== user?.id &&
                  state?.status &&
                  ![STATUS.COMPLETED, STATUS.CANCELLED, STATUS.CANCELLED_BY_USER].includes(state?.status) &&
                  user?.email !== state?.assign_to?.email && (
                    <Button onClick={throttledOnClickToAssignToMe}>
                      <span className="badge badge-warning">Взять в работу</span>
                    </Button>
                  )}
              </Grid>
              {state.comments?.length > 0 && (
                <>
                  <Grid item xs={dividerWidth}>
                    <Divider />
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    Комментарии:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Grid container alignItems="center" spacing={1}>
                      <>
                        <Grid item xs={12} />
                        {state.comments.map((c) => (
                          <>
                            <Grid item xs={firstColumnWidth}>
                              <pre className={classes.preColor}>{c.created_at}</pre>
                            </Grid>
                            <Grid item xs={secondColumnWidth}>
                              <pre className={classes.preColor}>{c.text}</pre>
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {state.created_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Создан:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.created_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.created_by?.email}
                  </Grid>
                </>
              )}
              {state.waiting_for_client_readiness_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Менеджер подтвердил готовность:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.waiting_for_client_readiness_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.waiting_for_client_readiness_by?.email}
                  </Grid>
                </>
              )}
              {state.client_asked_requisites_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Клиент готов получить реквизиты:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.client_asked_requisites_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.requisites_updated_by?.email}
                  </Grid>
                </>
              )}
              {state.requisites_sent_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Реквизиты отправлены:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.requisites_sent_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.requisites_sent_by?.email}
                  </Grid>
                </>
              )}
              {state.requisites_updated_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Реквизиты обновлены:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.requisites_updated_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.requisites_updated_by?.email}
                  </Grid>
                </>
              )}
              {state.payment_sent_at && (isExchange() || isExchangeToMyWallet() || isMyWalletReplenish()) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Деньги отправлены:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.payment_sent_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.payment_sent_by?.email}
                  </Grid>
                </>
              )}
              {state.payment_received_at && (isExchange() || isExchangeToMyWallet()) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Деньги получены:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.payment_received_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.payment_received_by?.email}
                  </Grid>
                </>
              )}
              {state.sent_receipt_to_client_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Отправить чек клиенту:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.sent_receipt_to_client_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.send_receipt_to_client_by?.email}
                  </Grid>
                </>
              )}

              {state.completed_at && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Заявка завершена:
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state.completed_at}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    {state?.completed_by?.email || state?.cancelled_by?.email}
                  </Grid>
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {state?.is_p2p ? (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Пользователь платит через P2P:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(state.give_amount)} {state.currency_code_from} на {state.wallet_to_send_name}
                    {state.wallet_to_send_receiver_name !== state.wallet_to_send?.receiver_name && (
                      <>
                        <br />
                        <span>
                          {state.wallet_to_send_address}
                          <CopyToClipboard text={state.wallet_to_send_address}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                          <br />
                          {state.wallet_to_send_receiver_name}
                          <CopyToClipboard text={state.wallet_to_send_receiver_name}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                          <br />
                          <i>{state.wallet_to_send_comment}</i>
                          <CopyToClipboard text={state.wallet_to_send_comment}>
                            <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                          </CopyToClipboard>
                        </span>
                      </>
                    )}
                  </Grid>
                </>
              ) : null}
              {(isExchange() || isExchangeToMyWallet() || isMyWalletReplenish()) &&
                ((state?.is_p2p && state?.real_give_amount) || !state?.is_p2p) && (
                  <>
                    <Grid item xs={firstColumnWidth}>
                      Проверить зачисление {state?.is_p2p ? '(от P2P)' : ''}:
                    </Grid>
                    <Grid item xs={secondColumnWidth}>
                      {formattedMoney(state?.real_give_amount ? state?.real_give_amount : state.give_amount)}{' '}
                      {state?.real_give_amount_currency_code
                        ? state?.real_give_amount_currency_code
                        : state.currency_code_from}{' '}
                      на
                      <Link
                        style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
                        to={`/wallets/${state.wallet_to_send_id}/history`}
                      >
                        <span>
                          {' '}
                          {state.wallet_to_send.name}
                          {state.wallet_to_send?.description ? ` (${state.wallet_to_send?.description})` : ''}
                        </span>
                      </Link>
                      <br />
                      {formatWalletToSendRequisites()}
                      <CopyToClipboard text={formatWalletToSendRequisites()}>
                        <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                      </CopyToClipboard>
                    </Grid>
                  </>
                )}
              {(isExchange() || isMyWalletWithdraw() || isExchangeFromMyWallet()) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Отправить:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {formattedMoney(state.receive_amount)} {state.currency_code_to} на {state.wallet_to_receive_name}
                    {!hasWalletToRequisite && (
                      <>
                        <br />
                        {state.wallet_to_receive_address}
                        <CopyToClipboard text={state.wallet_to_receive_address}>
                          <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                        </CopyToClipboard>
                        {!isCryptoReceive() && (
                          <>
                            <br />
                            {state.wallet_to_receive_receiver_name}{' '}
                            <CopyToClipboard text={state.wallet_to_receive_receiver_name}>
                              <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                            </CopyToClipboard>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    Копировать получателя:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <CopyToClipboard
                      text={`${formattedMoney(state.receive_amount)} ${state.currency_code_to} на ${
                        state.wallet_to_receive_name
                      } ${
                        !hasWalletToRequisite
                          ? `${state.wallet_to_receive_address} ${state.wallet_to_receive_receiver_name}`
                          : ''
                      } `}
                    >
                      <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                    </CopyToClipboard>
                  </Grid>
                  {state.wallet_receive_amount_sent_from ? (
                    <>
                      <Grid item xs={dividerWidth}>
                        <Divider />
                      </Grid>
                      <Grid item xs={firstColumnWidth}>
                        Отправлено с кошелька:
                      </Grid>
                      <Grid item xs={secondColumnWidth}>
                        <Link
                          style={getLinkStylesByPermission(PERMISSIONS.Wallets)}
                          to={`/wallets/${state.wallet_receive_amount_sent_from.id}/history`}
                        >
                          <span>
                            {state.wallet_receive_amount_sent_from?.name}
                            {state.wallet_receive_amount_sent_from?.description
                              ? ` (${state.wallet_receive_amount_sent_from?.description})`
                              : ''}
                          </span>
                        </Link>

                        <br />
                        {state.wallet_receive_amount_sent_from?.account_num}
                        {state.wallet_receive_amount_sent_from?.type !== 'crypto' ? (
                          <>
                            <br />
                            {state.wallet_receive_amount_sent_from?.receiver_name}
                          </>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                  {state.status === STATUS.COMPLETED || state.status === STATUS.SEND_RECEIPT_TO_CLIENT ? (
                    <>
                      <Grid item xs={firstColumnWidth}>
                        Комиссия отправки:
                      </Grid>
                      <Grid item xs={secondColumnWidth}>
                        <span>
                          {state.wallet_receive_amount_sent_from_commission} {state.currency_code_to}
                        </span>
                      </Grid>
                      {state.completed_confirm ? (
                        <>
                          <Grid item xs={firstColumnWidth}>
                            Клиент подтвердил получение
                          </Grid>
                          <Grid item xs={secondColumnWidth}>
                            ✅
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
              <Grid item xs={dividerWidth}>
                <Divider />
              </Grid>
              {state.updated_requisites && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Обновленные реквизиты:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <pre className={classes.preColor}>{state.updated_requisites}</pre>
                    <CopyToClipboard text={state.updated_requisites}>
                      <FileCopyIcon className="copy-clipboard" fontSize="small" onClick={handleClickCopy} />
                    </CopyToClipboard>
                  </Grid>
                  <Grid item xs={dividerWidth}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={firstColumnWidth}>
                Пользователь:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {getLinkName(state.client)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                ID пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {state?.client?.id}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Тип пользователя:
              </Grid>
              <Grid item xs={5}>
                {typesOfClient[state?.client?.type]}
              </Grid>
              {state?.client?.type === 'telegram' &&
                (user.permissions.includes(PERMISSIONS.Notifications) || user.permissions.includes('admin')) && (
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/notifications/create?client=${state?.client?.id}`)}
                    >
                      Отправить сообщение
                    </Button>
                  </Grid>
                )}
              <Grid item xs={firstColumnWidth}>
                Тип верификации пользователя:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {formatVerificationType(state?.client?.verification)}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Контакт:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {state?.client?.contact || '-'}
              </Grid>
              <Grid item xs={firstColumnWidth}>
                Описание:
              </Grid>
              <Grid item xs={secondColumnWidth}>
                {state?.client?.description || '-'}
              </Grid>
              {(state?.client?.verification === 'by_card' || state?.client?.verified_cards?.length > 0) && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    Верифицированные карты пользователя:
                  </Grid>
                  <Grid item xs={secondColumnWidth} style={{ whiteSpace: 'pre-line' }}>
                    {state?.client?.verified_cards}
                  </Grid>
                </>
              )}
              {state.files && state.files[STATUS.PAYMENT_SENT] && (
                <Grid item xs={dividerWidth}>
                  <Divider />
                </Grid>
              )}
              {state.files && getExpandedPhotos()}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
