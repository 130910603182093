import React, { createRef, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import { parse, stringify } from 'query-string'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format, parse as parseDate } from 'date-fns'

import useSWR from 'swr'
import debounce from 'lodash/debounce'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import {
  getLinkName,
  getOrderData,
  getStatusLabel,
  getManualToSourceLabels,
  typesOfClient,
  STATUS,
} from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import fetcher from '../../utils/fetcher'
import { formatDateWithTime } from '../../utils/date'

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default function TaobaoRegistrations() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { data: statuses } = useSWR('/taobao-register/statuses', fetcher)
  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    // eslint-disable-next-line no-restricted-globals,no-undef
    parse(location.search) || {
      status: '',
    }
  )
  const tableRef = createRef()

  const onChange = (e) => {
    dispatch({ search_string: e.target.value || undefined, page: 1 })
  }

  const debouncedOnChange = debounce(onChange, 300)

  const statusFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-outlined-label">Статус</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.status ? parsed.status : ''}
        onChange={(event) => dispatch({ status: event.target.value || undefined })}
        label="Статус"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {statuses &&
          statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {getStatusLabel(status)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )

  const createdAtFrom = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_from"
        label="Создан после"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_from ? parseDate(parsed?.created_at_from, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_from: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const createdAtTo = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_to"
        label="Создан до"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_to ? parseDate(parsed?.created_at_to, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_to: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const codeFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <TextField
        id="outlined-basic"
        label="Поиск"
        variant="outlined"
        type="text"
        defaultValue={parsed.search_string || ''}
        onChange={debouncedOnChange}
      />
    </FormControl>
  )

  const columns = [
    {
      title: 'Дата',
      field: 'created_at',
      maxWidth: 110,
    },
    {
      title: '№ Заказа',
      field: 'register_code',
      maxWidth: 90,
    },
    {
      title: 'Статус',
      field: 'status',
      maxWidth: 120,
      render: ({ status }) => <>{getStatusLabel(status)}</>,
    },
    {
      title: 'Имя клиента',
      field: 'name',
      maxWidth: 150,
    },
  ]

  useDidMountEffect(() => {
    navigate(`/taobao-registrations?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  return (
    <div>
      <ButtonAppBar />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <br />
          {codeFilter}
          {statusFilter}
          {createdAtFrom}
          {createdAtTo}
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <br />
          <MaterialTable
            title="Регистрации Taobao"
            tableRef={tableRef}
            searchable={false}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                let url = '/taobao-register?'
                // url += 'per_page=' + query.pageSize
                url += `&page=${query.page + 1}`
                url += `&${stringify(parsed)}`

                AxiosService.get(url).then((result) => {
                  resolve({
                    data: result?.data?.data?.map(({ id, status, created_at, register_code, client }) => ({
                      id,
                      register_code: (
                        <Link key={id} to={`/taobao-registration/${id}`} style={{ color: `inherit` }}>
                          {register_code}
                        </Link>
                      ),
                      status,
                      created_at: formatDateWithTime(created_at),
                      name: getLinkName(client),
                    })),
                    page: result?.data?.current_page - 1,
                    totalCount: result?.data?.total,
                  })
                })
              })
            }
            options={{
              search: false,
              sorting: false,
              // columnsButton: true,
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15],
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
