import React, { useState, useReducer, useEffect } from 'react'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useNavigate } from 'react-router-dom'
import { FormControl, Input, InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { parse, stringify } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import ButtonAppBar from '../bar/ButtonAppBar'
import AxiosService from '../../utils/axiosService'
import { formattedCurrenciesFixed } from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'

import WalletCommentDialog from './WalletCommentDialog'

function Alert(props) {
  const { onClose, severity } = props
  return <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity} />
}

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default function Currency() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [currencies, setCurrencies] = useState({})
  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    // eslint-disable-next-line no-restricted-globals,no-undef
    parse(location.search) || {
      status: '',
      currency_code: '',
    }
  )
  const statuses = {
    ENABLED: '0_enabled',
    HIDDEN_FOR_USERS: '1_hidden_for_users',
    DISABLED: '2_disabled',
  }

  const columns = [
    {
      title: 'Название карты',
      field: 'name',
    },
    {
      title: 'Внутреннее имя',
      field: 'description',
    },
    {
      title: 'Тип',
      field: 'type',
      lookup: {
        bankcard: 'Банковская карта',
        alipay: 'Алипэй',
        bankcard_chinese: 'Китайская карта',
        crypto: 'Крипта',
        bankcard_p2p: 'P2P',
      },
      editable: 'onAdd',
    },
    {
      title: 'Валюта',
      field: 'currency',
      lookup: currencies,
      editable: 'onAdd',
    },
    {
      title: 'Текущий баланс',
      field: 'current_balance',
      initialEditValue: '0.00',
      editComponent: (props) => {
        const { value = 0 } = props
        return (
          <input
            type="number"
            style={{
              color: 'black',
              border: 0,
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            }}
            required
            min={0}
            max={100000000}
            step={0.01}
            value={formattedCurrenciesFixed(value, 2)}
            onChange={(e) => props?.onChange(formattedCurrenciesFixed(e.target.value, 2))}
          />
        )
      },
    },
    {
      title: 'Номер счета/карты',
      field: 'account_num',
    },
    {
      title: 'Имя получателя',
      field: 'receiver_name',
    },
    {
      title: 'Статус',
      field: 'status',
      lookup: {
        [statuses.ENABLED]: <span className="badge badge-success">Виден в боте</span>,
        [statuses.HIDDEN_FOR_USERS]: <span className="badge badge-light">Cкрыт в боте</span>,
        [statuses.DISABLED]: <span className="badge badge-dark">Неактивен</span>,
      },
    },
    {
      title: 'Мин. лимит',
      field: 'min_give_amount',
      initialEditValue: '0.01',
      editComponent: (props) => {
        const { value = 0 } = props
        return (
          <input
            type="number"
            style={{
              color: 'black',
              border: 0,
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            }}
            required
            min={0.01}
            max={9999999.99}
            step={0.01}
            value={formattedCurrenciesFixed(value, 2)}
            onChange={(e) => props?.onChange(formattedCurrenciesFixed(e.target.value, 2))}
          />
        )
      },
    },
    {
      title: 'Макс. лимит',
      field: 'max_give_amount',
      initialEditValue: '0.00',
      editComponent: (props) => {
        const { value = 0 } = props
        return (
          <input
            type="number"
            style={{
              color: 'black',
              border: 0,
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            }}
            required
            min={0}
            max={9999999.99}
            step={0.01}
            value={formattedCurrenciesFixed(value, 2)}
            onChange={(e) => props?.onChange(formattedCurrenciesFixed(e.target.value, 2))}
          />
        )
      },
    },
    {
      title: 'Коэффициент курса',
      field: 'wallet_rate_percent',
      type: 'numeric',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      render: ({ wallet_rate_percent }) => <>{wallet_rate_percent}%</>,
      editComponent: (props) => {
        const { value } = props
        const [curValue, setCurValue] = useState(value)
        return (
          <>
            <Input
              type="number"
              name="variable"
              style={{ color: 'black' }}
              required
              inputProps={{
                step: 0.01,
                min: 0,
                max: 100,
              }}
              value={formattedCurrenciesFixed(curValue)}
              onChange={(e) => {
                setCurValue(e.target.value)
                props.onChange(e.target.value)
              }}
            />
          </>
        )
      },
    },
  ]
  const tableRef = React.createRef()

  const [open, setOpen] = useState(false)
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false)
  const [updatedWallet, setUpdatedWallet] = useState(null)

  function handleCommentDialogClose() {
    setUpdatedWallet(null)
    setIsCommentDialogOpen(false)
  }

  useDidMountEffect(() => {
    navigate(`/wallets?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  async function handleCommentDialogConfirm(comment) {
    try {
      await AxiosService.put(`/wallets/send/${updatedWallet.id}`, { ...updatedWallet, comment })
    } catch (err) {
      console.error('Wallet Update Error: ', err)
    } finally {
      // eslint-disable-next-line no-unused-expressions
      tableRef.current && tableRef.current.onQueryChange()
      handleCommentDialogClose()
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    const fetchCurrencies = async () => {
      const cur = {}
      await AxiosService.get(`/currencies`).then((res) => {
        if (res.data) {
          const mergedCurrencies = res.data
          mergedCurrencies.map(({ id, currency_code: currencyCode }) => (cur[id] = currencyCode || '-'))
          setCurrencies(cur)
        }
      })
    }
    fetchCurrencies()
  }, [])

  const currencyFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-outlined-label">Валюта</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.currency_code}
        defaultValue=""
        onChange={(event) =>
          dispatch({ currency_code: event.target.value === '-' ? undefined : event.target.value || undefined })
        }
        label="Валюта"
      >
        <MenuItem value={null}>
          <em>-</em>
        </MenuItem>
        {Object.keys(currencies).map((key) => (
          <MenuItem key={key} value={currencies[key]}>
            {currencies[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const statusFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-autowidth-label">Статус</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.status}
        defaultValue=""
        onChange={(event) => dispatch({ status: event.target.value || undefined })}
        label="Статус"
      >
        <MenuItem value={null}>
          <em>-</em>
        </MenuItem>
        {Object.keys(statuses).map((key) => (
          <MenuItem key={key} value={statuses[key]}>
            <>
              {key === 'EMPTY' && <span className="badge badge-light">-</span>}
              {key === 'ENABLED' && <span className="badge badge-success">Виден в боте</span>}
              {key === 'HIDDEN_FOR_USERS' && <span className="badge badge-light">Cкрыт в боте</span>}
              {key === 'DISABLED' && <span className="badge badge-dark">Неактивен</span>}
            </>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Все поля должны быть заполнены!
        </Alert>
      </Snackbar>
      <ButtonAppBar />
      <WalletCommentDialog
        isOpen={isCommentDialogOpen}
        handleClose={handleCommentDialogClose}
        handleConfirm={handleCommentDialogConfirm}
        updatedWallet={updatedWallet}
      />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={11} lg={10}>
          {statusFilter}
          {currencyFilter}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={10}>
          <br />
          <MaterialTable
            title="Кошельки"
            columns={columns}
            tableRef={tableRef}
            localization={{
              header: {
                actions: 'Действия',
              },
            }}
            options={{
              addRowPosition: 'first',
              search: false,
              sorting: true,
              paging: false,
              actionsColumnIndex: -1,
              rowStyle: ({ status }) => {
                let backgroundColor
                if (status === statuses.DISABLED) {
                  backgroundColor = '#DDD'
                } else if (status === statuses.HIDDEN_FOR_USERS) {
                  backgroundColor = '#EEE'
                } else {
                  backgroundColor = '#FFF'
                }
                return {
                  backgroundColor,
                }
              },
            }}
            data={() =>
              new Promise((resolve) => {
                const url = '/wallets/send'
                AxiosService.get(url, {
                  params: parsed,
                })
                  .then((result) => {
                    resolve({
                      data: result?.data?.map(
                        ({
                          id,
                          name,
                          type,
                          currency: { id: currency },
                          status,
                          account_num,
                          receiver_name,
                          current_balance,
                          description,
                          min_give_amount,
                          max_give_amount,
                          wallet_rate_percent,
                        }) => ({
                          id,
                          name,
                          type,
                          currency,
                          status,
                          account_num,
                          receiver_name,
                          current_balance,
                          description,
                          min_give_amount,
                          max_give_amount,
                          wallet_rate_percent,
                        })
                      ),
                      page: result.data.current_page - 1,
                      totalCount: result.data.total,
                    })
                  })
                  .catch((err) => {
                    console.log('err', err)
                  })
              })
            }
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (
                      !newData.name ||
                      !newData.type ||
                      !newData.currency ||
                      !newData.status ||
                      !newData.account_num ||
                      !newData.receiver_name
                    ) {
                      setOpen(true)
                      reject()
                      return
                    }
                    resolve()
                    AxiosService.post(`/wallets/send`, new URLSearchParams(newData), {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    })
                      .then(() => {
                        // eslint-disable-next-line no-unused-expressions
                        tableRef.current && tableRef.current.onQueryChange()
                      })
                      .catch((err) => {
                        console.log('err', err)
                        if (err.response) {
                          console.log(err.response.status)
                        }
                      })
                    // eslint-disable-next-line no-unused-expressions
                    tableRef.current && tableRef.current.onQueryChange()
                  }, 600)
                }),
              onRowUpdate: async (newData, oldData) => {
                if (
                  !newData.name ||
                  !newData.type ||
                  !newData.currency ||
                  !newData.account_num ||
                  !newData.receiver_name ||
                  !oldData
                ) {
                  setOpen(true)
                  return
                }

                setUpdatedWallet(newData)
                setIsCommentDialogOpen(true)
              },
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve()
                    AxiosService.delete(`/wallets/send/${oldData.id}`, {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    })
                      .then(() => {
                        // eslint-disable-next-line no-unused-expressions
                        tableRef.current && tableRef.current.onQueryChange()
                      })
                      .catch((err) => {
                        console.log('err', err)
                        if (err.response) {
                          console.log(err.response.status)
                        }
                      })
                    // eslint-disable-next-line no-unused-expressions
                    tableRef.current && tableRef.current.onQueryChange()
                  }, 600)
                }),
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'history',
                tooltip: 'Показать историю',
                isFreeAction: false,
                onClick: (event, row) => navigate(`/wallets/${row.id}/history`),
              },
              {
                icon: 'transform',
                tooltip: 'Перевести средства на другой кошелек',
                isFreeAction: false,
                onClick: (event, row) => navigate(`/wallets/${row.id}/transfer`),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
