import { useAuth } from './customHooks'

export const getLinkStylesByPermission = (permission, defaultStyle = undefined) => {
  const { user } = useAuth()
  return {
    pointerEvents: user.permissions.includes(permission) || user.permissions.includes('admin') ? 'all' : 'none',
    color: defaultStyle
      ? defaultStyle.color
      : !user.permissions.includes(permission) && !user.permissions.includes('admin') && 'inherit',
  }
}
