import { useEffect, useRef } from 'react'

export const useDidMountEffect = (effect, dependencies) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      effect()
    } else {
      mounted.current = true
    }
  }, dependencies)
}
