import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { Button, Input, MenuItem, Select } from '@material-ui/core'

import AxiosService from '../../utils/axiosService'
import ButtonAppBar from '../bar/ButtonAppBar'
import { ALPHABETS } from '../../utils/customHooks'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}))

export default function WalletsTransfer() {
  const { id: walletTransferFromId } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [walletData, setWalletData] = useState({ name: '' })
  const [walletsList, setWalletsList] = useState([])

  const { register, watch, getValues, trigger } = useForm()
  const formValues = watch()

  const firstColumnWidth = 4
  const secondColumnWidth = 8
  const dividerWidth = 12

  async function fetchWalletInfo() {
    if (walletTransferFromId) {
      await AxiosService.get(`/wallets/send/${walletTransferFromId}`).then(async ({ data }) => {
        setWalletData(data)

        if (data?.currency?.currency_code !== '') {
          await AxiosService.get(`/wallets/send?currency_code=${data?.currency?.currency_code}`).then(
            ({ data: curList = [] }) => {
              setWalletsList(curList.filter(({ id }) => +id !== +walletTransferFromId))
            }
          )
        }
      })
    }
    return []
  }

  useEffect(() => {
    fetchWalletInfo()
  }, [walletTransferFromId])

  const isDisabledButton = useMemo(
    () => !Number(getValues()?.wallet_to_send_id_to) > 0 || !Number(getValues()?.amount) > 0,
    [formValues]
  )

  const handleSave = async () => {
    await AxiosService.put(`/wallets/send/${walletTransferFromId}/transfer`, getValues(), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res?.response?.status === 204 || res?.status === 204) {
          enqueueSnackbar('Трансфер выполнен успешно', { variant: 'success' })
          navigate(`/wallets`)
        } else if (res?.response?.status === 422 || res?.status === 422) {
          Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
            enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
          })
        } else if (res?.response?.status === 500 || res?.status === 500) {
            enqueueSnackbar(`Ошибка сервера`, { variant: 'error', autoHideDuration: 7000 })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div>
      <ButtonAppBar />

      <br />
      <Grid container alignItems="center" justify="center">
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" spacing={1}>
                <Grid container justify="center">
                  <Grid item xs={firstColumnWidth}>
                    Перевод с {walletData?.currency?.currency_code} кошелька:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {walletData.name} ({walletData?.receiver_name})
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    Текущий баланс:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    {walletData?.current_balance} {walletData?.currency?.currency_code}
                  </Grid>
                  <Grid item xs={dividerWidth} className={classes.divider}>
                    <Divider />
                  </Grid>
                  <Grid item xs={firstColumnWidth}>
                    На какой кошелек перевести:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Select
                      labelId="wallet_to"
                      id="wallet_to"
                      key="wallet_to"
                      value={getValues().wallet_to_send_id_to || ''}
                      style={{ width: '100%' }}
                      {...register('wallet_to_send_id_to', {
                        required: 'Пожалуйста выберите Кошелек',
                        onChange: () => trigger(),
                      })}
                    >
                      <MenuItem disabled value="">
                        <em>Выберите кошелек</em>
                      </MenuItem>
                      {walletsList.map(({ id, name, receiver_name, description, current_balance, currency }) => (
                        <MenuItem value={id} key={`wallet_to_${id}`}>
                          {name} {description} ({receiver_name}): {current_balance} {currency?.currency_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={firstColumnWidth}>
                    Сумма перевода:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Input
                      id="amount"
                      type="number"
                      style={{ width: '100%' }}
                      inputProps={{
                        step: '0.01',
                        min: '0.01',
                      }}
                      value={getValues().amount || ''}
                      onKeyDown={(e) => {
                        if (ALPHABETS.includes(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      placeholder="Введите сумму"
                      {...register('amount', { setValueAs: (v) => +v})}
                    />
                  </Grid>

                  <Grid item xs={firstColumnWidth}>
                    Комментарий:
                  </Grid>
                  <Grid item xs={secondColumnWidth}>
                    <Input
                      id="comment"
                      type="text"
                      style={{ width: '100%' }}
                      value={getValues().comment || ''}
                      placeholder="Введите комментарий"
                      {...register('comment')}
                    />
                  </Grid>
                  <Grid item xs={dividerWidth} container style={{ justifyContent: 'center', paddingTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleSave} disabled={isDisabledButton}>
                      Трансфер
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
