export function getPhotoUrl(photoUrl) {
    // if url is relative URL - as is
    if (photoUrl.startsWith('/')) {
        return photoUrl;
    }

    // if url is valid absolute URL - as is
    try {
        new URL(photoUrl)
        return photoUrl;
    } catch (_) {
    }

    return `${process.env.REACT_APP_API_URL}/files/${photoUrl}`;
}
