import React, { createRef, useEffect, useReducer, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import { parse, stringify } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format, parse as parseDate } from 'date-fns'

import AxiosService from '../../utils/axiosService'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import { formattedCurrenciesFixed, getOrderData } from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import { getLinkStylesByPermission } from '../../utils/getLinkStylesByPermission'

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
}))

export default function WalletsHistory() {
  const { id: walletHistoryId } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()
  const tableRef = createRef()
  const [walletInfo, setWalletInfo] = useState({ name: '', currency_code: '' })

  async function fetchWalletInfo() {
    if (walletHistoryId) {
      await AxiosService.get(`/wallets/send/${walletHistoryId}`).then(
        ({ data: { name = '', currency: { currency_code = '' } = {} } = {} }) => {
          setWalletInfo({ name, currency_code })
        }
      )
    }
    return []
  }

  useEffect(() => {
    fetchWalletInfo()
    tableRef.current.onQueryChange() // todo fix double loading
  }, [walletHistoryId])

  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    // eslint-disable-next-line no-restricted-globals,no-undef
    parse(location.search) || {
      created_at_from: '',
      created_at_to: '',
    }
  )

  const createdAtFrom = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_from"
        label="Создан после"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_from ? parseDate(parsed?.created_at_from, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_from: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const createdAtTo = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_to"
        label="Создан до"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_to ? parseDate(parsed?.created_at_to, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_to: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const columns = [
    {
      title: 'Дата',
      field: 'created_at',
      minWidth: 110,
      maxWidth: 130,
    },
    {
      title: 'Тип',
      field: 'history_type',
      minWidth: 100,
      maxWidth: 120,
      render: ({ history_type }) => {
        switch (history_type) {
          case `created`:
            return <span className="badge badge-info">Создан</span>
          case `manual_edit`:
            return <span className="badge badge-primary">Редактирование</span>
          case `transfer_balance`:
            return <span className="badge badge-warning">Трансфер</span>
          case `edit_by_order`:
            return <span className="badge badge-success">Заказ</span>
          case `expense`:
            return <span className="badge badge-danger">Расход</span>
          case `taobao_order`:
            return <span className="badge badge-secondary">Заказ таобао</span>
          case `taobao_register`:
            return <span className="badge badge-light">Регистрация таобао</span>
          default:
            return ''
        }
      },
    },
    {
      title: 'Описание',
      field: 'description',
      minWidth: 170,
      render: ({
        history_type,
        order = {},
        taobao_order = {},
        taobao_register = {},
        user_edited = {},
        wallet_to_send_transfer = {},
        comment,
        expense_main_category,
      }) => {
        switch (history_type) {
          case `created`:
            return (
              <>
                Создал: {user_edited?.email}
                {comment ? <i>: {comment}</i> : ''}
              </>
            )
          case `manual_edit`:
            return (
              <>
                Отредактировал: {user_edited?.email}
                {comment ? <i>: {comment}</i> : ''}
              </>
            )
          case `transfer_balance`:
            return (
              <>
                Перевод от {user_edited?.email} на{' '}
                <Link to={`/wallets/${wallet_to_send_transfer.id}/history`}>{wallet_to_send_transfer?.name}</Link>
                {comment ? <i>: {comment}</i> : ''}
              </>
            )
          case `edit_by_order`:
            return (
              <>
                <Link
                  style={getLinkStylesByPermission(PERMISSIONS.Orders)}
                  to={`/orders/${order.id}`}
                >{`Заказ ${order.order_code}`}</Link>
                :{` ${getOrderData(order)}`}
              </>
            )
          case `taobao_order`:
            return (
              <>
                <Link
                  style={getLinkStylesByPermission(PERMISSIONS.Taobao)}
                  to={`/taobao-orders/${taobao_order?.id}`}
                >{`Заказ ${taobao_order.order_code}`}</Link>
                :{` ${getOrderData({ ...taobao_order, type: 'taobao_order' })}`}
              </>
            )
          case `taobao_register`:
            return (
              <>
                <Link
                  style={getLinkStylesByPermission(PERMISSIONS.Taobao)}
                  to={`/taobao-registration/${taobao_register?.id}`}
                >{`Заказ ${taobao_register.register_code}`}</Link>
                :{` ${getOrderData({ ...taobao_register, type: 'taobao_register' })}`}
              </>
            )
          case `expense`:
            return <>{expense_main_category}</>
          default:
            return ''
        }
      },
    },
    {
      title: 'Баланс',
      field: 'balance',
      minWidth: 155,
      render: ({ old_balance, new_balance }) =>
        old_balance === new_balance ? (
          <p style={{ color: `gray` }}>
            {old_balance} {walletInfo.currency_code}
          </p>
        ) : (
          <>
            {formattedCurrenciesFixed(new_balance - old_balance)} {walletInfo.currency_code}:
            <br />
            {old_balance} {walletInfo.currency_code} -&gt; {new_balance} {walletInfo.currency_code}
          </>
        ),
    },
    {
      title: 'Номер счета/карты',
      field: 'account_num',
      minWidth: 180,
      width: 180,
      maxWidth: 300,
      render: ({ old_account_num, new_account_num }) =>
        old_account_num === new_account_num ? (
          <p style={{ color: `gray` }}>{old_account_num}</p>
        ) : (
          `${old_account_num} -> ${new_account_num}`
        ),
    },
    {
      title: 'Имя получателя',
      field: 'receiver_name',
      minWidth: 160,
      width: 160,
      maxWidth: 300,
      render: ({ old_receiver_name, new_receiver_name }) =>
        old_receiver_name === new_receiver_name ? (
          <p style={{ color: `gray` }}>{old_receiver_name}</p>
        ) : (
          `${old_receiver_name} -> ${new_receiver_name}`
        ),
    },
  ]

  useDidMountEffect(() => {
    navigate(`/wallets/${walletHistoryId}/history?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  return (
    <div>
      <ButtonAppBar />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={11} lg={9}>
          {createdAtFrom}
          {createdAtTo}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={9}>
          <br />
          <MaterialTable
            title={`История изменений кошелька ${walletInfo.name} ${walletInfo.currency_code}`}
            tableRef={tableRef}
            searchable={false}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                let url = `/wallets/send/${walletHistoryId}/history?`
                // url += 'per_page=' + query.pageSize
                url += `&page=${query.page + 1}`
                url += `&${stringify(parsed)}`
                AxiosService.get(url).then((result) => {
                  resolve({
                    data: result?.data?.data?.map(
                      ({
                        history_type,
                        created_at,
                        old_balance,
                        new_balance,
                        old_account_num,
                        new_account_num,
                        old_receiver_name,
                        new_receiver_name,
                        comment,
                        order,
                        user_edited,
                        taobao_register,
                        wallet_to_send_transfer,
                        expense_main_category,
                        taobao_order,
                      }) => ({
                        description: 3,
                        comment,
                        order,
                        history_type,
                        created_at,
                        old_balance,
                        new_balance,
                        old_account_num,
                        new_account_num,
                        old_receiver_name,
                        new_receiver_name,
                        taobao_order,
                        taobao_register,
                        user_edited,
                        wallet_to_send_transfer,
                        expense_main_category,
                      })
                    ),
                    page: result?.data?.current_page - 1,
                    totalCount: result?.data?.total,
                  })
                })
              })
            }
            options={{
              search: false,
              sorting: false,
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15],
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
