import React, { createRef, useMemo, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import DateFnsUtils from '@date-io/date-fns'
import { format, parse as parseDate } from 'date-fns'
import throttle from 'lodash/throttle'
import { useSnackbar } from 'notistack'

import Grid from '@material-ui/core/Grid'
import { parse, stringify } from 'query-string'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, InputLabel } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import AxiosService from '../../utils/axiosService'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import {
  getLinkName,
  getOrderData,
  getStatusLabel,
  getManualToSourceLabels,
  typesOfClient,
  STATUS,
  useAuth,
  getRequestDirectionLabel,
} from '../../utils/customHooks'
import { useDidMountEffect } from '../../utils/useDidMountEffect'

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  button: {
    height: 56,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  textOverflowWithDots: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

export default function Orders() {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()
  const navigate = useNavigate()
  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    parse(location.search) || {
      search_string: '',
      type: '',
      status: '',
      client_type: '',
      created_at_from: '',
      created_at_to: '',
    }
  )

  const exportCSVCondition = useMemo(
    () =>
      (user?.permissions.includes(PERMISSIONS.Wallets) &&
        user?.permissions.includes(PERMISSIONS.Orders) &&
        user?.permissions.includes(PERMISSIONS.Clients)) ||
      user?.permissions.includes(PERMISSIONS.Admin),
    [user]
  )

  const tableRef = createRef()

  const statusLabels = {
    [STATUS.WAITING_MANAGER]: getStatusLabel(STATUS.WAITING_MANAGER),
    [STATUS.WAITING_CLIENT]: getStatusLabel(STATUS.WAITING_CLIENT),
    [STATUS.CLIENT_IS_WAITING]: getStatusLabel(STATUS.CLIENT_IS_WAITING),
    [STATUS.REQUISITES_SENT]: getStatusLabel(STATUS.REQUISITES_SENT),
    [STATUS.PAYMENT_SENT]: getStatusLabel(STATUS.PAYMENT_SENT),
    [STATUS.PAYMENT_RECEIVED]: getStatusLabel(STATUS.PAYMENT_RECEIVED),
    [STATUS.PAYMENT_ERROR]: getStatusLabel(STATUS.PAYMENT_ERROR),
    [STATUS.REQUISITES_UPDATED]: getStatusLabel(STATUS.REQUISITES_UPDATED),
    [STATUS.COMPLETED]: getStatusLabel(STATUS.COMPLETED),
    [STATUS.SEND_RECEIPT_TO_CLIENT]: getStatusLabel(STATUS.SEND_RECEIPT_TO_CLIENT),
    [STATUS.CANCELLED]: getStatusLabel(STATUS.CANCELLED),
    [STATUS.CANCELLED_BY_USER]: getStatusLabel(STATUS.CANCELLED_BY_USER),
  }

  const handleExportClick = async () => {
    const url = `/order/export-csv?${stringify(parsed)}` // URL с параметрами запроса.
    const { data } = await AxiosService.get(url)
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', `Заказы (${format(new Date(), 'dd-MM-yyyy')})`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const statusFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <InputLabel id="demo-simple-select-outlined-label">Статус</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.status ? parsed.status : ''}
        onChange={(event) => dispatch({ status: event.target.value || undefined })}
        label="Статус"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(statusLabels).map((key) => (
          <MenuItem key={key} value={key}>
            {statusLabels[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const typesLabels = {
    exchange: 'Обмен 💱',
    my_wallet_withdraw: 'Вывод из 💰Кошелька',
    my_wallet_replenish: 'Пополнение 💰Кошелька',
    exchange_from_wallet: 'Обмен из 💰Кошелька',
    exchange_to_wallet: 'Обмен в 💰Кошелек',
  }
  const typesFilter = (
    <FormControl variant="outlined" className={classes.formControlTypes}>
      <InputLabel id="demo-simple-select-outlined-label">Тип заказа</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.type ? parsed.type : ''}
        onChange={(event) => dispatch({ type: event.target.value || undefined })}
        label="Тип заказа"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(typesLabels).map((key) => (
          <MenuItem key={key} value={key}>
            {typesLabels[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const manualFilter = (
    <FormControl variant="outlined" className={classes.formControlTypes}>
      <InputLabel id="demo-simple-select-outlined-label">Источник</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.is_manual ? parsed.is_manual : ''}
        onChange={(event) => dispatch({ is_manual: event.target.value || undefined })}
        label="Источник"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(getManualToSourceLabels).map((key) => (
          <MenuItem key={key} value={key}>
            {getManualToSourceLabels[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const typesOfClientFilter = (
    <FormControl variant="outlined" className={classes.formControlTypes}>
      <InputLabel id="demo-simple-select-outlined-label">Тип клиента</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={parsed.client_type ? parsed.client_type : ''}
        onChange={(event) => dispatch({ client_type: event.target.value || undefined })}
        label="Тип клиента"
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {Object.keys(typesOfClient).map((key) => (
          <MenuItem key={key} value={key}>
            {typesOfClient[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  const codeFilter = (
    <TextField
      className={classes.selectEmpty}
      id="outlined-basic"
      label="Поиск"
      variant="outlined"
      type="text"
      value={parsed.search_string || ''}
      onChange={(event) => dispatch({ search_string: event.target.value || undefined })}
    />
  )
  const createdAtFrom = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_from"
        label="Создан после"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_from ? parseDate(parsed?.created_at_from, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_from: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const createdAtTo = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="dd/MM/yyyy"
        id="created_at_to"
        label="Создан до"
        inputVariant="outlined"
        className={classes.selectEmpty}
        autoOk
        clearable
        value={parsed?.created_at_to ? parseDate(parsed?.created_at_to, 'yyyy-MM-dd', new Date()) : null}
        onChange={(date) => dispatch({ created_at_to: date ? format(date, 'yyyy-MM-dd') : undefined })}
      />
    </MuiPickersUtilsProvider>
  )

  const columns = [
    {
      title: 'Дата',
      field: 'created_at',
      maxWidth: 95,
    },
    {
      title: '№ Заказа',
      field: 'order_code',
      maxWidth: 55,
    },
    {
      title: 'Статус',
      field: 'status',
      maxWidth: 140,
      lookup: statusLabels,
      render: ({ is_p2p, status }) => (
        <Grid container item style={{ display: 'flex' }}>
          <Grid item style={{ marginRight: is_p2p ? '8px' : 0 }}>
            {statusLabels[status]}
          </Grid>
          <Grid item>{is_p2p ? <span className="badge badge-dark">P2P</span> : null}</Grid>
        </Grid>
      ),
    },
    {
      title: 'Данные заказа',
      field: 'order_data',
    },
    {
      title: 'Направление',
      field: 'request_direction',
      maxWidth: 60,
      render: ({ request_direction }) => (request_direction ? getRequestDirectionLabel(request_direction) : '-'),
    },
    {
      title: 'В работе у',
      field: 'assign_to',
      maxWidth: 100,
      render: ({ assign_to = {}, clean_order_code = '', id = 0, status }) => {
        const throttledOnClickToAssignToMe = throttle(() => {
          enqueueSnackbar(`Заявка ${clean_order_code} взята в работу`, { variant: 'success', autoHideDuration: 6000 })

          AxiosService.post(`/order/${id}/assign-to-me`)
            .then(() => {
              tableRef?.current?.onQueryChange()
            })
            .catch((err) => {
              console.log('err', err)
              if (err.response) {
                console.log(err.response.status)
              }
            })
        }, 30000)

        if (assign_to?.email) {
          return <div className={classes.textOverflowWithDots}>{assign_to?.email}</div>
        }
        if (status && ![STATUS.COMPLETED, STATUS.CANCELLED, STATUS.CANCELLED_BY_USER].includes(status))
          return (
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={throttledOnClickToAssignToMe}
              style={{ whiteSpace: 'nowrap' }}
            >
              В работу
            </Button>
          )
        return '-'
      },
    },
    {
      title: 'Имя клиента',
      field: 'name',
      maxWidth: 150,
    },
  ]

  useDidMountEffect(() => {
    navigate(`/orders?${stringify(parsed)}`)
    tableRef?.current.onQueryChange()
  }, [parsed])

  return (
    <div>
      <ButtonAppBar />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <br />
          {codeFilter}
          {statusFilter}
          {typesFilter}
          {manualFilter}
          {typesOfClientFilter}
          <br />
          {createdAtFrom}
          {createdAtTo}
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <br />
          <MaterialTable
            title="Заказы"
            tableRef={tableRef}
            searchable={false}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                let url = '/order?'
                url += `&page=${query.page + 1}`
                url += `&${stringify(parsed)}`

                AxiosService.get(url).then((result) => {
                  resolve({
                    data: result?.data?.data?.map(
                      ({
                        id,
                        order_code: orderCode,
                        type,
                        status,
                        created_at,
                        currency_code_from,
                        give_amount,
                        currency_code_to,
                        receive_amount,
                        rate,
                        client,
                        is_p2p,
                        real_give_amount,
                        real_give_amount_currency_code,
                        request_direction,
                        assign_to,
                      }) => ({
                        id,
                        order_code: (
                          <Link to={`/orders/${id}`} style={{ color: `inherit` }}>
                            {orderCode}
                          </Link>
                        ),
                        status,
                        is_p2p,
                        created_at,
                        name: getLinkName(client),
                        order_data: getOrderData({
                          real_give_amount,
                          real_give_amount_currency_code,
                          type,
                          give_amount,
                          currency_code_from,
                          currency_code_to,
                          receive_amount,
                          rate,
                        }),
                        request_direction,
                        assign_to,
                        clean_order_code: orderCode,
                      })
                    ),
                    page: result?.data?.current_page - 1,
                    totalCount: result?.data?.total,
                  })
                })
              })
            }
            options={{
              search: false,
              sorting: false,
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15],
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'add',
                tooltip: 'Создать ручной заказ',
                isFreeAction: true,
                onClick: () => {
                  navigate('/orders/create')
                },
              },
              exportCSVCondition
                ? {
                    icon: 'download',
                    tooltip: 'Выгрузить CSV',

                    isFreeAction: true,
                    onClick: () => {
                      handleExportClick()
                    },
                  }
                : null,
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
