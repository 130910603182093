import React from 'react'
import { logouts, useAuth } from '../../utils/customHooks'

export default function PermissionWrapper({ children, permission }) {
  const { user } = useAuth()
  if (user?.role) {
    logouts()
  }
  if (user?.permissions?.includes('admin') || user?.permissions?.includes(permission)) {
    return <>{children}</>
  }
  return null
}
