import React, { useState } from 'react'
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

export default function WalletCommentDialog({ isOpen, handleClose, handleConfirm }) {
  const [comment, setComment] = useState('')

  function onConfirm() {
    setComment('')
    handleConfirm(comment)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle>Введите обязательный комментарий</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          multiline
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          id="comment"
          label="Комментарий"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отменить
        </Button>
        <Button onClick={onConfirm} disabled={!comment.length} color="primary">
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
