import React, { useState, useRef } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import MaterialTable from 'material-table'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ButtonAppBar from '../bar/ButtonAppBar'

import AxiosService from '../../utils/axiosService'
import { formatDate } from '../../utils/date'

const useStyles = makeStyles({
  root: {
    paddingTop: 25,
  },
  noWrap: {
    whiteSpace: 'nowrap',
    width: 150,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
})

function SendDialog({ isOpen, handleClose, handleConfirm }) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Подтвердить действие?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Вы действительно хотите отправить эту рассылку?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function NotificationList() {
  const classes = useStyles()
  const navigate = useNavigate()

  const tableRef = useRef()

  const { enqueueSnackbar } = useSnackbar()
  const [isSendDialogOpen, setIsSendDialogOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)

  const handleSendConfirm = async () => {
    const res = await AxiosService.put(`/client-notifications/${currentId}/schedule`)

    if (res?.response?.status === 200 || res?.status === 200) {
      enqueueSnackbar(`Отправка рассылки запланирована`, { variant: 'success' })
      tableRef.current.onQueryChange()
    } else if (res?.response?.status === 422 || res?.status === 422) {
      Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
        enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
      })
    }

    setIsSendDialogOpen(false)
  }

  return (
    <>
      <ButtonAppBar />
      <Container className={classes.root} maxWidth="xl">
        <SendDialog
          isOpen={isSendDialogOpen}
          handleClose={() => setIsSendDialogOpen(false)}
          handleConfirm={handleSendConfirm}
        />
        <MaterialTable
          title="Рассылки"
          tableRef={tableRef}
          localization={{
            header: {
              actions: 'Действия',
            },
            body: {
              deleteTooltip: 'Удалить',
              editRow: {
                deleteText: 'Вы уверены что хотите удалить эту рассылку?',
                saveTooltip: 'Подтвердить',
                cancelTooltip: 'Отмена',
              },
            },
          }}
          columns={[
            {
              title: 'Дата создания',
              field: 'created_at',
              render: ({ created_at }) => formatDate(created_at),
            },
            {
              title: 'Статус',
              field: 'status',
              lookup: {
                new: <span className="badge badge-light">Новое</span>,
                scheduled: <span className="badge badge-warning">Запланировано</span>,
                in_progress: <span className="badge badge-primary">Выполняется</span>,
                finished: <span className="badge badge-success">Завершено</span>,
              },
              width: 100,
            },
            {
              title: 'Тип',
              field: 'type',
              lookup: {
                all: <span className="badge badge-success">Всем</span>,
                client_ids: <span className="badge badge-warning">Выборочно</span>,
              },
              width: 100,
            },
            {
              title: 'Текст',
              field: 'text',
              render: ({ text }) => (
                <div className={classes.noWrap} title={text}>
                  {text}
                </div>
              ),
            },
            {
              title: 'Название',
              field: 'description',
              render: ({ description }) => (
                <div className={classes.noWrap} title={description}>
                  {description}
                </div>
              ),
            },
            {
              title: 'Прогресс',
              render: ({ clients_count, success_count, failed_count }) => (
                  <div className={classes.noWrap} title={success_count + failed_count}>
                    {success_count + failed_count}/{clients_count}
                  </div>
              ),
            },

          ]}
          data={async (query) => {
            const page = query.page ? query.page + 1 : 1
            const { data: response } = await AxiosService.get(`/client-notifications?page=${page}`)

            return {
              data: response.data,
              page: response.current_page - 1,
              totalCount: response.total,
            }
          }}
          options={{
            search: false,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [15],
            actionsColumnIndex: -1,
            column: {
              columnsButton: true,
              width: 300,
            },
          }}
          editable={{
            isDeletable: (rowData) => rowData.status === 'new',
            onRowDelete: async ({ id }) => {
              const res = await AxiosService.delete(`/client-notifications/${id}`)

              if (res?.response?.status === 200 || res?.status === 200) {
                enqueueSnackbar(`Рассылка удалена`, { variant: 'success' })
                tableRef.current.onQueryChange()
              } else if (res?.response?.status === 422 || res?.status === 422) {
                Object.entries(res?.response?.data?.error)?.forEach(([key, value]) => {
                  enqueueSnackbar(`${key}: ${value}`, { variant: 'error', autoHideDuration: 7000 })
                })
              }
            },
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Создать рассылку',
              isFreeAction: true,
              onClick: () => {
                navigate('/notifications/create')
              },
            },
            {
              icon: 'visibility',
              tooltip: 'Открыть',
              onClick: (_, rowData) => {
                navigate(`/notifications/${rowData.id}`)
              },
            },
            (rowData) => ({
              disabled: rowData.status !== 'new',
              icon: 'send',
              tooltip: 'Отправить рассылку',
              onClick: () => {
                setCurrentId(rowData.id)
                setIsSendDialogOpen(true)
              },
            }),
          ]}
        />
      </Container>
    </>
  )
}
