import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ButtonAppBar from '../bar/ButtonAppBar'

import fetcher from '../../utils/fetcher'
import { formatDate } from '../../utils/date'
import AxiosService from '../../utils/axiosService'
import Loading from '../common/Loading'
import ErrorComponent from '../common/ErrorComponent'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: 0,
  },
  listItemText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  listItemContent: {
    maxWidth: '50%',
  },
}))

const getStatusLabel = (status) =>
  ({
    new: <span className="badge badge-light">Новое</span>,
    scheduled: <span className="badge badge-warning">Запланировано</span>,
    in_progress: <span className="badge badge-primary">Выполняется</span>,
    finished: <span className="badge badge-success">Завершено</span>,
  }[status])

const getTypeLabel = (type) =>
  ({
    all: <span className="badge badge-success">Всем</span>,
    client_ids: <span className="badge badge-warning">Выборочно</span>,
  }[type])

export default function Notification() {
  const classes = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: notification, isLoading, error, mutate } = useSWR(`/client-notifications/${id}`, fetcher)

  const deleteNotification = () => {
    AxiosService.delete(`/client-notifications/${id}`)
    navigate('/notifications')
  }

  const scheduleNotification = () => {
    AxiosService.put(`/client-notifications/${id}/schedule`)
    mutate()
  }

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent />
  }

  return (
    <>
      <ButtonAppBar />
      <Container maxWidth="sm">
        <Paper square className={classes.paper}>
          <List disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Дата создания" disableTypography className={classes.listItemText} />
              <Typography variant="body2" color="textSecondary">
                {formatDate(notification.created_at)}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem} divider>
              <ListItemText primary="Создано" disableTypography className={classes.listItemText} />
              <Typography variant="body2" color="textSecondary">
                {notification.created_by.email}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Статус" disableTypography className={classes.listItemText} />
              {getStatusLabel(notification.status)}
            </ListItem>
            <ListItem className={classes.listItem} divider>
              <ListItemText primary="Тип" disableTypography className={classes.listItemText} />
              {getTypeLabel(notification.type)}
            </ListItem>
            <ListItem alignItems="flex-start" className={classes.listItem}>
              <ListItemText primary="Текст" disableTypography className={classes.listItemText} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ whiteSpace: 'pre-wrap' }}
                className={classes.listItemContent}
              >
                {notification.text}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem} divider>
              <ListItemText primary="Название" disableTypography className={classes.listItemText} />
              <Typography variant="body2" color="textSecondary">
                {notification.description}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Кол-во клиентов" disableTypography className={classes.listItemText} />
              <Typography variant="body2" color="textSecondary">
                {notification.clients_count}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Кол-во успешных отправок" disableTypography className={classes.listItemText} />
              <Typography variant="body2" color="textSecondary">
                {notification.success_count}
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" className={classes.listItem}>
              <ListItemText primary="Кол-во проваленных отправок" disableTypography className={classes.listItemText} />
              <Box style={{ textAlign: 'right' }}>
                <Typography variant="body2" color="textSecondary">
                  В общем - {notification.failed_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Бот забанен - {notification.failed_bot_blocked_count}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Юзер деактивирован - {notification.failed_user_deactivated_count}
                </Typography>
              </Box>
            </ListItem>
          </List>
          <Box className={classes.buttons}>
            {notification.status === 'new' && (
              <>
                <Button variant="contained" color="secondary" className={classes.button} onClick={deleteNotification}>
                  Удалить
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={scheduleNotification}>
                  Отправить
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  )
}
