import React, { createRef, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import { parse, stringify } from 'query-string'
import debounce from 'lodash/debounce'
import { FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import AxiosService from '../../utils/axiosService'
import ButtonAppBar, { PERMISSIONS } from '../bar/ButtonAppBar'
import { useDidMountEffect } from '../../utils/useDidMountEffect'
import { RenderBadge } from '../expenses/RenderBadge'

const useStyles = makeStyles((theme) => ({
  formControlStatuses: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlAutoComplete: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: '300px',
  },
  formControlTypes: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export const getPermissionLocale = (permission) => {
  switch (permission) {
    case PERMISSIONS.Admin:
      return 'Админ'
    case PERMISSIONS.Orders:
      return 'Заказы'
    case PERMISSIONS.Wallets:
      return 'Кошельки'
    case PERMISSIONS.Expenses:
      return 'Расходы'
    case PERMISSIONS.Notifications:
      return 'Уведомления'
    case PERMISSIONS.Clients:
      return 'Клиенты'
    case PERMISSIONS.Exchanges:
      return 'Курсы'
    case PERMISSIONS.Taobao:
      return 'Taobao'
    default:
      return 'none'
  }
}

export default function Users() {
  const classes = useStyles()
  const tableRef = createRef()
  const navigate = useNavigate()

  const [parsed, dispatch] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    // eslint-disable-next-line no-restricted-globals,no-undef
    parse(location.search) || {
      email: '',
    }
  )

  const columns = [
    {
      title: 'ID Пользователя',
      field: 'id',
      width: '10%',
      cellStyle: { width: '10%' },
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Доступы',
      field: 'permissions',
      render: ({ permissions }) => <>{permissions.map((permission) => getPermissionLocale(permission)).join(', ')}</>,
    },
    {
      title: 'Блокировка',
      field: 'permissions',
      render: ({ is_blocked }) => (
        <span>
          {RenderBadge({
            name: is_blocked ? 'Заблокирован' : 'Активен',
            color_hash: is_blocked ? 'dc3545' : '28a745',
          })}
        </span>
      ),
    },
  ]

  const onChange = (e) => {
    dispatch({ email: e.target.value || undefined })
  }

  const debouncedOnChange = debounce(onChange, 300)

  const emailFilter = (
    <FormControl variant="outlined" className={classes.formControlStatuses}>
      <TextField
        id="outlined-basic"
        label="Поиск"
        variant="outlined"
        type="text"
        defaultValue={parsed.email || ''}
        onChange={debouncedOnChange}
      />
    </FormControl>
  )

  useDidMountEffect(() => {
    navigate(`/users?${stringify(parsed)}`)
    tableRef?.current?.onQueryChange()
  }, [parsed])

  return (
    <>
      <ButtonAppBar />
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={11} lg={10}>
          {emailFilter}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={10}>
          <br />
          <MaterialTable
            tableRef={tableRef}
            title="Пользователи"
            searchable={false}
            columns={columns}
            onRowClick={(event, rowData) => navigate(`/users/${rowData.id}`)}
            data={async (query) => {
              const url = `/users?&page=${query.page + 1}&${stringify(parsed)}`

              const response = await AxiosService.get(url)

              return {
                data: response?.data?.data?.map(({ id, email, is_blocked, permissions }) => ({
                  id,
                  email,
                  is_blocked,
                  permissions,
                })),
                page: response?.data.current_page - 1,
                totalCount: response.data.total,
              }
            }}
            options={{
              search: false,
              sorting: false,
              // columnsButton: true,
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15],
              actionsColumnIndex: -1,
              rowStyle: ({ is_blocked }) => ({
                backgroundColor: is_blocked ? 'rgb(238, 238, 238)' : '#FFF',
              }),
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'add',
                tooltip: 'Создать категорию расходов',
                isFreeAction: true,
                onClick: () => navigate('/users/create'),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
