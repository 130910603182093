import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  expansion: {
    color: theme.palette.text.secondary,
  },
  container: {
    maxHeight: 440,
  },
}))

export default function CTable(props) {
  const classes = useStyles()
  const { columns, rows, text, keyPref } = props

  return (
    <Grid item xs={12}>
      {rows && rows.length > 0 && (
        <Accordion className={classes.expansion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{text}</AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={`${keyPref}_${column.id}`}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={Math.random()}>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell key={`${keyPref}__${column.id}`} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
    </Grid>
  )
}
