import { formattedMoney } from './customHooks'

const getCorrectBusketWordForm = (number) => {
  if (number === 1) {
    return '1 товар'
  }
  if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    return `${number} товара`
  }
  return `${number} товаров`
}

export const getTaobaoOrderData = (
  {
    type,
    receive_amount,
    commission,
    delivery_price,
    give_amount,
    currency_code_from,
    real_give_amount,
    real_give_amount_currency_code,
  } = {},
  baskets = 0,
  isInTable = false
) => {
  const p2pOrder = real_give_amount ? `-> ${formattedMoney(real_give_amount)} ${real_give_amount_currency_code} ` : ''
  const commonPrice = Number(receive_amount) + Number(commission ?? 0) + Number(delivery_price ?? 0)

  if (typeof baskets === 'number' && baskets !== 0) {
    if (give_amount) {
      return `${getCorrectBusketWordForm(baskets)}, ${give_amount || '-'} ${currency_code_from || '-'} ${p2pOrder}
      (${commonPrice || '-'} 🇨🇳CNY)`
    }
    if (commonPrice) {
      return `${getCorrectBusketWordForm(baskets)} ${p2pOrder}(${commonPrice || '-'} 🇨🇳CNY)`
    }
    return `${getCorrectBusketWordForm(baskets)} ${p2pOrder}`
  }
  if (type === 'rent' || (isInTable && give_amount && commonPrice)) {
    return `${give_amount} ${currency_code_from} ${p2pOrder}(${commonPrice} 🇨🇳CNY)`
  }
  return '-'
}
